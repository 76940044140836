import * as React from 'react';
import { ColumnSetting } from '../../store/slices/preferences.slice';

export interface PreferenceContext {
  updateColumns: (columns: ColumnSetting[]) => void;
  currentColumns: ColumnSetting[];
}

export const PreferenceColumnsContext = React.createContext<PreferenceContext>({
  currentColumns: [],
  updateColumns: ([]) => {
    return;
  },
});

interface PreferenceProviderProps {
  children: React.ReactNode;
  dispatchColumns: (columns: ColumnSetting[]) => void;
}

export const PreferenceProvider: React.FC<PreferenceProviderProps> = ({
  dispatchColumns,
  children,
}) => {
  const [cols, setCols] = React.useState<ColumnSetting[]>([]);

  const handleUpdate = (newCols: ColumnSetting[]) => {
    setCols(newCols);
    dispatchColumns(newCols);
  };

  return (
    <PreferenceColumnsContext.Provider
      value={{
        updateColumns: handleUpdate,
        currentColumns: cols,
      }}
      
    >
      {children}
    </PreferenceColumnsContext.Provider>
  );
};
