import React from 'react';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import WorldMap from 'react-svg-worldmap';
import { AppColors } from '@backlinkit/shared';

export type WorldMapItem = {
  country: string;
  value: number;
};

export type WorldMapClickEvent = {
  countryCode: string;
};

export type WorldMapChartProps = {
  chartData: WorldMapItem[];
  onMapClick: (data: WorldMapClickEvent) => void;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const WorldMapChart: React.FC<WorldMapChartProps> = ({ chartData, onMapClick }) => {
  return (
    <WorldMap
      color={AppColors.secondary}
      value-suffix="Links"
      size="responsive"
      data={chartData}
      onClickFunction={(data) => onMapClick(data)}
    />
  );
};

export default WorldMapChart;
