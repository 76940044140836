import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { AppText, LinkType } from '@backlinkit/shared';
import { BaseBacklinkCell } from '../../../backlinks/components/cells/base-backlink-cell';

type AnchorCellProps = BaseBacklinkCell & {};

const CheckerAnchorCell: React.FC<AnchorCellProps> = ({ item }) => {

  return (
    <Flex flexDir={'row'} justifyContent={'start'} alignItems={'center'}>
      <Popover trigger="hover">
        <PopoverTrigger>
          <Flex>
            <AppText maxW={'200px'} isTruncated>
              {item?.backlinkMeta?.linkType !== LinkType.Image && item?.anchor}
            </AppText>
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Flex alignItems={'center'}>
              <AppText ml={2} whiteSpace={'normal'}>
                {item?.backlinkMeta?.linkType !== LinkType.Image && item?.anchor}
              </AppText>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default CheckerAnchorCell;
