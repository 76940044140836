import { Box, SimpleGrid } from '@chakra-ui/react';
import { AppColors, AppText, BackLinkListItem } from '@backlinkit/shared';
import CheckerAnchorCell from '../components/app-link-anchor-cell';
import CheckerGoogleCacheCell from '../components/app-link-cache-index';
import CheckerUrlCell from '../components/app-link-url-cell';
import CheckerGoogleIndexCell from '../components/app-link-gi-cell';
import CheckerLinkStatusCell from '../components/app-checker-link-status-cell';
import CheckerLandinPageCell from '../components/checker-landing-cell';
import { selectDomain } from '../../../../store/slices/domain.slice';
import CheckerRelCell from '../components/checker-rel-cell';
import { useAppSelector } from '../../../../store/store';

type LinksDetailsProps = {
  links: BackLinkListItem[];
};

const CheckerLinkCard: React.FC<LinksDetailsProps> = ({ links }) => {
  const domain = useAppSelector(selectDomain);

  return (
    <Box py={4}>
      {links.map((link, index) => (
        <SimpleGrid columns={2} spacingY={4} key={`${link.url}-${index}`}>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <AppText fontWeight={'700'} fontSize={'14px'}>
              Status:
            </AppText>
          </Box>

          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <CheckerLinkStatusCell
              isManual={link?.isManual}
              linkStatus={link?.backlinkMeta?.linkStatus}
            />
          </Box>

          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <AppText fontWeight={'700'} fontSize={'14px'}>
              URL:
            </AppText>
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <CheckerUrlCell url={link?.url ?? ''} />
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <AppText fontWeight={'700'} fontSize={'14px'}>
              Landing:
            </AppText>
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <CheckerLandinPageCell
              url={link?.url}
              landingPage={link?.landingPage}
              backlinkType={link?.backlinkType}
              linkStatus={link?.backlinkMeta?.linkStatus}
              domainUrl={domain?.url ?? ''}
            />
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <AppText fontWeight={'700'} fontSize={'14px'}>
              Anchor:
            </AppText>
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <CheckerAnchorCell item={link} />
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <AppText fontWeight={'700'} fontSize={'14px'}>
              GI:
            </AppText>
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <CheckerGoogleIndexCell
              url={link?.url}
              hasDomainIndex={link?.backlinkGoogle?.cacheState}
              hasPageIndex={link?.backlinkGoogle?.hasPageIndex}
              googleCheckDate={link?.backlinkGoogle?.lastGoogleCheckDate}
            />
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <AppText fontWeight={'700'} fontSize={'14px'}>
              GC:
            </AppText>
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <CheckerGoogleCacheCell
              url={link?.url}
              cacheState={link?.backlinkGoogle?.cacheState}
              cacheDate={link?.backlinkGoogle?.cacheCreatedDate}
              googleCheckDate={link?.backlinkGoogle?.lastGoogleCheckDate}
            />
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <AppText fontWeight={'700'} fontSize={'14px'}>
              REL:
            </AppText>
          </Box>
          <Box pb={4} borderBottom={`1px solid ${AppColors.appBorder}`}>
            <CheckerRelCell relStatus={link?.backlinkMeta?.relStatus} relValue={link?.relValue} />
          </Box>
        </SimpleGrid>
      ))}
    </Box>
  );
};

export default CheckerLinkCard;
