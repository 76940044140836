import { AnchorMetaData, AppColors } from '@backlinkit/shared';
import { WarningIcon } from '@chakra-ui/icons';
import { Flex, Icon, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { FaCheckCircle, FaTruckLoading } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';

type GoogleCacheCellProps = {
  url: string;
  cacheState?: boolean;
  cacheDate?: Date;
  googleCheckDate?: Date;
  domainMeta: AnchorMetaData[];
};

const GoogleCacheCell: React.FC<GoogleCacheCellProps> = ({
  url,
  cacheState,
  cacheDate,
  googleCheckDate,
  domainMeta,
}) => {
  if (url?.length === 0 || cacheState === undefined) {
    return <></>;
  }

  const getCacheDate = () => {
    return cacheDate ? format(new Date(cacheDate), 'dd MMM yyyy') : '';
  };

  const handleGoogleCacheCellClick = async () => {
    window.open(`http://webcache.googleusercontent.com/search?q=cache:${url}`, 'blank');
  };

  if (!googleCheckDate) {
    return (
      <Tooltip label={`In 24h`}>
        <span>
          <Icon onClick={handleGoogleCacheCellClick} color={'grey'} as={FaTruckLoading} />
        </span>
      </Tooltip>
    );
  }
  if (cacheState) {
    return (
      <Flex gap={'8px'}>
        <Tooltip label={`Cached - ${getCacheDate()}`}>
          <span>
            <Icon onClick={handleGoogleCacheCellClick} as={FcGoogle} />
          </span>
        </Tooltip>
        {domainMeta && domainMeta.length > 0 && (
          <Tooltip label={`Link found in cache`}>
            <span>
              <Icon color={'green'} onClick={handleGoogleCacheCellClick} as={FaCheckCircle} />
            </span>
          </Tooltip>
        )}
      </Flex>
    );
  } else {
    return (
      <Tooltip label={`Not Cached`}>
        <span>
          <Icon onClick={handleGoogleCacheCellClick} color={'orange'} as={WarningIcon} />
        </span>
      </Tooltip>
    );
  }
};

export default GoogleCacheCell;
