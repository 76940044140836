export type OccurrenceItem = {
  key: string;
  occurrence: number;
};

export const findOcc = (arr: any, key: string): OccurrenceItem[] => {
  const arr2: OccurrenceItem[] = [];

  arr.forEach((x: any) => {
    // Checking if there is any object in arr2
    // which contains the key value
    if (
      arr2.some((val) => {
        return val.key === x[key];
      })
    ) {
      // If yes! then increase the occurrence by 1
      arr2.forEach((k) => {
        if (k.key === x[key]) {
          k.occurrence++;
        }
      });
    } else {
      // If not! Then create a new object initialize
      // it with the present iteration key's value and
      // set the occurrence to 1
      let a: any = {};
      a.key = x[key];
      a.occurrence = 1;
      arr2.push(a);
    }
  });

  return arr2;
};
