import { useState } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { AppCheckbox, AppButton, AppColors } from '@backlinkit/shared';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';

export interface GoogleCheckerReprocessFormData {
  checkGoogleIndex: boolean;
  checkGoogleCache: boolean;
}

const googleCheckerFormDataSchema = yup.object({
  checkGoogleIndex: yup.boolean(),
  checkGoogleCache: yup.boolean(),
});

const defaultValues: GoogleCheckerReprocessFormData = {
  checkGoogleIndex: false,
  checkGoogleCache: false,
};

type GoogleCheckerReprocessFormProps<T> = {} & BaseFormProps<T>;

const BacklinkGoogleReprocessForm: React.FC<
  GoogleCheckerReprocessFormProps<GoogleCheckerReprocessFormData>
> = ({ onSubmit }) => {
  const { control, getValues } = useForm<GoogleCheckerReprocessFormData>({
    defaultValues: defaultValues,
    resolver: yupResolver(googleCheckerFormDataSchema),
    mode: 'onChange',
  });
  const [checkIndex, setCheckIndex] = useState<boolean>(false);
  const [checkCache, setCheckCache] = useState<boolean>(false);

  const handleFormSubmit = () => {
    const formData = getValues();
    onSubmit(formData);
  };

  return (
    <Stack spacing={4}>
      <Flex gap={3} py={4}>
        <AppCheckbox<GoogleCheckerReprocessFormData>
          name={'checkGoogleIndex'}
          control={control}
          error={undefined}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCheckIndex(!checkIndex)}
          label="Google Index"
        />
        <AppCheckbox<GoogleCheckerReprocessFormData>
          name="checkGoogleCache"
          control={control}
          error={undefined}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCheckCache(!checkCache)}
          label="Google Cache"
        />
      </Flex>
      <AppButton bgColor={AppColors.secondary} color={'white'} onClick={handleFormSubmit}>
        Reprocess
      </AppButton>
    </Stack>
  );
};

export default BacklinkGoogleReprocessForm;
