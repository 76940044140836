import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  Flex,
  FormLabel,
  SimpleGrid,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { backlinkURLRegex, domainURLRegex } from '../../constants/regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  BacklinkFormData,
  AppColors,
  AppButton,
  AppInput,
  AppText,
  SelectListFilter,
  SelectOption,
  AppAlertItem,
} from '@backlinkit/shared';
import { useCurrencies } from '../../hooks/useCurrencies';

export const backLinkFormDataDefaultValues: BacklinkFormData = {
  url: '',
  notes: '',
  expectedLandingPage: '',
  expectedAnchor: '',
  expectedRel: '',
  price: 0,
  priceCurrency: undefined,
  tempId: undefined,
};

const backLinkFormDataSchema = yup.object({
  url: yup.string().required('Field is required').matches(backlinkURLRegex, 'Not a valid Url'),
  price: yup.number(),
});

type BackLinkCreateFormType<T> = {
  form?: BacklinkFormData;
} & BaseFormProps<T>;

const BackLinkCreateForm: React.FC<BackLinkCreateFormType<BacklinkFormData[]>> = ({
  form,
  onSubmit,
}) => {
  const [relSplit, setRelSplit] = useState<string[]>([]);
  const [backlinksList, setBacklinksList] = useState<BacklinkFormData[]>([]);
  const [relFollow, setRelFollow] = useState<boolean>(false);
  const [edittingAddedBacklink, setEdittingAddedBacklink] = useState<boolean>(false);
  const [currencyOptions, setCurrencyOptions] = useState<SelectOption[]>();
  const [warning, setWarning] = useState<boolean>(false);

  const {
    setValue,
    control: backLinkControl,
    formState: { isValid, errors },
    getValues,
    reset,
  } = useForm<BacklinkFormData>({
    defaultValues: form || backLinkFormDataDefaultValues,
    resolver: yupResolver(backLinkFormDataSchema),
    mode: 'onChange',
  });

  const currencies = useCurrencies();

  useEffect(() => {
    if (form) {
      const relSplit = form?.expectedRel?.split(',') || [];
      setRelSplit(relSplit);
      if (relSplit.includes('follow')) {
        setRelFollow(true);
      }
    }
  }, [form]);

  const addBacklinkToList = () => {
    const currentForm = getValues();
    console.log('addBacklinkToList', currentForm);
    if (isValid && !backlinksList.some((x) => x.tempId === currentForm.tempId)) {
      currentForm.tempId = backlinksList.length;
      setBacklinksList([...backlinksList, currentForm]);
      setRelSplit([]);
      reset();
    } else if (isValid) {
      const index = backlinksList.findIndex((x) => x.tempId === currentForm.tempId);
      if (index !== -1) {
        const newBacklinksList = [...backlinksList];
        newBacklinksList[index] = currentForm;
        setBacklinksList(newBacklinksList);
        setRelSplit([]);
        reset();
      }
    }
  };

  const handleEditAddedBacklink = (link: BacklinkFormData) => {
    setEdittingAddedBacklink(true);
    reset(link);
  };

  const handleDeleteAddedBacklink = (link: BacklinkFormData) => {
    const newBacklinksList = [...backlinksList].filter((x) => x.tempId !== link.tempId);
    setBacklinksList(newBacklinksList);
  };

  const cancelAddedBacklinkEditting = () => {
    setEdittingAddedBacklink(false);
    setRelSplit([]);
    reset(backLinkFormDataDefaultValues);
  };

  const onRelSelectChange = (checked: boolean, rel: string) => {
    if (rel === 'follow' && checked) {
      setRelFollow(true);
      setRelSplit([rel]);
      setValue('expectedRel', rel);
    } else {
      setRelFollow(false);
      if (checked) {
        setRelSplit([...relSplit, rel]);
        setValue('expectedRel', [...relSplit, rel].join(','));
      } else {
        const relCopy = [...relSplit];
        const relIndex = relCopy.findIndex((x) => x === rel);
        if (relIndex !== -1) {
          relCopy.splice(relIndex, 1);
        }
        setRelSplit(relCopy);
        setValue('expectedRel', relCopy.join(','));
      }
    }
  };

  const handleFormSubmit = (formData?: BacklinkFormData) => {
    console.log('handleFormSubmit', formData);
    if (form?.id && formData) {
      const validFormData = formSubmitValidation([formData]);
      console.log('validFormData', validFormData);
      onSubmit(validFormData);
    } else {
      const validMultiFormData = formSubmitValidation(backlinksList);
      onSubmit(validMultiFormData);
    }
  };

  // OnSubmit custom validation fn
  const formSubmitValidation = (formData: BacklinkFormData[]) => {
    console.log('formSubmitValidation', formData);
    const formDataList = [...formData];
    return formDataList;
  };

  const getCurrencyWarning = () => {
    const formData = getValues();
    if (formData.price && formData.price > 0) {
      switch (formData?.priceCurrency) {
        case undefined:
          setWarning(true);
          break;
        case '':
          setWarning(true);
          break;
        default:
          setWarning(false);
          break;
      }
    }
  };

  useEffect(() => {
    setCurrencyOptions(currencies);
    getCurrencyWarning();
  }, [currencies, form?.priceCurrency]);

  return (
    <Flex flexDirection={'column'} flex={1}>
      <form>
        <AppInput<BacklinkFormData>
          mt={6}
          flex={1}
          control={backLinkControl}
          name="url"
          label="URL from"
          error={errors.url}
          placeHolder={'Url'}
        />
        <SimpleGrid columns={2} columnGap={4} pb={4}>
          <AppInput<BacklinkFormData>
            mt={6}
            control={backLinkControl}
            error={undefined}
            name="price"
            label="Price"
            placeHolder={'Price'}
            inputType="number"
            flex={1}
            onChange={() => {
              getCurrencyWarning();
            }}
          />
          <Flex height={'100%'} align={'end'}>
            <SelectListFilter
              flex={1}
              mr={2}
              options={currencyOptions || []}
              isInModal
              isMulti={false}
              showClear={false}
              placeholder="Select Currency"
              onSelectionChange={(item: SelectOption[]) => {
                setValue('priceCurrency', item[0].value);
                getCurrencyWarning();
              }}
            />
            <AppButton
              size={'xs'}
              marginBottom={'10px'}
              onClick={() => {
                setValue('price', 0);
                setValue('priceCurrency', undefined);
              }}
            >
              Clear Price
            </AppButton>
          </Flex>
        </SimpleGrid>
        <FormLabel mt={2}>
          Please Note - The price & currency will apply to all added links*
        </FormLabel>
        <Accordion allowToggle mt={4}>
          <AccordionItem>
            <AccordionButton>
              <AppText
                textAlign={'left'}
                w={'100%'}
                variant={'h3'}
                fontWeight={'medium'}
                fontSize={'18px'}
              >
                Additional Details
              </AppText>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <SimpleGrid columns={2} spacing={5}>
                <AppInput<BacklinkFormData>
                  mt={6}
                  control={backLinkControl}
                  error={errors.expectedAnchor}
                  name="expectedAnchor"
                  label="Expected Anchor"
                  placeHolder={'Anchor'}
                  flex={1}
                />
                <AppInput<BacklinkFormData>
                  mt={6}
                  control={backLinkControl}
                  error={errors.expectedLandingPage}
                  name="expectedLandingPage"
                  label="Expected Landing Page"
                  placeHolder={'Landing Page'}
                  flex={1}
                />
              </SimpleGrid>

              <AppInput<BacklinkFormData>
                mt={6}
                control={backLinkControl}
                error={errors.notes}
                label="Notes"
                name="notes"
                placeHolder={'Notes'}
                textArea={true}
                flex={1}
              />
              <Flex mt={6} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Checkbox
                  isChecked={relSplit.includes('follow')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onRelSelectChange(event.currentTarget.checked, 'follow');
                  }}
                >
                  Follow
                </Checkbox>
                <Checkbox
                  isDisabled={relFollow}
                  isChecked={relSplit.includes('nofollow')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onRelSelectChange(event.currentTarget.checked, 'nofollow');
                  }}
                >
                  No Follow
                </Checkbox>
                <Checkbox
                  isDisabled={relFollow}
                  isChecked={relSplit.includes('ugc')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onRelSelectChange(event.currentTarget.checked, 'ugc');
                  }}
                >
                  UGC
                </Checkbox>
                <Checkbox
                  isDisabled={relFollow}
                  isChecked={relSplit.includes('sponsored')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onRelSelectChange(event.currentTarget.checked, 'sponsored');
                  }}
                >
                  Sponsored
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          {!form?.id && (
            <AccordionItem>
              <AccordionButton>
                <Flex flex={1} justifyContent={'space-between'} pr={4}>
                  <AppText
                    textAlign={'left'}
                    w={'100%'}
                    variant={'h3'}
                    fontWeight={'medium'}
                    fontSize={'18px'}
                  >
                    Added Backlinks
                  </AppText>
                  <AppText
                    px={2}
                    py={1}
                    borderRadius="full"
                    bgColor={AppColors.secondary}
                    textColor={'white'}
                  >
                    {backlinksList.length}
                  </AppText>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Url</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {backlinksList.map((link, index) => {
                        return (
                          <Tr key={`${link.url}-${index}`}>
                            <Td>
                              <AppText isTruncated>{link.url}</AppText>
                            </Td>
                            <Td>
                              <Flex>
                                <AppButton
                                  mr={4}
                                  bgColor={AppColors.secondary}
                                  variant={'solid'}
                                  size="xs"
                                  borderRadius="full"
                                  onClick={() => {
                                    handleEditAddedBacklink(link);
                                  }}
                                  rightIcon={<SettingsIcon />}
                                >
                                  Edit
                                </AppButton>
                                <AppButton
                                  mr={4}
                                  bgColor={AppColors.secondary}
                                  variant={'solid'}
                                  size="xs"
                                  borderRadius="full"
                                  onClick={() => {
                                    handleDeleteAddedBacklink(link);
                                  }}
                                  rightIcon={<DeleteIcon />}
                                >
                                  Delete
                                </AppButton>
                              </Flex>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
        {!form?.id && (
          <AppButton
            mt={8}
            w={'full'}
            isDisabled={!isValid}
            onClick={() => {
              addBacklinkToList();
            }}
          >
            {edittingAddedBacklink ? 'Update added Backlink' : 'Add Backlink'}
          </AppButton>
        )}
        {edittingAddedBacklink && (
          <AppButton
            mt={4}
            w={'full'}
            onClick={() => {
              cancelAddedBacklinkEditting();
            }}
          >
            {'Cancel editting Backlink'}
          </AppButton>
        )}
        {!form?.id && (
          <AppButton
            mt={4}
            w={'full'}
            isDisabled={backlinksList.length === 0}
            onClick={() => {
              handleFormSubmit();
            }}
          >
            {'Process Backlinks!'}
          </AppButton>
        )}
        {form?.id && (
          <AppButton
            mt={8}
            w={'full'}
            isDisabled={!isValid && !warning}
            onClick={() => {
              handleFormSubmit(getValues());
            }}
          >
            {'Save'}
          </AppButton>
        )}
      </form>
      {warning && (
        <AppAlertItem
          item={{
            name: 'Curency required',
            nameLabel: undefined,
            alertType: 'warning',
            alertMessage: 'Field is required',
            alertMessageLabel: undefined,
            alertNote: undefined,
            alertNoteLabel: undefined,
            entityId: undefined,
            alertKey: undefined,
          }}
        />
      )}
    </Flex>
  );
};

export default BackLinkCreateForm;
