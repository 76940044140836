import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppButton,
  AppColors,
  AppConfirm,
  LoadingComponent,
  SelectListFilter,
  SelectOption,
  useDialog,
} from '@backlinkit/shared';
import { BaseFormProps } from './base-forms';
import { Flex, FormLabel, Stack } from '@chakra-ui/react';
import { useFetchAllOrganizationBudgetTypesQuery } from '../../store/api/organizationBudgetTypeApi';
import { useFetchAllOrganizationLinkBuildingStategiesTypesQuery } from '../../store/api/organizationLinkBuilidingStrategiesTypeApi';
import { useFetchAllOrganizationSEOExperienceTypesQuery } from '../../store/api/organizationSEOExperienceTypeApi';
import { useFetchAllOrganizationTypesQuery } from '../../store/api/organizationTypeApi';

export type OrganizationDetailFormData = {
  organizationBudgetTypeId?: string;
  organizationLinkBuildingStratergiesTypeId?: string;
  organizationSEOExperienceTypeId?: string;
  organizationTypeId?: string;
};

export const OrganizationDetailFormValues: OrganizationDetailFormData = {
  organizationBudgetTypeId: undefined,
  organizationLinkBuildingStratergiesTypeId: undefined,
  organizationSEOExperienceTypeId: undefined,
  organizationTypeId: undefined,
};

export const OrganizationDetailDataSchema = yup.object({
  organizationBudgetTypeId: yup.string(),
  organizationLinkBuildingStratergiesTypeId: yup.string(),
  organizationSEOExperienceTypeId: yup.string(),
  organizationTypeId: yup.string().required('Field is required'),
});

type OrganizationDetailFormProps<T> = {
  form?: OrganizationDetailFormData;
} & BaseFormProps<T>;

const OrganizationDetailForm: React.FC<OrganizationDetailFormProps<OrganizationDetailFormData>> = ({
  form,
  onSubmit,
}) => {
  const dialog = useDialog();

  const {
    formState: { isValid },
    setValue,
    getValues,
  } = useForm<OrganizationDetailFormData>({
    defaultValues: form || OrganizationDetailFormValues,
    resolver: yupResolver(OrganizationDetailDataSchema),
    mode: 'onChange',
  });

  const [organizationBudgetTypeOptions, setOrganizationBudgetTypeOptions] =
    useState<SelectOption[]>();
  const [
    organizationLinkBuildingStategiesTypeOptions,
    setOrganizationLinkBuildingStategiesTypeOptions,
  ] = useState<SelectOption[]>();
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<SelectOption[]>();
  const [organizationSEOExperienceTypeOptions, setOrganizationSEOExperienceTypeOptions] =
    useState<SelectOption[]>();

  const { data: organizationBudgetTypes, isFetching: isFetchingOrganizationBudgetTypes } =
    useFetchAllOrganizationBudgetTypesQuery({
      refetchOnMountArgChange: true,
    });
  const {
    data: organizationLinkBuildingStategiesTypes,
    isFetching: isFetchingOrganizationLinkBuildingStategiesTypes,
  } = useFetchAllOrganizationLinkBuildingStategiesTypesQuery({
    refetchOnMountArgChange: true,
  });
  const { data: organizationTypes, isFetching: isFetchingOrganizationTypes } =
    useFetchAllOrganizationTypesQuery({
      refetchOnMountArgChange: true,
    });
  const {
    data: organizationSEOExperienceTypes,
    isFetching: isFetchingOrganizationSEOExperienceTypes,
  } = useFetchAllOrganizationSEOExperienceTypesQuery({
    refetchOnMountArgChange: true,
  });

  useEffect(() => {
    if (organizationBudgetTypes) {
      const selectors: SelectOption[] = [];
      organizationBudgetTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationBudgetTypeOptions(selectors);
    }
  }, [organizationBudgetTypes]);

  useEffect(() => {
    if (organizationLinkBuildingStategiesTypes) {
      const selectors: SelectOption[] = [];
      organizationLinkBuildingStategiesTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationLinkBuildingStategiesTypeOptions(selectors);
    }
  }, [organizationLinkBuildingStategiesTypes]);

  useEffect(() => {
    if (organizationTypes) {
      const selectors: SelectOption[] = [];
      organizationTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationTypeOptions(selectors);
    }
  }, [organizationTypes]);

  useEffect(() => {
    if (organizationSEOExperienceTypes) {
      const selectors: SelectOption[] = [];
      organizationSEOExperienceTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationSEOExperienceTypeOptions(selectors);
    }
  }, [organizationSEOExperienceTypes]);

  const handleFormSubmit = () => {
    dialog({
      title: 'Are you happy with your selection?',
      render: (onCancel) => {
        return (
          <AppConfirm
            message={`Completing this will help us provide you with valuable insights and even better service!`}
            onConfirm={async () => {
              onCancel();

              const formData = getValues();

              const details: OrganizationDetailFormData = {
                organizationBudgetTypeId: formData.organizationBudgetTypeId,
                organizationLinkBuildingStratergiesTypeId:
                  formData.organizationLinkBuildingStratergiesTypeId,
                organizationSEOExperienceTypeId: formData.organizationSEOExperienceTypeId,
                organizationTypeId: formData.organizationTypeId,
              };
              onSubmit(details);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  return (
    <Flex width={'full'}>
      {(isFetchingOrganizationBudgetTypes ||
        isFetchingOrganizationLinkBuildingStategiesTypes ||
        isFetchingOrganizationSEOExperienceTypes ||
        isFetchingOrganizationTypes) && <LoadingComponent />}
      <Stack spacing={4} width={'full'}>
        <FormLabel p={0} m={0}>
          Account Type
        </FormLabel>
        <SelectListFilter
          name="organizationType"
          options={organizationTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationTypeId', items[0].value, { shouldValidate: true });
          }}
        />
        <FormLabel m={0} p={0}>
          Budget
        </FormLabel>
        <SelectListFilter
          name="organizationBudgetTypeId"
          options={organizationBudgetTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationBudgetTypeId', items[0].value);
          }}
        />
        <FormLabel m={0} p={0}>
          Link Building Stratergies
        </FormLabel>
        <SelectListFilter
          name="organizationLinkBuildingStratergiesTypeId"
          options={organizationLinkBuildingStategiesTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationLinkBuildingStratergiesTypeId', items[0].value);
          }}
        />
        <FormLabel m={0} p={0}>
          SEO Experience
        </FormLabel>
        <SelectListFilter
          name="organizationSEOExperienceTypeId"
          options={organizationSEOExperienceTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationSEOExperienceTypeId', items[0].value);
          }}
        />
        <AppButton
          mt={2}
          variant={'solid'}
          bgColor={AppColors.secondary}
          color={'white'}
          onClick={handleFormSubmit}
          isDisabled={!isValid}
        >
          Save
        </AppButton>
      </Stack>
    </Flex>
  );
};

export default OrganizationDetailForm;
