import { Flex, Stack, useToast } from '@chakra-ui/react';
import {
  AppButton,
  AppColors,
  AppLoader,
  AppUserNotification,
  Notification,
} from '@backlinkit/shared';
import {
  useDeleteAllUserNotificationsMutation,
  useDeleteNotificationMutation,
} from '../../store/api/notificationsApi';
import { useAppSelector } from '../../store/store';
import { selectUserNotifications } from '../../store/slices/user.slice';
import { selectUser } from '../../store/slices/authentication.slice';

export type NotificationListComponentProps = {
  refetchNotifications: () => void;
};

const NotificationListComponent: React.FC<NotificationListComponentProps> = ({
  refetchNotifications,
}) => {
  const toast = useToast();
  const notifications = useAppSelector(selectUserNotifications);
  const user = useAppSelector(selectUser);
  const [deleteNotification, { isLoading }] = useDeleteNotificationMutation();

  const [deleteAllUserNotification, { isLoading: isAllDeleteLoading }] =
    useDeleteAllUserNotificationsMutation();

  const handleDelete = async (item: Notification) => {
    try {
      const deletePromise = deleteNotification(item.id).unwrap();
      await deletePromise;
    } catch (err) {
      toast({
        title: 'Notification Delete Error',
        description: `We've run into a problem deleting the selected notification. Contact support for help`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Notification deleted',
      description: `We've deleted the notification you selected`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    refetchNotifications();
  };

  const handleDeleteAll = async () => {
    try {
      await deleteAllUserNotification(user?.id ?? '').unwrap();
    } catch (err) {
      toast({
        title: 'Notification Delete Error',
        description: `We've run into a problem deleting all notifications. Contact support for help`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Notification deleted',
      description: `We've deleted the notification you selected`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    refetchNotifications();
  };

  return (
    <>
      {(isLoading || isAllDeleteLoading) && <AppLoader />}
      <Stack spacing={4}>
        <AppButton bgColor={AppColors.secondary} color={'white'} onClick={() => handleDeleteAll()}>
          Clear All
        </AppButton>

        <Flex direction={'column'} gap={3}>
          {notifications?.map((item, index) => (
            <AppUserNotification
              key={`${item.title}-${index}`}
              item={item}
              itemsClearable
              showName
              onItemClearClick={async () => {
                await handleDelete(item);
              }}
              // onItemClick={() => {
              //   handleNotificationOpen(item);
              // }}
            />
          ))}
        </Flex>
      </Stack>
    </>
  );
};

export default NotificationListComponent;
