import React from 'react';
import { Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, RepeatIcon } from '@chakra-ui/icons';
import { BiExport } from 'react-icons/bi';
import { HiClipboardList } from 'react-icons/hi';
import { AppButtonAs, AppButton } from '@backlinkit/shared';

export interface BacklinkActionsMenuProps {
  isDisabled: boolean;
  onRefreshBacklinks?: () => void;
  onReProcessBacklinksGoogle?: () => void;
  onDeleteBacklinks?: () => void;
  onExportBacklinks?: () => void;
  onCreateBacklinkExternalReport?: () => void;
}

const BacklinkActionsMenu: React.FC<BacklinkActionsMenuProps> = ({
  isDisabled,
  onDeleteBacklinks,
  onRefreshBacklinks,
  onReProcessBacklinksGoogle,
  onExportBacklinks,
  onCreateBacklinkExternalReport,
}) => {
  const handleBacklinksRefresh = () => {
    onRefreshBacklinks && onRefreshBacklinks();
  };

  const handleReProcessBacklinksGoogle = () => {
    onReProcessBacklinksGoogle && onReProcessBacklinksGoogle();
  };

  const handleBacklinksDelete = () => {
    onDeleteBacklinks && onDeleteBacklinks();
  };

  const handleBacklinksExport = () => {
    onExportBacklinks && onExportBacklinks();
  };

  const handleBacklinksExternalReport = () => {
    onCreateBacklinkExternalReport && onCreateBacklinkExternalReport();
  };

  return (
    <Menu>
      <MenuButton
        mr={4}
        as={AppButtonAs}
        rightIcon={<ChevronDownIcon />}
        size="sm"
        borderRadius={'full'}
      >
        Actions
      </MenuButton>
      <MenuList zIndex={4}>
        <Flex flexDirection={'column'} px={4} py={2} gap={2}>
          <AppButton
            isDisabled={isDisabled}
            onClick={handleBacklinksExternalReport}
            leftIcon={<HiClipboardList />}
          >
            Create External Report
          </AppButton>

          <AppButton
            isDisabled={isDisabled}
            onClick={handleBacklinksExport}
            leftIcon={<BiExport />}
          >
            Export
          </AppButton>
          <AppButton
            isDisabled={isDisabled}
            onClick={handleBacklinksRefresh}
            leftIcon={<RepeatIcon />}
          >
            Reprocess
          </AppButton>
          <AppButton
            isDisabled={isDisabled}
            onClick={handleReProcessBacklinksGoogle}
            leftIcon={<RepeatIcon />}
          >
            Reprocess Google Checks
          </AppButton>
          <AppButton
            isDisabled={isDisabled}
            onClick={handleBacklinksDelete}
            leftIcon={<DeleteIcon />}
          >
            Delete
          </AppButton>
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default BacklinkActionsMenu;
