import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  OrganizationCompetitor,
  OrganizationCompetitorForm,
  EditOrganizationCompetitorForm,
} from '@backlinkit/shared';

export const organizationCompetitorApi = createApi({
  reducerPath: 'organizationCompetitor',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationCompetitors: build.query<OrganizationCompetitor[], string>({
      query: (orgId) => ({
        url: `/organizationcompetitor/by/org/${orgId}`,
        method: 'GET',
      }),
    }),
    fetchOrganizationCompetitorById: build.query<OrganizationCompetitor, string>({
      query: (id) => ({
        url: `/organizationcompetitor/${id}`,
        method: 'GET',
      }),
    }),
    createOrganizationCompetitor: build.mutation<
      OrganizationCompetitor,
      OrganizationCompetitorForm
    >({
      query: (body) => ({
        url: '/organizationcompetitor/create',
        method: 'POST',
        body: body,
      }),
    }),
    editOrganizationCompetitor: build.mutation<
      OrganizationCompetitor,
      EditOrganizationCompetitorForm
    >({
      query: (body) => ({
        url: `/organizationcompetitor/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteOrganizationCompetitor: build.mutation<string, string>({
      query: (id) => ({
        url: `/organizationcompetitor/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateOrganizationCompetitorMutation,
  useDeleteOrganizationCompetitorMutation,
  useEditOrganizationCompetitorMutation,
  useFetchAllOrganizationCompetitorsQuery,
  useFetchOrganizationCompetitorByIdQuery,
  useLazyFetchAllOrganizationCompetitorsQuery,
  useLazyFetchOrganizationCompetitorByIdQuery,
} = organizationCompetitorApi;
