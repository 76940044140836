import React from 'react';
import { AppInput, AppButton, AppColors, AppText } from '@backlinkit/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { BaseFormProps } from './base-forms';
import { Stack } from '@chakra-ui/react';

export type ContactFormData = {
  name: string;
  email: string;
  body: string;
};

export const contactFormDefaultData: ContactFormData = {
  name: '',
  email: '',
  body: '',
};

const contactFormDataSchema = yup.object({
  name: yup.string(),
  email: yup.string().required('Field is required'),
  body: yup.string().required('Field is required'),
});

type ContactFormProps<T> = {
  form?: ContactFormData;
} & BaseFormProps<T>;

const ContactForm: React.FC<ContactFormProps<ContactFormData>> = ({ form, onSubmit }) => {
  const {
    control: contactControl,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<ContactFormData>({
    defaultValues: form || contactFormDefaultData,
    resolver: yupResolver(contactFormDataSchema),
    mode: 'onChange',
  });

  return (
    <Stack spacing={6} w={'full'}>
      <AppText fontSize={'xl'} fontWeight={'600'}>
        Contact us for our Enterprise plan!
      </AppText>
      <AppInput<ContactFormData>
        name="body"
        textArea={true}
        control={contactControl}
        error={errors.body}
        label="Message:"
      />
      <AppButton
        bgColor={AppColors.secondary2}
        color={'white'}
        borderRadius={'full'}
        w={'full'}
        onClick={handleSubmit(onSubmit)}
      >
        Send
      </AppButton>
    </Stack>
  );
};

export default ContactForm;
