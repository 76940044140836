import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BacklinkImportFileValidationResult } from '../../utils/sharedFormHelpers';
import { AppColors, AppText } from '@backlinkit/shared';

type BacklinkCsvImportValidationErrorsProps = {
  validation: BacklinkImportFileValidationResult;
};

const BacklinkCsvImportValidationErrors: React.FC<BacklinkCsvImportValidationErrorsProps> = ({
  validation,
}) => {
  console.log(validation);

  return (
    <Flex flexDirection={'column'} flex={1}>
      <>
        <Accordion allowToggle mt={4}>
          <AccordionItem>
            <AccordionButton>
              <Flex flex={1} justifyContent={'space-between'} pr={4}>
                <AppText
                  textAlign={'left'}
                  w={'100%'}
                  variant={'h3'}
                  fontWeight={'medium'}
                  fontSize={'18px'}
                >
                  Invalid Validated Backlink Imports
                </AppText>
                <Tag borderRadius="full" bgColor={AppColors.secondary} textColor={'white'}>
                  <TagLabel isTruncated={false} display={'flex'} overflow={'visible'}>
                    {validation.invalidBacklinkImports.length}
                  </TagLabel>
                </Tag>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              pb={4}
              maxH={'200px'}
              overflowY={'auto'}
              overflowX={'auto'}
              maxW={'100%'}
            >
              <TableContainer overflowY={'visible'} overflowX={'visible'}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>File Line Number</Th>
                      <Th>Value </Th>
                      <Th>Error</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {validation.invalidBacklinkImports.map((link, index) => {
                      return (
                        <Tr key={`${link.lineNumber}-${index}`}>
                          <Td>
                            <AppText isTruncated>{link.lineNumber}</AppText>
                          </Td>
                          <Td>
                            <AppText isTruncated>{link.value}</AppText>
                          </Td>
                          <Td>
                            <AppText isTruncated>{link.message}</AppText>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </>
    </Flex>
  );
};

export default BacklinkCsvImportValidationErrors;
