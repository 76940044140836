import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { OrganizationLinkBuildingStratergiesTypeDto } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationLinkBuildingStratergiesTypeApi = createApi({
  reducerPath: 'orgaizationLinkBuildingStratergiesType',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationLinkBuildingStategiesTypes: build.query<
      OrganizationLinkBuildingStratergiesTypeDto[],
      any
    >({
      query: () => ({
        url: '/organizationLinkBuildingStratergiesType',
        method: 'GET',
      }),
    }),
  }),
});
export const { useFetchAllOrganizationLinkBuildingStategiesTypesQuery } =
  organizationLinkBuildingStratergiesTypeApi;
