import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Bug, BugCreateForm, BugEditForm } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const bugApi = createApi({
  reducerPath: 'bugApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllBugs: build.query<Bug[], any>({
      query: () => ({
        url: '/bugReport',
        method: 'GET',
      }),
    }),
    fetchBugById: build.query<Bug, string>({
      query: (id) => ({
        url: `/bugReport/${id}`,
        method: 'GET',
      }),
    }),
    createBug: build.mutation<Bug, BugCreateForm>({
      query: (payload) => ({
        url: '/bugReport/create',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: Bug) => {
        return response;
      },
    }),
    updateBug: build.mutation<Bug, BugEditForm>({
      query: (payload) => ({
        url: `/bugReport/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteBug: build.mutation<Bug, string>({
      query: (id) => ({
        url: `/bugReport/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateBugMutation,
  useDeleteBugMutation,
  useFetchAllBugsQuery,
  useFetchBugByIdQuery,
  useUpdateBugMutation,
} = bugApi;
