// Chakra imports
import { Box, Card, Flex, Icon, Progress, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import { GiPayMoney } from 'react-icons/gi';
import IconBox from './IconBox';
import { AppButton, AppColors } from '@backlinkit/shared';

export default function Subscription(props: { used: number; total: number; [x: string]: any }) {
  const { used, total } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const box = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  return (
    <Card
      variant="filled"
      bg={'white'}
      p="4"
      rounded={'2xl'}
      mb={{ base: '0px', lg: '20px' }}
      alignItems="center"
      h="100%"
      w={'100%'}
      justify={'center'}
    >
      <IconBox
        mx="auto"
        h="60px"
        w="60px"
        icon={<Icon as={GiPayMoney} color={AppColors.secondary} h="46px" w="46px" />}
        bg={box}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        Your Subscription
      </Text>
      <Text
        color={textColorSecondary}
        fontSize="sm"
        maxW={{ base: '100%', xl: '80%', '3xl': '60%' }}
        mx="auto"
      >
        Amount of links used
      </Text>
      <Box w="100%">
        <Flex w="100%" justify="space-between" mb="10px">
          <Text color={textColorSecondary} fontSize="sm" maxW="40%">
            {used}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" maxW="40%">
            {total}
          </Text>
        </Flex>
        <Progress alignItems="start" value={(used / total) * 100} w="100%" />
      </Box>
    </Card>
  );
}
