import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { OrganizationSourceEntityType } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationSourceTypeApi = createApi({
  reducerPath: 'organizationSourceTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchOrganizationSourceTypeById: build.query<OrganizationSourceEntityType, string>({
      query: (id) => ({
        url: `/organizationsourcetype/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchOrganizationSourceTypeByIdQuery } = organizationSourceTypeApi;
