import { Flex } from '@chakra-ui/react';
import { ReactNode, useEffect } from 'react';
import DomainHeader from './domain-header';
import { useDispatch } from 'react-redux';
import { selectDomain, selectDomains, setSelectedDomain } from '../../store/slices/domain.slice';
import { selectUser } from '../../store/slices/authentication.slice';
import { useLazyFetchDomainsByOrgIdQuery } from '../../store/api/domainApi';
import { useLazyFetchBatchesByDomainQuery } from '../../store/api/backlinkBatchesApi';
import { useAppSelector } from '../../store/store';

export type DomainLayoutProps = {
  children?: ReactNode | ReactNode[];
};
const DomainLayout: React.FC<DomainLayoutProps> = ({ children }) => {
  const domain = useAppSelector(selectDomain);
  const domains = useAppSelector(selectDomains);
  const dispatch = useDispatch();
  const user = useAppSelector(selectUser);
  const [getDomainsByOrgId] = useLazyFetchDomainsByOrgIdQuery();
  const [getBatchesByDomain] = useLazyFetchBatchesByDomainQuery();

  useEffect(() => {
    if (user && user.organizationId) {
      getDomainsByOrgId(user.organizationId);
    }
  }, [user]);

  useEffect(() => {
    if (!domain && domains && domains.length > 0) {
      dispatch(setSelectedDomain(domains[0]));
      getBatchesByDomain(domains[0].id);
    }
  }, [domains]);

  return (
    <>
      <Flex direction={'column'} w={'100%'} h={'100%'}>
        {domain ? (
          <>
            <DomainHeader domain={domain} />
            <Flex id="domain-layout-container" w={'100%'} h={'100%'}>
              {children}
            </Flex>
          </>
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
};

export default DomainLayout;
