import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import BaseLayout from '../components/layouts/base-layout';
import { useAppSelector } from '../store/store';
import { selectToken } from '../store/slices/authentication.slice';
import { isAuthTokenValid } from '../utils/auth-util';
import { RoutesList } from './router';
import { useToast } from '@chakra-ui/react';

const RouterOutlet: React.FC = () => {
  const toast = useToast();
  const userAuthToken = useAppSelector(selectToken);
  const navigate = useNavigate();
  useEffect(() => {
    if (userAuthToken && !isAuthTokenValid(userAuthToken)) {
      toast({
        title: 'Session expired.',
        description: 'Your session has expired, please login to continue.',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      navigate(RoutesList.Index);
    }
  }, [userAuthToken]);

  return (
    <React.Fragment>
      <BaseLayout>
        <Outlet />
      </BaseLayout>
    </React.Fragment>
  );
};

const AuthOutlet: React.FC = () => {
  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
};

export { RouterOutlet, AuthOutlet };
