import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  AppButton,
  AppColors,
  AppDataTable,
  AppLoader,
  AppText,
  BacklinkEFL,
  ColumnDefinitionType,
  OrganizationCompetitor,
  OrganizationExludeLink,
  useDialog,
  useLoading,
} from '@backlinkit/shared';

import { ChevronDownIcon, AddIcon } from '@chakra-ui/icons';
import { useFetchBacklinkEflsQuery } from '../../store/api/backlinkEflApi';
import {
  useCreateOrganizationCompetitorMutation,
  useLazyFetchAllOrganizationCompetitorsQuery,
} from '../../store/api/organization-competitorsApi';
import {
  useCreateOrganizationExcludeLinkMutation,
  useLazyFetchAllOrganizationExcludeLinksQuery,
} from '../../store/api/organizationExludeLinkApi';
import StatusIndicator from '../status-indicator/status-indicator';
import UrlCell from './components/cells/url-cell';
import {
  selectOrganizationExludeLinks,
  selectOrganizationCompetitors,
} from '../../store/slices/organization.slice';
import { useAppSelector } from '../../store/store';
import ExludeListManagement from '../dialog-management/exclude-list-management';
import CompetitorManagement from '../dialog-management/competitor-management';

type EflPanelProps = {
  backlinkId: string;
  organizationId: string;
  parentUrl: string;
};

interface EflItem {
  href: string;
  type: string;
  isCompetitor: boolean;
  isHighlighted: boolean;
  domain?: string;
}

interface DomainGrouping {
  domain: string;
  eflItems: EflItem[];
}

const EflPanel: React.FC<EflPanelProps> = ({ organizationId, parentUrl, backlinkId }) => {
  const organizationExludeLinks = useAppSelector(selectOrganizationExludeLinks);
  const organizationCompetitors = useAppSelector(selectOrganizationCompetitors);

  const { setLoading } = useLoading();
  const toast = useToast();
  const dialog = useDialog();

  const [domainGroups, setDomainGroups] = useState<DomainGrouping[]>([]);

  const { data: backlinkEfl, isFetching } = useFetchBacklinkEflsQuery(backlinkId);

  const [saveExludeLinkTrigger] = useCreateOrganizationExcludeLinkMutation();
  const [fetchAllOrganizationExcludeLinks] = useLazyFetchAllOrganizationExcludeLinksQuery();
  const [saveCompetitorTrigger] = useCreateOrganizationCompetitorMutation();
  const [fetchAllOrganizationCompetitors] = useLazyFetchAllOrganizationCompetitorsQuery();

  const addToExcludeList = async (item: EflItem) => {
    debugger;
    try {
      setLoading(true);
      await saveExludeLinkTrigger({
        link: item.href,
        organizationId: organizationId,
      });

      toast({
        title: `Exclude Link created`,
        description: `We've created your exclude link.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      if (backlinkEfl) {
        const excludeList = await fetchAllOrganizationExcludeLinks(organizationId).unwrap();
        setupList(backlinkEfl, excludeList, organizationCompetitors);
      }

      setLoading(false);
    } catch (error) {
      toast({
        title: `Exlude Link already exists in the system`,
        description: `Please change the link and try again`,
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  const addToCompetitorList = async (item: EflItem) => {
    try {
      setLoading(true);

      const urlObject = new URL(item.href);
      const urlDomain = urlObject.hostname;

      await saveCompetitorTrigger({
        competitorDomain: urlDomain,
        competitorName: urlDomain,
        organizationId: organizationId,
      });

      toast({
        title: `Competitor created`,
        description: `We've saved your competitor for you.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      if (backlinkEfl) {
        const competitorList = await fetchAllOrganizationCompetitors(organizationId).unwrap();
        setupList(backlinkEfl, organizationExludeLinks, competitorList);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: `Competitor already exists in the system.`,
        description: `Please change the competitor name & try again`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  const renderDefaultTableActions = (item: EflItem) => {
    if (!item.isHighlighted) {
      return (
        <Menu>
          <MenuButton
            size="xs"
            bgColor={'white'}
            color={AppColors.primary}
            border={`1px solid ${AppColors.appBorder}`}
            as={Button}
            rightIcon={<ChevronDownIcon />}
            borderRadius="full"
          >
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<AddIcon />}
              onClick={() => {
                addToExcludeList(item);
              }}
            >
              Add to Exclude List
            </MenuItem>
            {!item.isCompetitor && (
              <MenuItem
                icon={<AddIcon />}
                onClick={() => {
                  addToCompetitorList(item);
                }}
              >
                Add to Competitor List
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      );
    }
  };

  const linkColumns: ColumnDefinitionType<EflItem>[] = [
    {
      key: 'href',
      header: 'Link',
      headerSortable: true,
      columnSearchable: true,
      render: (item) => <UrlCell url={item.href} />,
    },
    {
      key: 'type',
      header: 'Type',
    },
    {
      key: 'isCompetitor',
      header: 'Competitor',
      render: (item) => {
        return item.isCompetitor ? (
          <StatusIndicator status={AppColors.errorColor} pulse={true} />
        ) : (
          <AppText></AppText>
        );
      },
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  useEffect(() => {
    if (backlinkEfl) {
      setupList(backlinkEfl, organizationExludeLinks, organizationCompetitors);
    }
  }, [backlinkEfl, organizationCompetitors, organizationExludeLinks]);

  const setupList = (
    requiredList: BacklinkEFL,
    exludeLinks: OrganizationExludeLink[],
    competitors: OrganizationCompetitor[]
  ) => {
    const cardList: EflItem[] = [];

    const urlObject = new URL(parentUrl);
    const urlDomain = urlObject.hostname;

    if (requiredList) {
      const filteredLinks = requiredList.links.filter((x) => x.href && x.href.includes('http'));

      const myArrayFiltered = filteredLinks.filter((el) => {
        const excludeItem = exludeLinks.some(
          (organizationExludeLink) =>
            el.href.includes(organizationExludeLink.link) || el.href === organizationExludeLink.link
        );

        return !excludeItem;
      });

      const alertItem: EflItem[] = myArrayFiltered.map((backlinkEfl) => {
        const isCompetitor = competitors.some((organizationCompetitor) =>
          backlinkEfl.href.includes(organizationCompetitor.competitorDomain)
        );

        return {
          href: backlinkEfl.href,
          type: backlinkEfl.type,
          isCompetitor: isCompetitor,
          isHighlighted: backlinkEfl?.href?.includes(urlDomain),
        };
      });
      cardList.push(...alertItem);
    }

    const items: EflItem[] = [];
    cardList.forEach((item) => {
      const domainItem = item.href.split('//')[1].split('/')[0];
      items.push({
        ...item,
        domain: domainItem,
      });
    });
    const groups = items.reduce((acc: DomainGrouping[], item: EflItem): DomainGrouping[] => {
      const domain = item.domain || `${new URL(item.domain ?? '')}`;
      const groupCheck = acc.findIndex((group) => group.domain === domain);
      if (groupCheck >= 0) {
        acc[groupCheck].eflItems.push(item);
      } else {
        acc.push({ domain: domain, eflItems: [item] });
      }
      return acc;
    }, []);
    setDomainGroups(groups);
  };

  const handleExcludeLinkManagement = () => {
    dialog({
      title: 'Exclude Link Management',
      position: 3,
      size: '4xl',
      render: (onSubmit) => {
        return <ExludeListManagement />;
      },
    });
  };

  const handleCompetitorManagement = () => {
    dialog({
      title: 'Competitor Management',
      position: 3,
      size: '4xl',
      render: (onSubmit) => {
        return <CompetitorManagement />;
      },
    });
  };

  const findCompetitor = (group: DomainGrouping) => {
    const hasCompetitor = group.eflItems.find((item) => item.isCompetitor);
    if (hasCompetitor) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Flex flexDir={'column'} gap={'30px'}>
      <Flex width={'100%'} align={'center'} gap={'10px'}>
        <AppButton
          borderRadius={'full'}
          bgColor={AppColors.secondary}
          color={'white'}
          onClick={handleExcludeLinkManagement}
          flex={1}
        >
          Link Management
        </AppButton>
        <AppButton
          borderRadius={'full'}
          onClick={handleCompetitorManagement}
          flex={1}
          bgColor={AppColors.secondary}
          color={'white'}
        >
          Competitor Management
        </AppButton>
      </Flex>
      {isFetching && <AppLoader />}
      {!isFetching && (
        <Accordion
          allowToggle
          allowMultiple
          boxShadow={'sm'}
          bgColor={'transparent'}
          defaultIndex={[0]}
        >
          {domainGroups.map((group, idx) => (
            <AccordionItem
              key={`${group.domain}-${idx}`}
              borderTop={idx === 0 ? 'none' : '1px solid rgba(0,0,0,0.1)'}
            >
              <AccordionButton py={4}>
                <Flex width={'100%'} align={'center'} justify={'space-between'}>
                  <Flex align={'center'} width={'100%'} justify={'space-between'}>
                    <AppText fontSize={'lg'} fontWeight={'700'}>
                      {group.domain}
                    </AppText>
                    {findCompetitor(group) === true && (
                      <Box mr={'50px'}>
                        <StatusIndicator status={AppColors.errorColor} pulse={true} />
                      </Box>
                    )}
                  </Flex>
                  <AccordionIcon color={AppColors.secondary} />
                </Flex>
              </AccordionButton>
              <AccordionPanel bgColor={'white'}>
                <AppDataTable
                  data={group.eflItems || []}
                  noDataMessage={'No Efls'}
                  columns={linkColumns}
                  selectableRows={false}
                  showColumnVisibility={false}
                  searchBar={false}
                />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </Flex>
  );
};

export default EflPanel;
