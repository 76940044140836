import React from 'react';
import { Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';
import { AppButtonAs, AppButton } from '@backlinkit/shared';

export interface TagsActionsMenuProps {
  isDisabled: boolean;
  onDeleteTags?: () => void;
}

const TagsActionsMenu: React.FC<TagsActionsMenuProps> = ({ isDisabled, onDeleteTags }) => {
  const handleTagsDelete = () => {
    onDeleteTags && onDeleteTags();
  };

  return (
    <Menu>
      <MenuButton as={AppButtonAs} rightIcon={<ChevronDownIcon />} size="sm" borderRadius={'full'}>
        Actions
      </MenuButton>
      <MenuList>
        <Flex flexDirection={'column'} px={4} py={2} gap={2}>
          <AppButton isDisabled={isDisabled} onClick={handleTagsDelete} leftIcon={<DeleteIcon />}>
            Delete
          </AppButton>
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default TagsActionsMenu;
