import { AppColors } from '@backlinkit/shared';
import { ChartData } from 'chart.js';

export const GoogleIndexReportDefault: ChartData = {
  labels: ['Domain Index', 'Page Index', 'No Index'],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: [AppColors.secondary, AppColors.tertiary, AppColors.primary],
      borderColor: [AppColors.secondary, AppColors.tertiary, AppColors.primary],
      borderWidth: 1,
    },
  ],
};

export const LinkTypeReportDefault: ChartData = {
  labels: ['Link', 'Image', 'Redirect'],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: [AppColors.secondary, AppColors.tertiary, AppColors.primary],
      borderColor: [AppColors.secondary, AppColors.tertiary, AppColors.primary],
      borderWidth: 1,
    },
  ],
};

export const RelReportDefault: ChartData = {
  labels: ['Follow', 'No Follow', 'UGC', 'Sponsored'],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: [
        AppColors.secondary,
        AppColors.tertiary,
        AppColors.primary,
        AppColors.contentColor,
      ],
      borderColor: [
        AppColors.secondary,
        AppColors.tertiary,
        AppColors.primary,
        AppColors.contentColor,
      ],
      borderWidth: 1,
    },
  ],
};

export const BarChartReportDefault: ChartData = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: AppColors.secondary,
    },
  ],
};
