import React from 'react';
import { FlexProps, Flex } from '@chakra-ui/react';
import { AppText } from '../app-text/app-text';
import { AppButton } from '../app-button/app-button';
import { AppColors } from '../../theme';

export type AppConfirmProps = {
  message?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
} & FlexProps;

export const AppConfirm: React.FC<AppConfirmProps> = ({
  message = 'Are you sure?',
  confirmText = 'Ok',
  cancelText = 'Cancel',
  children,
  onConfirm,
  onCancel,
  ...props
}) => {
  return (
    <Flex direction={'column'} {...props}>
      <AppText>{message}</AppText>
      {children}
      <Flex
        mt={4}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        py={2}
        gap={2}
      >
        <AppButton
          variant={'solid'}
          color={'white'}
          bgColor={AppColors.secondary}
          flex={1}
          onClick={() => onConfirm && onConfirm()}
        >
          {confirmText}
        </AppButton>
        <AppButton flex={1} onClick={() => onCancel && onCancel()}>
          {cancelText}
        </AppButton>
      </Flex>
    </Flex>
  );
};
