import { Box } from '@chakra-ui/react';
import React from 'react';
import { AppColors } from '@backlinkit/shared';
import { useAppSelector } from '../../../../store/store';
import { selectBatches } from '../../../../store/slices/domain.slice';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../../../router/router';

type BacklinkBatchCellProps = {
  batchId?: string;
};

const BacklinkBatchCell: React.FC<BacklinkBatchCellProps> = ({ batchId }) => {
  const domainBatches = useAppSelector(selectBatches);
  const batch = domainBatches?.find((x) => x.id === batchId);
  const navigate = useNavigate();
  return (
    <>
      {batch && (
        <Box
          px={'16px'}
          py={'8px'}
          fontSize={'12px'}
          borderRadius={'4px'}
          textAlign={'center'}
          fontWeight={'semibold'}
          backgroundColor={AppColors.appBackground}
          color={'black'}
          _hover={{
            cursor: 'pointer',
            transform: 'translateY(-2px)',
            transition: '0.1s all',
            boxShadow: 'lg',
          }}
          onClick={() => navigate(`${RoutesList.Batches}/${batch.id}`)}
        >
          {batch.name}
        </Box>
      )}
    </>
  );
};

export default BacklinkBatchCell;
