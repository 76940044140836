import { Flex } from '@chakra-ui/react';
import { AppText } from '../app-text';
import { AppColors } from '../../theme';

export const Definitions: React.FC = () => {
  return (
    <Flex
      w={'100%'}
      borderRadius={'lg'}
      justify={'center'}
      py={2}
      bgColor={AppColors.appBackground}
      border={`1px solid ${AppColors.secondary}`}
      _hover={{
        transform: 'translateY(-2px)',
        transition: '0.2s',
        boxShadow: 'lg',
        cursor: 'pointer',
      }}
      onClick={() =>
        window.open(
          'https://backlinkitprod.blob.core.windows.net/policies/Definitions%20Key%20BacklinkIt%20FINAL.pdf',
          '_blank'
        )
      }
    >
      <AppText>Definitions</AppText>
    </Flex>
  );
};
