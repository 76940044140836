import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { NicheType } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const nicheTypeApi = createApi({
  reducerPath: 'nicheTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllNicheTypes: build.query<NicheType[], any>({
      query: () => ({
        url: '/nichetype',
        method: 'GET',
      }),
    }),
    fetchNicheTypeById: build.query<NicheType, string>({
      query: (id) => ({
        url: `/nichetype/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchAllNicheTypesQuery, useFetchNicheTypeByIdQuery } = nicheTypeApi;
