import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { getBacklinkScrapeDataDifferences } from '../../utils/apiHelpers';
import {
  PaginatedData,
  BackLinkListItem,
  BacklinkByOptionsRequest,
  BackLink,
  BackLinkCreate,
  BackLinkBulkCreateResponse,
  BacklinkBulkCreateRequest,
  BacklinkBulkDeleteRequest,
  BackLinkUpdateBulkRequest,
  BacklinkScrapeRequest,
  BacklinkGoogleRequest,
  BackLinkClearAlertsParams,
  BacklinkGoogleBulkRequest,
} from '@backlinkit/shared';

export const backlinksApi = createApi({
  reducerPath: 'backlinksApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    //NEW
    fetchBacklinksByOptions: build.query<PaginatedData<BackLinkListItem>, BacklinkByOptionsRequest>(
      {
        query: (data) => ({
          url: `/backlink/by/options`,
          method: 'POST',
          body: JSON.stringify(data),
        }),
        transformResponse: (response: PaginatedData<BackLinkListItem>) => {
          const transformedData: BackLinkListItem[] = response.data.map((item) => {
            const scraperDifference = getBacklinkScrapeDataDifferences(item);
            return {
              ...item,
              secondTierLinkCount: item.backlinks ? item.backlinks.length : 0,
              errorCount: item.errors ? item.errors.length : 0,
              scraperDifference: scraperDifference,
            } as BackLinkListItem;
          });
          return {
            data: transformedData,
            pagination: response.pagination,
          } as PaginatedData<BackLinkListItem>;
        },
      }
    ),
    fetchBacklinkCountByOrganization: build.query<number, string>({
      query: (id) => ({
        url: `/backlink/count/by/organization/${id}`,
        method: 'GET',
      }),
    }),
    fetchBacklinksByDomain: build.query<PaginatedData<BackLinkListItem>, string>({
      query: (id) => ({
        url: `/backlink/by/domain/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: BackLinkListItem[]) => {
        const transformedData: BackLinkListItem[] = response.map((item) => {
          const scraperDifference = getBacklinkScrapeDataDifferences(item);
          return {
            ...item,
            errorCount: item.errors ? item.errors.length : 0,
            scraperDifference: scraperDifference,
          } as BackLinkListItem;
        });
        return {
          data: transformedData,
        } as PaginatedData<BackLinkListItem>;
      },
    }),
    fetchBacklinksByIds: build.query<BackLinkListItem[], string[]>({
      query: (params) => ({
        url: `/backlink/by/id/list`,
        method: 'POST',
        body: { backlinkIds: params },
      }),
      transformResponse: (response: BackLinkListItem[]) => {
        const transformedData: BackLinkListItem[] = response.map((item) => {
          const scraperDifference = getBacklinkScrapeDataDifferences(item);

          return {
            ...item,
            errorCount: item.errors ? item.errors.length : 0,
            scraperDifference: scraperDifference,
          } as BackLinkListItem;
        });
        return transformedData;
      },
    }),
    fetchSecondTierBacklinksByLinkId: build.query<PaginatedData<BackLinkListItem>, string>({
      query: (id) => ({
        url: `/backlink/by/parent/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: BackLinkListItem[]) => {
        const transformedData: BackLinkListItem[] = response.map((item) => {
          const scraperDifference = getBacklinkScrapeDataDifferences(item);
          return {
            ...item,
            errorCount: item.errors ? item.errors.length : 0,
            scraperDifference: scraperDifference,
          } as BackLinkListItem;
        });
        return {
          data: transformedData,
        } as PaginatedData<BackLinkListItem>;
      },
    }),
    saveBacklink: build.mutation<BackLink, BackLinkCreate>({
      query: (payload) => {
        return {
          url: `/backlink/create`,
          method: 'POST',
          body: payload,
        };
      },
      transformResponse: (response: BackLink) => {
        return response;
      },
    }),
    editBacklink: build.mutation<BackLink, BackLink>({
      query: (payload) => {
        return {
          url: `/backlink/update/${payload.id}`,
          method: 'PUT',
          body: payload,
        };
      },
      transformResponse: (response: BackLink) => {
        return response;
      },
    }),
    deleteBacklink: build.mutation<BackLink, string>({
      query: (backlinkId) => ({
        url: `/backlink/delete/${backlinkId}`,
        method: 'DELETE',
      }),
    }),
    backlinkBulkCreate: build.mutation<BackLinkBulkCreateResponse, BacklinkBulkCreateRequest>({
      query: (data) => ({
        url: `/backlink/create/bulk`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: BackLinkBulkCreateResponse) => {
        return response;
      },
    }),
    backlinkLinkBulkDelete: build.mutation<any, BacklinkBulkDeleteRequest>({
      query: (data) => ({
        url: `/backlink/delete/bulk`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
    backlinkBulkRescrape: build.mutation<any, BackLinkUpdateBulkRequest>({
      query: (data) => ({
        url: `/backlink/rescrape/bulk`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }),
    backlinkRescrape: build.mutation<any, BacklinkScrapeRequest>({
      query: (data) => ({
        url: `/backlink/rescrape`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }),
    backlinkRescrapeGoogle: build.mutation<any, BacklinkGoogleBulkRequest>({
      query: (data) => ({
        url: `/backlink/rescrape/google`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }),
    editBacklinkAlerts: build.mutation<BackLink, BackLinkClearAlertsParams>({
      query: (payload) => {
        return {
          url: `/backlink/update/errors`,
          method: 'PUT',
          body: payload,
        };
      },
      transformResponse: (response: BackLink) => {
        return response;
      },
    }),
  }),
});

export const {
  useLazyFetchBacklinksByOptionsQuery,
  useFetchBacklinksByOptionsQuery,
  useLazyFetchBacklinksByDomainQuery,
  useFetchBacklinkCountByOrganizationQuery,
  useLazyFetchBacklinksByIdsQuery,
  useFetchBacklinksByIdsQuery,
  useLazyFetchSecondTierBacklinksByLinkIdQuery,
  useBacklinkBulkCreateMutation,
  useBacklinkLinkBulkDeleteMutation,
  useSaveBacklinkMutation,
  useEditBacklinkMutation,
  useDeleteBacklinkMutation,
  useEditBacklinkAlertsMutation,
  useBacklinkBulkRescrapeMutation,
  useBacklinkRescrapeMutation,
  useBacklinkRescrapeGoogleMutation,
} = backlinksApi;
