import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

export type PieBarChartProps = {
  chartData: any;
};
ChartJS.register(ArcElement, Tooltip, Legend);

const PieBarChart: React.FC<PieBarChartProps> = ({ chartData }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  return <Pie data={chartData} options={options} />;
};

export default PieBarChart;
