import * as React from 'react';
import { Currency, getAllISOByCurrencyOrSymbol, getAllInfoByISO } from 'iso-country-currency';
import { SelectOption } from '@backlinkit/shared';

export const useCurrencies = () => {
  const [currencyOptions, setCurrencyOptions] = React.useState<SelectOption[]>();

  React.useEffect(() => {
    const strings = ['USD', 'GBP', 'EUR', 'ZAR', 'CAD', 'AUD'];
    const isoList: string[] = [];
    const currencies: Currency[] = [];
    strings.forEach((item) => {
      const isos = getAllISOByCurrencyOrSymbol('currency', item);
      isoList.push(...isos);
    });
    isoList.forEach((iso) => {
      const currency = getAllInfoByISO(iso);
      currencies.push(currency);
    });
    const uniqueCurrencies = new Map<string, Currency>();
    currencies.map((x) => {
      uniqueCurrencies.set(x.currency, x);
    });
    const currencyArray = Array.from(uniqueCurrencies.values());
    const options = currencyArray.map((x) => {
      return {
        value: x.currency,
        label: `${x.symbol} - ${x.currency}`,
      };
    });
    setCurrencyOptions(options);
  }, []);
  return currencyOptions;
};
