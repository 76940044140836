import { ArrowRightIcon, SettingsIcon } from '@chakra-ui/icons';
import { RiDashboardLine } from 'react-icons/ri';
import {
  Flex,
  useToast,
  useColorModeValue,
  Tag,
  TagLabel,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DomainActionsMenu from '../app/app-menus/domain-actions-menu';
import DomainCreateForm, { DomainFormData } from '../forms/domain-create-form';
import {
  AlertListItem,
  AppAlertList,
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  AppPaginator,
  AppText,
  CellClickEvent,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  Domain,
  DomainListItem,
  PageChangeEvent,
  PaginatedData,
  Pagination,
  SearchParam,
  SortParam,
  TableSearchEventProps,
  searchingColumnsToSearchParam,
  sortingColumnsToSortingArray,
  useDialog,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import { RoutesList } from '../../router/router';
import { useDeleteBacklinkMutation } from '../../store/api/backlinksApi';
import {
  useDeleteDomainMutation,
  useEditDomainMutation,
  useLazyFetchDomainsByOptionsQuery,
  useSaveDomainMutation,
} from '../../store/api/domainApi';
import { selectUser } from '../../store/slices/authentication.slice';
import { setSelectedDomain } from '../../store/slices/domain.slice';
import { useAppSelector } from '../../store/store';
import { useLazyFetchBatchesByDomainQuery } from '../../store/api/backlinkBatchesApi';
import LinkDomainAgeCell from '../backlinks/components/cells/last-domain-age-cell';

const Domains: React.FC = () => {
  const title = 'Domains';
  const { setLoading } = useLoading();
  const user = useAppSelector(selectUser);

  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [currentPageSize, setCurrentPageSize] = useState<number | undefined>();
  const [currentSortList, setCurrentSortList] = useState<SortParam[]>([]);

  const [currentSearchParam, setCurrentSearchParam] = useState<SearchParam>();

  const [dashboardDomains, setDashboardDomains] = useState<PaginatedData<DomainListItem>>();

  const [getDomainsByOptions] = useLazyFetchDomainsByOptionsQuery();
  const [deleteDomainTrigger] = useDeleteDomainMutation();
  const [getBatchesByDomain] = useLazyFetchBatchesByDomainQuery();

  const [deleteBacklinkTrigger] = useDeleteBacklinkMutation();
  const [saveDomainTrigger, { isLoading: isDomainAddSaving }] = useSaveDomainMutation();

  const [editDomainTrigger, { isLoading: isDomainEditSaving }] = useEditDomainMutation();

  const [pagination, setPagination] = useState<Pagination>();

  const dispatch = useDispatch();
  const toast = useToast();
  const dialog = useDialog();
  const panel = usePanel();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPage && currentPageSize) {
      setupDomains(currentPage, currentPageSize, currentSortList, currentSearchParam);
    }
  }, [currentPage, currentPageSize]);

  useEffect(() => {
    if (dashboardDomains?.pagination) {
      console.log('dashboardDomains?.pagination ', dashboardDomains?.pagination);
      setPagination(dashboardDomains?.pagination);
    }
  }, [dashboardDomains]);

  const setupDomains = async (
    page: number | undefined,
    pageSize: number | undefined,
    sortList: SortParam[] | undefined,
    searchParam: SearchParam | undefined
  ) => {
    try {
      setLoading(true);
      setDashboardDomains(undefined);
      const domainList = await getDomainsByOptions({
        organizationId: user?.organizationId?.toString() ?? '',
        pageNumber: page,
        pageSize,
        searchParam,
        sortParams: sortList,
      }).unwrap();

      setDashboardDomains(domainList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBacklinkItClick = async (domain: DomainListItem) => {
    handleDomainSelection(domain, RoutesList.Batches);
  };

  const handleProfileClick = async (domain: DomainListItem) => {
    handleDomainSelection(domain, RoutesList.DomainProfile);
  };

  const handleDomainSelection = async (domain: DomainListItem, route: RoutesList) => {
    getBatchesByDomain(domain.id);
    await dispatch(setSelectedDomain(domain));
    navigate(route);
  };

  const handleEditDialog = (domain: DomainListItem) => {
    const domainForm = {
      id: domain.id,
      domainName: domain.name,
      url: domain.url,
      nicheTypeId: domain.nicheTypeId,
      domainUserRoleTypeId: domain.domainUserRoleTypeId,
    };
    console.log('domain', domain);
    panel({
      title: 'Edit Domain',
      render: (onSubmit) => {
        return (
          <DomainCreateForm
            form={domainForm}
            onSubmit={(formData) => {
              onSubmit();
              saveDomain(formData, domain);
            }}
          ></DomainCreateForm>
        );
      },
    });
  };

  const handleErrorListDialog = async (domain: DomainListItem) => {
    getBatchesByDomain(domain.id);
    await dispatch(setSelectedDomain(domain));
    navigate(RoutesList.Links, { state: { hasErrors: true } });

    // const errorList: AlertListItem[] = [];
    // domain.backlinks?.forEach((backLink) => {
    //   if (backLink.errors) {
    //     const errors: AlertListItem[] = backLink.errors.map((error) => {
    //       return {
    //         name: backLink.url,
    //         alertType: error.errorType,
    //         alertMessage: error.errorMessage,
    //       };
    //     });
    //     errorList.push(...errors);
    //   }
    // });
    // panel({
    //   title: `${domain.name} Errors`,
    //   size: 'xl',
    //   render: (onSubmit) => {
    //     if (domain.errorCount && domain.errorCount > 0) {
    //       return <AppAlertList items={errorList} showName={true} showType={true}></AppAlertList>;
    //     } else {
    //       return (
    //         <AppText fontSize={'lg'} fontWeight={'500'}>
    //           There are no errors!
    //         </AppText>
    //       );
    //     }
    //   },
    // });
  };

  const domainColumns: ColumnDefinitionType<DomainListItem>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: true,
    },
    {
      key: 'name',
      header: 'Name',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'url',
      header: 'Domain',
      cellDataClickable: true,
      headerSortable: true,
      columnSearchable: true,
      render: (item) => {
        return (
          <Flex direction={'row'}>
            <Image
              minW={'20px'}
              src={`https://www.google.com/s2/favicons?domain=${item?.url}`}
              alt=""
            />
            <AppText ml={2}>{item.url}</AppText>
          </Flex>
        );
      },
    },
    {
      key: 'domainCreatedDate',
      header: 'Domain Age',
      render: (link) => {
        if (link.domainCreatedDate) {
          return (
            <LinkDomainAgeCell originalDomainCreationDate={link.domainCreatedDate} url={link.url} />
          );
        } else {
          return <></>;
        }
      },
    },
    {
      key: 'backLinkCount',
      header: 'Backlinks',
      headerSortable: false,
      render: (item) => {
        return (
          <Tag
            onClick={() => {
              handleBacklinkItClick(item);
            }}
            borderRadius="full"
            bgColor={AppColors.secondary}
            textColor={'white'}
          >
            <TagLabel cursor={'pointer'}>{item?.backLinkCount}</TagLabel>
          </Tag>
        );
      },
    },
    {
      key: 'errorCount',
      headerSortable: false,
      header: 'Errors',
      render: (item) => {
        return (
          <Tag borderRadius="full" colorScheme={'red'}>
            <TagLabel cursor={'pointer'} onClick={() => handleErrorListDialog(item)}>
              {item?.errorCount}
            </TagLabel>
          </Tag>
        );
      },
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      cellDataClickable: false,
      render: (item) => {
        return (
          <Flex flexDirection={'row'}>
            <AppButton
              mr={4}
              bgColor={'white'}
              border={`1px solid ${AppColors.appBorder}`}
              variant={'solid'}
              size="xs"
              borderRadius="full"
              isLoading={isDomainEditSaving}
              onClick={() => {
                handleEditDialog(item);
              }}
              rightIcon={<SettingsIcon />}
            >
              Edit
            </AppButton>
            <AppButton
              mr={4}
              bgColor={'white'}
              border={`1px solid ${AppColors.appBorder}`}
              variant={'solid'}
              size="xs"
              borderRadius="full"
              onClick={() => {
                handleProfileClick(item);
              }}
              rightIcon={<RiDashboardLine />}
            >
              Domain Profile
            </AppButton>
            <AppButton
              bgColor={'white'}
              border={`1px solid ${AppColors.appBorder}`}
              variant={'solid'}
              size="xs"
              borderRadius="full"
              onClick={() => {
                handleBacklinkItClick(item);
              }}
              rightIcon={<ArrowRightIcon />}
            >
              Link Batches
            </AppButton>
          </Flex>
        );
      },
    },
  ];

  const [tableColumns, setTableColumns] =
    useState<ColumnDefinitionType<DomainListItem>[]>(domainColumns);

  const [selectedDomains, setSelectedDomains] = useState<DomainListItem[]>([]);

  const handleTableRowsSelectedChange = (selection: DomainListItem[]) => {
    setSelectedDomains(selection);
  };

  const saveDomain = async (domainFormData: DomainFormData, model?: Domain) => {
    try {
      if (!user) {
        toast({
          title: 'User account not found.',
          description: 'Sign out and back in.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
      }

      setLoading(true);

      if (model && model.id) {
        await editDomainTrigger({
          ...model,
          name: domainFormData.domainName,
          url: domainFormData.url,
          domainUserRoleTypeId: domainFormData.domainUserRoleTypeId,
          nicheTypeId: domainFormData.nicheTypeId,
        });

        setLoading(false);
        toast({
          title: 'Domain updated.',
          description: "We've updated your domain for you",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        await saveDomainTrigger({
          name: domainFormData.domainName,
          url: domainFormData.url,
          organizationId: user.organizationId.toString(),
          domainUserRoleTypeId: domainFormData.domainUserRoleTypeId,
          nicheTypeId: domainFormData.nicheTypeId,
        });

        setLoading(false);
        toast({
          title: 'Domain added.',
          description: "We've added your domain for you, come back soon to monitor the status",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }

      setupDomains(currentPage, currentPageSize, currentSortList, currentSearchParam);
    } catch (error) {
      toast({
        title: `Domain ${domainFormData.id ? 'update' : 'add'} error.`,
        description: `We've run into a problem ${
          domainFormData.id ? 'updating' : 'adding'
        } your domain, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleAddDialog = () => {
    panel({
      title: 'Create Domain',
      render: (onSubmit) => {
        return (
          <DomainCreateForm
            onSubmit={(formData) => {
              onSubmit();
              saveDomain(formData);
            }}
          ></DomainCreateForm>
        );
      },
    });
  };

  const handleTableCellClick = async (clickEvent: CellClickEvent<DomainListItem>) => {
    switch (clickEvent.column.key) {
      case 'backLinkCount':
      case 'url':
        window.open(clickEvent.row.url, 'blank');
        break;
      default:
        break;
    }
  };

  const handleDeleteDomainsClicked = () => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selectedDomains.length} selected Domains?`}
            onConfirm={async () => {
              onSubmit();

              await handleDeleteDomains(selectedDomains);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleSortByColumnClick = (columns: ColumnDefinitionType<DomainListItem>[]) => {
    const columnSortQueryString = sortingColumnsToSortingArray(columns);
    setCurrentSortList(columnSortQueryString);

    console.log(columnSortQueryString);
  };

  const handleDeleteDomains = async (domains: DomainListItem[]) => {
    try {
      const deleteDomainChildrenPromises = domains.flatMap(
        (domain) => domain.backlinks?.map((backlink) => deleteBacklinkTrigger(backlink.id).unwrap())
      );

      const deletePromises = domains.map((domain) => deleteDomainTrigger(domain.id).unwrap());

      await Promise.all(deleteDomainChildrenPromises);
      await Promise.all(deletePromises);
    } catch (e) {
      toast({
        title: 'Domain Delete error.',
        description:
          "We've run into a problem deleting the selected domains, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected Domains deleted.',
      description: "We've deleted the domains you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    setupDomains(currentPage, currentPageSize, currentSortList, currentSearchParam);
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    console.log(pageChangeEvent);
    setCurrentPage(pageChangeEvent.currentPage);
    setCurrentPageSize(pageChangeEvent.currentPageSize);
  };
  const handleSearchChange = (event: TableSearchEventProps<DomainListItem>) => {
    const searchObjects = searchingColumnsToSearchParam(event);
    setCurrentSearchParam(searchObjects);

    console.log(searchObjects);
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  return (
    <>
      <Flex
        flexDir={variant === 'desktop' ? 'row' : 'column'}
        px={4}
        justifyContent={'space-between'}
      >
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
          {title}
        </Text>
        <Flex alignItems={'center'}>
          <DomainActionsMenu
            isDisabled={selectedDomains.length === 0}
            onDeleteDomains={handleDeleteDomainsClicked}
          />
          <AppButton
            size={'sm'}
            borderRadius={'full'}
            isLoading={isDomainAddSaving}
            loadingText={'Adding Domain'}
            onClick={handleAddDialog}
          >
            Add Domain
          </AppButton>
        </Flex>
      </Flex>

      <Flex py={6} flex={1}>
        <Flex
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          padding={'4'}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={dashboardDomains?.data || []}
            noDataMessage={'No domains added, start by adding some!'}
            columns={tableColumns}
            selectableRows={true}
            onRowSelected={handleTableRowsSelectedChange}
            onTableCellClick={handleTableCellClick}
            onSearchInputChange={(event: TableSearchEventProps<DomainListItem>) => {
              handleSearchChange(event);
            }}
            onSortByColumnClick={(event: ColumnDefinitionType<DomainListItem>[]) =>
              handleSortByColumnClick(event)
            }
          />
          <AppPaginator
            pagination={pagination}
            onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
          ></AppPaginator>
        </Flex>
      </Flex>
    </>
  );
};

export default Domains;
