import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AppButton,
  AppColors,
  AppText,
  OrganizationPaymentDto,
  OrganizationPaymentStatusType,
} from '@backlinkit/shared';
import { useEditOrganizationPaymentMutation } from '../../store/api/organizationPaymentApi';
import { selectUser } from '../../store/slices/authentication.slice';
import { useAppSelector } from '../../store/store';
import BaseLayout from '../../components/layouts/base-layout';
import { WarningIcon } from '@chakra-ui/icons';
import { RoutesList } from '../../router/router';
import { useFetchProductByIdQuery } from '../../store/api/productApi';

const PaymentErrorPage: React.FC = () => {
  const { id } = useParams();
  const user = useAppSelector(selectUser);
  const { data: product } = useFetchProductByIdQuery(id ?? '');
  const [editOrganizationPayment] = useEditOrganizationPaymentMutation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (payment) {
  //     updatePayment();
  //   }
  // }, [payment]);

  // const updatePayment = async () => {
  //   if (user) {
  //     const paymentCopy: OrganizationPaymentDto = JSON.parse(JSON.stringify(payment));
  //     paymentCopy.paymentStatus = OrganizationPaymentStatusType.CANCELLED;
  //     await editOrganizationPayment(paymentCopy);
  //   }
  // };

  return (
    <>
      <Flex w={'full'} h={'full'} direction={'column'} justify={'center'} align={'center'}>
        <Flex
          boxShadow={'lg'}
          w={'md'}
          h={'lg'}
          bgColor={'white'}
          direction={'column'}
          align={'center'}
          justify={'space-around'}
          gap={5}
          borderRadius={'xl'}
          p={8}
        >
          <WarningIcon color={'red'} boxSize={'60px'} />
          <AppText
            color={AppColors.primary}
            fontWeight={'bold'}
            fontSize={'2xl'}
            mt={'10px'}
            mb={'4px'}
          >
            Oops... something went wrong
          </AppText>
          <AppText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </AppText>
          <AppButton
            borderRadius={'full'}
            border={`1px solid ${AppColors.appBorder}`}
            p={4}
            onClick={() => navigate(RoutesList.OrganizationSubscriptions)}
          >
            Go to Plans
          </AppButton>
        </Flex>
      </Flex>
    </>
  );
};
export default PaymentErrorPage;
