import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  UserInviteAuditBulkCreateRequest,
  UserInviteAuditBulkCreateResponse,
} from '@backlinkit/shared';

export const userInviteEmailApi = createApi({
  reducerPath: 'userInviteEmailApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    saveBulkUserInvite: build.mutation<
      UserInviteAuditBulkCreateResponse,
      UserInviteAuditBulkCreateRequest
    >({
      query: (payload) => ({
        url: `/userinviteaudit/create/bulk`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useSaveBulkUserInviteMutation } = userInviteEmailApi;
