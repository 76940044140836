import {
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { BacklinkTypes, AppColors, AppText, LinkStatus } from '@backlinkit/shared';
import { FaHome } from 'react-icons/fa';

type LandinPageCellProps = {
  url?: string;
  domainUrl?: string;
  landingPage?: string;
  linkStatus?: string;
  backlinkType?: BacklinkTypes;
};

const LandinPageCell: React.FC<LandinPageCellProps> = ({
  url,
  landingPage,
  linkStatus,
  domainUrl,
  backlinkType,
}) => {
  const toast = useToast();

  const getFullUrl = () => {
    if (backlinkType === BacklinkTypes.tierOne && domainUrl) {
      if (domainUrl.endsWith('/') && landingPage?.startsWith('/')) {
        return `${domainUrl.substring(0, domainUrl.length - 1)}${landingPage}`;
      } else {
        return `${domainUrl}${landingPage}`;
      }
    } else {
      return landingPage;
    }
  };

  const handleLangingPageClick = async () => {
    window.open(getFullUrl(), 'blank');
  };

  const handleCopyToClipboardClick = () => {
    navigator.clipboard
      .writeText(getFullUrl() ?? '')
      .then(() => {
        toast({
          title: 'Copied to clipboard',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((e) => {
        toast({
          title: 'Failed to copy to clipboard',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const getLandingPageText = () => {
    if (landingPage === '' || landingPage === '/' || !landingPage) {
      return <FaHome color={AppColors.contentColor} />;
    } else {
      return landingPage;
    }
  };

  return (
    <Flex direction={'row'} alignItems={'center'}>
      {url && (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Flex>
              <AppText
                ml={2}
                maxW={'200px'}
                isTruncated
                _hover={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                {linkStatus === LinkStatus.Ok ? getLandingPageText() : 'Not Found'}
              </AppText>
            </Flex>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody display={'flex'} flexDir={'row'} alignItems={'center'}>
              <IconButton
                bg={AppColors.secondary}
                color={'white'}
                aria-label="column visibility button"
                isRound={false}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                size="xs"
                mr={'15px'}
                onClick={(event) => {
                  event.stopPropagation();
                  handleCopyToClipboardClick();
                }}
              >
                <Icon as={HiOutlineClipboardCopy} size="1rem" color={'white'} />
              </IconButton>
              <Flex>
                <AppText
                  ml={2}
                  whiteSpace={'pre-wrap'}
                  wordBreak={'break-word'}
                  cursor={'pointer'}
                  onClick={() => handleLangingPageClick()}
                >
                  {linkStatus === LinkStatus.Ok ? getFullUrl() : 'Not Found'}
                </AppText>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
};

export default LandinPageCell;
