import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppButton, AppInput, OrganizationTagEditForm } from '@backlinkit/shared';

export type OrganizationTagFormData = {
  id?: string;
  name: string;
  description?: string;
};

export const OrganizationTagFormDataDefaultValues: OrganizationTagFormData = {
  name: '',
  description: '',
};

const OrganizationTagFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
});

type OrganizationTagCreateFormProps<T> = {
  form?: OrganizationTagEditForm;
} & BaseFormProps<T>;

const OrganizationTagCreateForm: React.FC<
  OrganizationTagCreateFormProps<OrganizationTagFormData>
> = ({ form, onSubmit }) => {
  const {
    control: OrganizationTagControl,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm<OrganizationTagFormData>({
    defaultValues: form || OrganizationTagFormDataDefaultValues,
    resolver: yupResolver(OrganizationTagFormDataSchema),
    mode: 'onChange',
  });
  // const [currentSelectValue, setCurrentSelectValue] = useState<TagType>(TagType.LINK);

  // const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedValue = +e.currentTarget.value;
  //   setCurrentSelectValue(selectedValue);
  //   console.log(currentSelectValue);
  // };

  return (
    <Flex direction={'column'}>
      <form>
        <AppInput<OrganizationTagFormData>
          control={OrganizationTagControl}
          name="name"
          label="Tag"
          placeHolder="Tag"
          error={errors.name}
        />
        <AppInput<OrganizationTagFormData>
          mt={6}
          control={OrganizationTagControl}
          error={errors.description}
          label="Description"
          placeHolder="Description"
          name="description"
        />
        <AppButton w={'full'} mt={8} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Save
        </AppButton>
      </form>
    </Flex>
  );
};

export default OrganizationTagCreateForm;
