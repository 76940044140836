import { Flex, useColorModeValue } from '@chakra-ui/react';
import DomainLayout from '../../components/layouts/domain-layout';
import {
  AppPaginator,
  BackLinkListItem,
  BacklinkFilter,
  BacklinkFormData,
  ColumnDefinitionType,
  DomainListItem,
  PageChangeEvent,
  SearchParam,
} from '@backlinkit/shared';
import useBacklinks from '../../hooks/useBacklinks';
import { useEffect } from 'react';
import BacklinksTable from '../../components/backlinks/backlinks-table';
import { useLocation } from 'react-router-dom';
import { selectDomain } from '../../store/slices/domain.slice';
import { useAppSelector } from '../../store/store';

const Links: React.FC = () => {
  const title = 'Links';
  const { state } = useLocation();
  const domain = useAppSelector(selectDomain);
  const {
    domainBacklinks,
    tempBacklinks,
    tempGoogleData,
    additionalBackLinkColumns,
    menuItems,
    addBacklink,
    editBacklink,
    handleSearchChange,
    handleSortByColumnClick,
    handleFiltersChange,
    handleBacklinksRescrape,
    handleBacklinkGoogleRescrapeProcess,
    handleBackButton,
    refreshBacklinks,
    pagination,
    handleOnPageChange,
    handleBacklinkExternalReport,
    handleDeleteBacklinkClicked,
    actionSetupBacklinks,
  } = useBacklinks({ filter: { hasErrors: state && state.hasErrors ? true : undefined } });

  // useEffect(() => {
  //   if (domain && state && state.hasErrors) {
  //     actionSetupBacklinks({
  //       hasErrors: state && state?.hasErrors,
  //     });
  //   }
  // }, [state]);

  const handleAddLinks = async (
    backlinkFormsData: BacklinkFormData[],
    currentDomain: DomainListItem,
    batchPrice?: number,
    batch?: string | undefined
  ) => {
    await addBacklink(backlinkFormsData, currentDomain, batchPrice, batch);
  };

  const handleEditLinks = async (
    backlinkForm: BacklinkFormData,
    currentDomain: DomainListItem,
    isNoteEdit?: boolean | undefined,
    isPriceEdit?: boolean | undefined
  ) => {
    await editBacklink(backlinkForm, currentDomain, isNoteEdit, isPriceEdit);
  };

  return (
    <DomainLayout>
      <Flex px={6} flex={1} direction="column" width={'100%'}>
        <Flex
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'lg'}
          padding={'4'}
          flexDir={'column'}
          flex={1}
        >
          {domain && (
            <BacklinksTable
              currentDomain={domain}
              title={title}
              parentUrl={domain?.url || ''}
              data={domainBacklinks?.data || []}
              tempBacklinkCount={tempBacklinks && tempBacklinks.length}
              tempGoogleDataCount={tempGoogleData && tempGoogleData.length}
              additionalBacklinkColumns={additionalBackLinkColumns}
              additionalMenuItems={menuItems}
              isBatchCreateDisabled={false}
              onAddClick={(
                backlinkFormsData: BacklinkFormData[],
                currentDomain: DomainListItem,
                batchPrice?: number | undefined,
                batch?: string | undefined
              ) => handleAddLinks(backlinkFormsData, currentDomain, batchPrice, batch)}
              onEditBacklink={(
                backlinkForm: BacklinkFormData,
                currentDomain: DomainListItem,
                isNoteEdit?: boolean | undefined,
                isPriceEdit?: boolean | undefined
              ) => handleEditLinks(backlinkForm, currentDomain, isNoteEdit, isPriceEdit)}
              onSearchChange={(searchParams: SearchParam) => handleSearchChange(searchParams)}
              onSortByColumnClick={(columns: ColumnDefinitionType<BackLinkListItem>[]) =>
                handleSortByColumnClick(columns)
              }
              onFiltersChange={(params: BacklinkFilter) => handleFiltersChange(params)}
              onSelectedBacklinksRescrape={(selection: BackLinkListItem[]) =>
                handleBacklinksRescrape(selection)
              }
              onSelectedBacklinksGoogleRescrape={(selection: BackLinkListItem[]) =>
                handleBacklinkGoogleRescrapeProcess(selection)
              }
              onSelectedBacklinksDelete={(selection: BackLinkListItem[]) =>
                handleDeleteBacklinkClicked(selection)
              }
              onSelectedBacklinksExternalReport={(selection: BackLinkListItem[]) =>
                handleBacklinkExternalReport(selection)
              }
              onBackButtonClick={() => handleBackButton()}
              refetch={() => refreshBacklinks()}
            >
              <AppPaginator
                pagination={pagination}
                onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
              ></AppPaginator>
            </BacklinksTable>
          )}
        </Flex>
      </Flex>
    </DomainLayout>
  );
};

export default Links;
