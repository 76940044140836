import { InputGroup } from "@chakra-ui/react";
import Papa from "papaparse";
import { ChangeEvent, ReactNode, useRef } from "react";

export type CsvFileUploadReaderProps<T extends {}> = {
  children?: ReactNode;
  requiredColumns?: string[];
  onFileParsed: (fileData: CsvFileUploadReaderResult) => void;
};

export type CsvFileUploadReaderResultRow = {
  rowLine: number;
  rowValue: string[];
};

export type CsvFileUploadReaderHeaderResultRow = {
  valid: boolean;
} & CsvFileUploadReaderResultRow;

export type CsvFileUploadReaderErrorRow = {
  rowLine: number;
  rowValues: string[];
  errorMessage: string;
};

export type CsvFileUploadReaderResult = {
  headerResult?: CsvFileUploadReaderHeaderResultRow;
  validResults: CsvFileUploadReaderResultRow[];
  invalidRows: CsvFileUploadReaderErrorRow[];
};

export const AppCsvFileUploadReader = <T extends {}>(
  props: CsvFileUploadReaderProps<T>
) => {
  const { children, onFileParsed } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => inputRef.current?.click();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    let lineNumberTracker = 0;
    const finalResult: CsvFileUploadReaderResult = {
      headerResult: undefined,
      validResults: [],
      invalidRows: [],
    };
    if (e.target.files) {
      Papa.parse<string[]>(e.target.files[0], {
        header: false,
        dynamicTyping: false,
        skipEmptyLines: true,
        delimiter: ",",

        step: (row, parser) => {
          lineNumberTracker++;
          console.log(row);
          if (lineNumberTracker === 1) {
            let isValidTemplateColumns = true;
            console.log(props?.requiredColumns);

            if (props?.requiredColumns) {
              const rowDataCheck = row.data.sort();
              const requiredDataCheck = props?.requiredColumns?.sort() ?? [];
              console.log(rowDataCheck);
              console.log(requiredDataCheck);
              isValidTemplateColumns =
                rowDataCheck.length === requiredDataCheck.length &&
                rowDataCheck
                  .slice()
                  .sort()
                  .every(function (value, index) {
                    return value === requiredDataCheck[index];
                  });
              console.log(isValidTemplateColumns);
            }

            // valid header
            if (row.data.length > 0 && isValidTemplateColumns) {
              finalResult.headerResult = {
                valid: true,
                rowLine: lineNumberTracker,
                rowValue: row.data,
              };
            } else {
              const invalidValue: CsvFileUploadReaderErrorRow = {
                rowLine: lineNumberTracker,
                rowValues: row.data,
                errorMessage: "Row does not match required template",
              };
              finalResult.invalidRows.push(invalidValue);
            }
          } else {
            const validValue: CsvFileUploadReaderResultRow = {
              rowLine: lineNumberTracker,
              rowValue: row.data,
            };
            finalResult.validResults.push(validValue);
          }
        },
        complete: (results) => {
          console.log(results);
          console.log(finalResult);
          onFileParsed && onFileParsed(finalResult);
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  };

  return (
    <InputGroup onClick={handleClick}>
      <input
        type={"file"}
        multiple={false}
        hidden
        accept={".csv"}
        ref={(e) => {
          console.log(e);
          inputRef.current = e;
        }}
        onChange={handleFileChange}
      />
      <>{children}</>
    </InputGroup>
  );
};
