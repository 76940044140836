import { KeyValuePair } from '@backlinkit/shared';

export const importBacklinkCsvRequiredColumns: KeyValuePair<number>[] = [
  { key: 'Url', value: 0 },
  { key: 'Price', value: 1 },
  { key: 'Price_Currency', value: 2 },
  { key: 'Expected_Anchor', value: 3 },
  { key: 'Expected_Landing_Page', value: 4 },
  { key: 'Follow(True/False)', value: 5 },
  { key: 'NoFollow(True/False)', value: 6 },
  { key: 'UGC(True/False)', value: 7 },
  { key: 'Sponsored(True/False)', value: 8 },
];

export enum ImportBacklinkCsvRequiredColumnsEnum {
  'Url' = 0,
  'Price' = 1,
  'Price_Currency' = 2,
  'Expected_Anchor' = 3,
  'Expected_Landing_Page' = 4,
  'Follow(True/False)' = 5,
  'NoFollow(True/False)' = 6,
  'UGC(True/False)' = 7,
  'Sponsored(True/False)' = 8,
}

export const importTagCsvRequiredColumns: KeyValuePair<number>[] = [
  { key: 'Name', value: 0 },
  { key: 'Description', value: 1 },
];

export enum ImportTagCsvRequiredColumnsEnum {
  'Name' = 0,
  'Description' = 1,
}
