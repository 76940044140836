import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { OrganizationBudgetTypeDto } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationBudgetTypeApi = createApi({
  reducerPath: 'organizationBudgetType',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationBudgetTypes: build.query<OrganizationBudgetTypeDto[], any>({
      query: () => ({
        url: '/organizationBudgetType',
        method: 'GET',
      }),
      transformResponse: (response: OrganizationBudgetTypeDto[]) => {
        return response;
      },
    }),
  }),
});

export const { useFetchAllOrganizationBudgetTypesQuery } = organizationBudgetTypeApi;
