import React, { useState, useEffect } from 'react';
import { Flex, Box, Image, Grid, GridItem, Spinner } from '@chakra-ui/react';
import {
  AppText,
  AppButton,
  ProductDto,
  AppColors,
  useDialog,
  getSumOfNumbers,
  ProductType,
  useLoading,
} from '@backlinkit/shared';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import { CheckIcon, InfoIcon } from '@chakra-ui/icons';
import creditImage from '../../assets/credit_payment.jpg';
import subImage from '../../assets/subscription_payment.jpg';
import { selectDomains } from '../../store/slices/domain.slice';
import { DomainStats } from '../../pages/dashboard/dashboard';
import ContactForm from './contact-form';
import { useFetchBacklinkCountByOrganizationQuery } from '../../store/api/backlinksApi';
import { useFetchProductsQuery } from '../../store/api/productApi';

const baseUrl = process.env.REACT_APP_BASE_URL;

type CheckoutFormProps = {
  onSubmit: (item: ProductDto) => void;
  onCancelPlan: (item: any) => void;
  onlyCreditProducts?: boolean;
};

const CheckOutForm: React.FC<CheckoutFormProps> = ({
  onlyCreditProducts = false,
  onSubmit,
  onCancelPlan,
}) => {
  const user = useSelector(selectUser);
  const { setLoading } = useLoading();
  const { data: products, refetch: refetchProducts, isLoading } = useFetchProductsQuery({});
  const { data: backLinkCount } = useFetchBacklinkCountByOrganizationQuery(user?.organizationId!);
  const creditPlans = products?.filter((x) => x.type === 1) as ProductDto[];
  const dialog = useDialog();
  const domains = useSelector(selectDomains);
  const [domainStats, setDomainStats] = useState<DomainStats>();
  const [recommendedSubPlan, setRecommendedSubPlan] = useState<ProductDto[]>();
  const [upgrade, setUpgrade] = useState<string>('none');

  const handleDetailsDialog = (product: ProductDto) => {
    dialog({
      title: `Product Details`,
      size: 'lg',
      render: (onSubmit) => {
        return (
          <Flex direction={'column'} justify={'center'} gap={3} align={'center'}>
            <Image src={product.type === 0 ? subImage : creditImage} w={'full'} h={'80'} />
            <Flex
              direction={'column'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
              gap={3}
              py={4}
              w={'full'}
            >
              <Box>
                <AppText fontSize={'xl'} fontWeight={'bold'} color={AppColors.secondary2}>
                  {product.name}
                </AppText>
                <AppText fontWeight={'500'}>${product.price}</AppText>
              </Box>
              <AppText fontSize={'md'} fontWeight={'500'}>
                Description
              </AppText>
              <AppText>{product.description}</AppText>
            </Flex>
            <Flex
              direction={'column'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
              gap={3}
              py={4}
              w={'full'}
            >
              <AppText color={AppColors.secondary2} fontWeight={'500'} fontSize={'md'}>
                Details
              </AppText>
              <Flex gap={3}>
                <CheckIcon color={'green'} />
                <AppText>{product.type === 0 ? 'Subscription' : 'Credits'}</AppText>
              </Flex>
              <Flex gap={3}>
                <CheckIcon color={'green'} />
                <AppText>{product.amount} links</AppText>
              </Flex>
            </Flex>
            <AppButton
              w={'full'}
              color={AppColors.iconColorOne}
              borderRadius={'full'}
              onClick={() => onSubmit()}
            >
              Close
            </AppButton>
          </Flex>
        );
      },
    });
  };

  useEffect(() => {
    if (domains) {
      const backLinkCountList = domains.map((x) => x.backLinkCount);
      const errorCountList = domains.map((x) => x.errorCount);
      const domainStatsData = {
        domainCount: domains.length,
        linkCount: getSumOfNumbers(backLinkCountList),
        errorCount: getSumOfNumbers(errorCountList),
      };

      setDomainStats(domainStatsData);
    }

    handleRecommendedSubPlans(user?.organization.product as ProductDto);
  }, [domains, products]);

  const handleRecommendedSubPlans = (userPlan: ProductDto) => {
    const subscriptions = products?.filter((x) => {
      return x.type === ProductType.SUBSCRIPTION;
    });
    const currentAvailablePlans = subscriptions?.filter((x) => {
      return x.name !== userPlan.name;
    });

    if (backLinkCount) {
      if (userPlan.amount / 2 >= backLinkCount) {
        const downgradePlans = currentAvailablePlans?.filter((x) => {
          return x.amount / 2 >= backLinkCount;
        });
        setRecommendedSubPlan(downgradePlans);
      }
      if (userPlan.amount / 2 <= backLinkCount) {
        const upgradePlans = currentAvailablePlans?.filter((x) => {
          return x.amount / 2 <= backLinkCount;
        });
        setRecommendedSubPlan(upgradePlans);
      }
    } else {
      setRecommendedSubPlan(currentAvailablePlans);
    }
    if (userPlan.name === 'Backlinker Plan' && userPlan.amount / 2 <= backLinkCount!) {
      setUpgrade('flex');
    }
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <Flex direction={'column'} p={4} justify={isLoading ? 'center' : 'start'} gap={4}>
      {/* ONLY DISPLAYING RECOMMENDED PRODUCTS INSTEAD OF ALL PRODUCTS */}

      {/* <Flex
        direction={'column'}
        gap={4}
        w={'full'}
        display={
          user?.organization.product?.amount! / domainStats?.linkCount! <= 2 ? 'flex' : 'none'
        }
      >
        <AppText fontSize={'2xl'} fontWeight={'bold'}>
          Recommended
        </AppText>
        <Flex gap={3} overflowX={'scroll'} h={'250px'} px={3}>
          {recommendedSubPlan?.map((x) => {
            return (
              <Flex
                direction={'column'}
                pos={'relative'}
                gap={3}
                p={4}
                borderRadius={'xl'}
                boxShadow={'lg'}
                minW={'300px'}
                h={'200px'}
                justify={'center'}
                align={'center'}
                key={x.id}
              >
                <Box
                  pos={'absolute'}
                  bgColor={AppColors.secondary2}
                  h={'60px'}
                  w={'full'}
                  top={0}
                  borderTopRadius={'xl'}
                />
                <Flex direction={'column'} align={'center'} justify={'center'} zIndex={5} gap={4}>
                  <AppText fontSize={'lg'} fontWeight={'600'} color={'white'}>
                    {x.name}
                  </AppText>
                  <AppText>
                    <CheckIcon color={'green'} /> {x.amount} links
                  </AppText>
                  <AppText>$ {x.price}</AppText>
                  <AppButton bgColor={AppColors.secondary2} color={'white'}>
                    Select
                  </AppButton>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex> */}
      {isLoading && (
        <Flex w={'100%'} height={'100%'} justify={'center'} align={'center'}>
          <Spinner color={AppColors.secondary} w={'40px'} h={'40px'} />
        </Flex>
      )}
      {!isLoading && (
        <>
          <Flex w={'full'} justify={'space-between'} align={'center'} gap={6}>
            <AppText fontSize={'xl'} fontWeight={'bold'}>
              Recommended Subscriptions
            </AppText>
            <AppText fontWeight={'500'} color={AppColors.secondary2}>
              Your total backlinks: {backLinkCount}
            </AppText>
          </Flex>
          <Flex direction={'column'} py={6} gap={2} display={onlyCreditProducts ? 'none' : 'flex'}>
            {recommendedSubPlan?.map((x) => {
              return (
                <Grid
                  gridTemplateColumns={'repeats(3, 1fr)'}
                  gridTemplateRows={'reapeat(1, 1fr)'}
                  key={x.id}
                  py={3}
                >
                  <GridItem
                    colSpan={1}
                    rowSpan={1}
                    rowStart={1}
                    w={'150px'}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <AppText fontSize={'lg'} fontWeight={'500'}>
                      {x.name}
                    </AppText>
                  </GridItem>
                  <GridItem
                    colSpan={1}
                    rowSpan={1}
                    rowStart={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <AppButton
                      rightIcon={<InfoIcon color={AppColors.secondary} />}
                      borderRadius={'full'}
                      size={'sm'}
                      onClick={() => handleDetailsDialog(x)}
                    >
                      Details
                    </AppButton>
                  </GridItem>
                  <GridItem
                    colSpan={1}
                    rowSpan={1}
                    rowStart={1}
                    display={'flex'}
                    justifyContent={'end'}
                  >
                    <Flex justify={'space-between'} align={'center'} w={'200px'}>
                      <AppText fontWeight={'500'}>${x.price}</AppText>
                      <AppButton
                        size={'sm'}
                        color={AppColors.iconColorOne}
                        borderRadius={'full'}
                        onClick={() => onSubmit(x)}
                      >
                        Select
                      </AppButton>
                    </Flex>
                  </GridItem>
                </Grid>
              );
            })}
            <Flex w={'full'} pt={12} direction={'column'} gap={3} display={upgrade}>
              <ContactForm onSubmit={() => {}} />
            </Flex>
          </Flex>
          <Box py={6}>
            <AppText fontSize={'xl'} fontWeight={'bold'}>
              Credits
            </AppText>
            <Flex direction={'column'} py={6} gap={2}>
              {creditPlans?.map((x) => {
                return (
                  <Grid
                    gridTemplateColumns={'repeats(3, 1fr)'}
                    gridTemplateRows={'reapeat(1, 1fr)'}
                    key={x.id}
                    py={4}
                  >
                    <GridItem
                      colSpan={1}
                      rowSpan={1}
                      rowStart={1}
                      w={'150px'}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <AppText fontSize={'lg'} fontWeight={'500'}>
                        {x.name}
                      </AppText>
                    </GridItem>
                    <GridItem
                      colSpan={1}
                      rowSpan={1}
                      rowStart={1}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <AppButton
                        rightIcon={<InfoIcon color={AppColors.secondary} />}
                        borderRadius={'full'}
                        size={'sm'}
                        onClick={() => handleDetailsDialog(x)}
                      >
                        Details
                      </AppButton>
                    </GridItem>
                    <GridItem
                      colSpan={1}
                      rowSpan={1}
                      rowStart={1}
                      display={'flex'}
                      justifyContent={'end'}
                    >
                      <Flex justify={'space-between'} align={'center'} w={'200px'}>
                        <AppText fontWeight={'500'}>${x.price}</AppText>
                        <AppButton
                          size={'sm'}
                          color={AppColors.iconColorOne}
                          borderRadius={'full'}
                          onClick={() => onSubmit(x)}
                        >
                          Select
                        </AppButton>
                      </Flex>
                    </GridItem>
                  </Grid>
                );
              })}
            </Flex>
            {/* <Flex w={'full'} justify={'end'}>
          <AppButton borderRadius={'full'} color={'red'} onClick={() => onCancelPlan(user!)}>
            Cancel Plan
          </AppButton>
        </Flex> */}
          </Box>
        </>
      )}
    </Flex>
  );
};
export default CheckOutForm;
