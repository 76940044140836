import React, { useEffect, useState } from 'react';
import { Checkbox, Flex, SimpleGrid } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppInput, AppButton } from '@backlinkit/shared';

export type BacklinkManualFormData = {
  landingHref?: string;
  anchorText?: string;
  rel?: string;
};

export const backLinkManualFormDataDefaultValues: BacklinkManualFormData = {
  landingHref: '',
  anchorText: '',
  rel: '',
};

const backLinkManualFormDataSchema = yup.object({
  landingHref: yup.string().required('Field is required'),
  anchorText: yup.string().required('Field is required'),
  rel: yup.string(),
});

type BacklinkManualFormType<T> = {
  form?: BacklinkManualFormData;
} & BaseFormProps<T>;

const BackLinkManualForm: React.FC<BacklinkManualFormType<BacklinkManualFormData>> = ({
  form,
  onSubmit,
}) => {
  const [relSplit, setRelSplit] = useState<string[]>([]);
  const [relFollow, setRelFollow] = useState<boolean>(false);

  const {
    setValue,
    control: backLinkManualControl,
    formState: { isValid, errors },
    getValues,
  } = useForm<BacklinkManualFormData>({
    defaultValues: form || backLinkManualFormDataDefaultValues,
    resolver: yupResolver(backLinkManualFormDataSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (form) {
      const relSplit = form?.rel?.split(',') || [];
      setRelSplit(relSplit);
      if (relSplit.includes('follow')) {
        setRelFollow(true);
      }
    }
  }, [form]);

  const onRelSelectChange = (checked: boolean, rel: string) => {
    if (rel === 'follow' && checked) {
      setRelFollow(true);
      setRelSplit([rel]);
      setValue('rel', rel);
    } else {
      setRelFollow(false);
      if (checked) {
        setRelSplit([...relSplit, rel]);
        setValue('rel', [...relSplit, rel].join(','));
      } else {
        const relCopy = [...relSplit];
        const relIndex = relCopy.findIndex((x) => x === rel);
        if (relIndex !== -1) {
          relCopy.splice(relIndex, 1);
        }
        setRelSplit(relCopy);
        setValue('rel', relCopy.join(','));
      }
    }
  };

  const handleFormSubmit = (formData?: BacklinkManualFormData) => {
    if (formData) {
      onSubmit && onSubmit(formData);
    }
  };

  const renderRelComponent = () => {
    return (
      <Flex mt={6} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Checkbox
          isChecked={relSplit.includes('follow')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onRelSelectChange(event.currentTarget.checked, 'follow');
          }}
        >
          Follow
        </Checkbox>
        <Checkbox
          isDisabled={relFollow}
          isChecked={relSplit.includes('nofollow')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onRelSelectChange(event.currentTarget.checked, 'nofollow');
          }}
        >
          No Follow
        </Checkbox>
        <Checkbox
          isDisabled={relFollow}
          isChecked={relSplit.includes('ugc')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onRelSelectChange(event.currentTarget.checked, 'ugc');
          }}
        >
          UGC
        </Checkbox>
        <Checkbox
          isDisabled={relFollow}
          isChecked={relSplit.includes('sponsored')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onRelSelectChange(event.currentTarget.checked, 'sponsored');
          }}
        >
          Sponsored
        </Checkbox>
      </Flex>
    );
  };

  return (
    <Flex flexDirection={'column'} flex={1}>
      <form>
        <SimpleGrid columns={2} spacing={5}>
          <AppInput<BacklinkManualFormData>
            mt={6}
            control={backLinkManualControl}
            error={errors.anchorText}
            name="anchorText"
            label="Anchor"
            placeHolder={'Anchor'}
            flex={1}
          />
          <AppInput<BacklinkManualFormData>
            mt={6}
            control={backLinkManualControl}
            error={errors.landingHref}
            name="landingHref"
            label="Landing Page"
            placeHolder={'Landing Page'}
            flex={1}
          />
        </SimpleGrid>
        {renderRelComponent()}

        <AppButton
          mt={8}
          w={'full'}
          isDisabled={!isValid}
          onClick={() => {
            handleFormSubmit(getValues());
          }}
        >
          {'Set Manual'}
        </AppButton>
      </form>
    </Flex>
  );
};

export default BackLinkManualForm;
