import { Flex } from '@chakra-ui/react';
import { Definitions } from './definitions';
import { AppColors } from '../../theme';
import { AppText } from '../app-text';

export const Disclaimer: React.FC = () => {
  return (
    <Flex w={'full'} flexDir={'column'} gap={4}>
      <Definitions />
      <Flex
        w={'100%'}
        borderRadius={'lg'}
        justify={'center'}
        py={2}
        bgColor={AppColors.appBackground}
        border={`1px solid ${AppColors.secondary}`}
        _hover={{
          transform: 'translateY(-2px)',
          transition: '0.2s',
          boxShadow: 'lg',
          cursor: 'pointer',
        }}
        onClick={() =>
          window.open(
            'https://backlinkitprod.blob.core.windows.net/policies/Disclaimer%20BacklinkIt%20FINAL.pdf',
            '_blank'
          )
        }
      >
        <AppText>Disclaimer</AppText>
      </Flex>
    </Flex>
  );
};
