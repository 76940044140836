import React from 'react';
import { BacklinkScraperValueDifference, usePanel } from '@backlinkit/shared';
import { AppText } from '@backlinkit/shared';
import {
  Flex,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import DOMPurify from 'dompurify';

type DifferenceCountCellProps = {
  backlinkUrl: string;
  scraperDifference?: BacklinkScraperValueDifference;
  onErrorsCleared?: () => void;
  onClick?: () => void;
  icon?: IconType;
  color?: string;
};

export const DifferenceCountCell: React.FC<DifferenceCountCellProps> = ({
  backlinkUrl,
  scraperDifference,
  onClick,
  icon,
  color,
}) => {
  const panel = usePanel();

  const handleDifferencesDialog = () => {
    panel({
      title: `${backlinkUrl} Differences`,
      size: 'xl',
      render: (onSubmit) => {
        return (
          <Flex>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Value</Th>
                    <Th>Differences</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Rel Status
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.relStatus?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.relStatus?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>Previous: {scraperDifference?.relStatus?.previousValue}</AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Rel Value
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.relValue?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.relValue?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>Previous: {scraperDifference?.relValue?.previousValue}</AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Anchor Context Info
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={
                              scraperDifference?.anchorContextInfo?.isEqual ? '400' : 'bold'
                            }
                          >
                            Current:
                            <AppText
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  scraperDifference?.anchorContextInfo?.currentValue ?? ''
                                ),
                              }}
                            ></AppText>
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous:{' '}
                            <AppText
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  scraperDifference?.anchorContextInfo?.previousValue ?? ''
                                ),
                              }}
                            ></AppText>
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Robots Index Status
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={
                              scraperDifference?.robotsIndexStatus?.isEqual ? '400' : 'bold'
                            }
                          >
                            Current: {scraperDifference?.robotsIndexStatus?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous: {scraperDifference?.robotsIndexStatus?.previousValue}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Anchor
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText fontWeight={scraperDifference?.anchor?.isEqual ? '400' : 'bold'}>
                            Current: {scraperDifference?.anchor?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>Previous: {scraperDifference?.anchor?.previousValue}</AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Landing
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.landingPage?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.landingPage?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous: {scraperDifference?.landingPage?.previousValue}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Google Page Index
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.hasPageIndex?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.hasPageIndex?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous: {scraperDifference?.hasPageIndex?.previousValue}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Google Domain Index
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.hasDomainIndex?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.hasDomainIndex?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous: {scraperDifference?.hasDomainIndex?.previousValue}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Google Cache Date
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={
                              scraperDifference?.cacheCreatedDate?.isEqual ? '400' : 'bold'
                            }
                          >
                            Current: {scraperDifference?.cacheCreatedDate?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous: {scraperDifference?.cacheCreatedDate?.previousValue}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  {/* <Tr alignItems={'center'}>
                    <Td>
                      <AppButton size={'sm'} onClick={onClick}>
                        Save Changes
                      </AppButton>
                    </Td>
                    <Td>
                      <Icon w={'22px'} height={'22px'} as={icon} color={color} />
                    </Td>
                  </Tr> */}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        );
      },
    });
  };

  return (
    <Flex>
      {scraperDifference && (
        <Tooltip label="New Link Differences">
          <Tag ml={1} borderRadius="full" colorScheme={'blue'} cursor={'pointer'}>
            <TagLabel onClick={() => handleDifferencesDialog()}>
              {scraperDifference.differenceCount}
            </TagLabel>
          </Tag>
        </Tooltip>
      )}
    </Flex>
  );
};

export default DifferenceCountCell;
