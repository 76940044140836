import { Flex, Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { useLazyVerifyExternalReportQuery } from '../../store/api/unAuthBacklinkReportApi';
import { useNavigate } from 'react-router-dom';
import OTPForm, { OTPFormData } from '../../components/forms/external-report-otp-form';
import { AppColors, useLoading } from '@backlinkit/shared';

type ExternalReportOtpVerificationPageProps = {
  id: string;
};

const ExternalReportOtpVerificationPage: React.FC<ExternalReportOtpVerificationPageProps> = ({
  id,
}) => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [verifyOtp] = useLazyVerifyExternalReportQuery();

  const handleClick = async (data: OTPFormData) => {
    try {
      setLoading(true);
      const verified = await verifyOtp({ id, otp: data.otp ?? 0 }).unwrap();
      if (verified) {
        navigate(`/external-report/${id}`);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Flex
      h={'100vh'}
      align={'center'}
      justify={'center'}
      m={'33px'}
      border={`1px solid ${AppColors.appBorder}`}
      borderRadius={'15px'}
    >
      <Stack spacing={8} mx={'auto'}>
        <Box>
          <OTPForm
            onSubmit={(data) => {
              handleClick(data);
            }}
          />
        </Box>
      </Stack>
    </Flex>
  );
};
export default ExternalReportOtpVerificationPage;
