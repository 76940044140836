import { Tabs, TabList, TabPanels, Tab, TabPanel, TabsProps, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { AppText } from '../app-text';

export type AppTabsProps = {
  titles: string[];
  tabs: ReactNode[];
  panelHeight?: string;
} & BoxProps;

const AppTabs: React.FC<AppTabsProps> = ({ titles, tabs, panelHeight = '50vh' }) => {
  return (
    <Tabs isFitted>
      <TabList borderBottom={'none'}>
        {titles.map((title, index) => (
          <Tab key={`${title}-${index}`}>
            <AppText fontWeight={'600'}>{title}</AppText>
          </Tab>
        ))}
      </TabList>
      <TabPanels h={panelHeight} overflow={'auto'}>
        {tabs.map((tab, index) => (
          <TabPanel key={index}>{tab}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default AppTabs;
