import {
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { protocolRegex } from '../../../../constants/regex';
import { AppText } from '@backlinkit/shared';

type UrlCellProps = {
  url?: string;
  isTruncated?: boolean;
};

const CheckerUrlCell: React.FC<UrlCellProps> = ({ url, isTruncated = true, ...props }) => {
  return (
    <Flex direction={'row'} alignItems={'center'}>
      {url && (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Flex>
              <Image
                minW={'20px'}
                objectFit={'contain'}
                src={`https://www.google.com/s2/favicons?domain=${url}`}
                alt=""
              />

              <AppText ml={2} maxW={isTruncated ? '200px' : '100%'} isTruncated={isTruncated}>
                {url ? url.replace(protocolRegex, '') : ''}
              </AppText>
            </Flex>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <AppText>{url ? url.replace(protocolRegex, '') : ''}</AppText>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
};

export default CheckerUrlCell;
