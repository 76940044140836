import { Tag, TagLabel, Tooltip, Icon, Flex } from '@chakra-ui/react';
import React from 'react';
import { FaTruckLoading, FaUserCircle } from 'react-icons/fa';
import {
  LinkStatus,
  RobotsIndexStatus,
  AppColors,
  AppText,
  useDialog,
  AlertListItem,
  AppAlertList,
  BackLinkListItem,
  ScrapeError,
} from '@backlinkit/shared';
import { useEditBacklinkAlertsMutation } from '../../../../store/api/backlinksApi';
import { format } from 'date-fns';
import { ErrorCountCell } from './error-count-cell';

type LinkStatusCellProps = {
  isManual?: boolean;
  linkStatus?: string;
  robotsIndexStatus?: RobotsIndexStatus;
  onErrorsCleared?: () => void;
};

const LinkStatusCell: React.FC<LinkStatusCellProps> = ({
  isManual,
  linkStatus,
  robotsIndexStatus,
  onErrorsCleared,
}) => {
  const dialog = useDialog();
  const [editAlertTrigger] = useEditBacklinkAlertsMutation();
  const renderLinkStatus = () => {
    if (!linkStatus) {
      return (
        <Tooltip label="Busy processing">
          <span>
            <Icon ml="3" color={'grey'} as={FaTruckLoading} />
          </span>
        </Tooltip>
      );
    }
    switch (linkStatus) {
      case LinkStatus.Ok:
      case LinkStatus.LinkNotFound:
      case LinkStatus.AnchorTextMismatch:
        return (
          <Tooltip label="(200) returned the requested data">
            <Tag borderRadius="full" colorScheme={'green'}>
              <TagLabel>200</TagLabel>
            </Tag>
          </Tooltip>
        );
      // case LinkStatus.LinkNotFound:
      //   return (
      //     <Tooltip label="No Href found">
      //       <Tag borderRadius="full" colorScheme={'red'}>
      //         <TagLabel>NHF</TagLabel>
      //       </Tag>
      //     </Tooltip>
      //   );
      // case LinkStatus.AnchorTextMismatch:
      //   return (
      //     <Tooltip label="Anchor text mismatch">
      //       <Tag borderRadius="full" colorScheme={'red'}>
      //         <TagLabel>ATM</TagLabel>
      //       </Tag>
      //     </Tooltip>
      //   );
      case LinkStatus.InternalError:
        return (
          <Tooltip label="(500) Internal Server error">
            <Tag borderRadius="full" colorScheme={'red'}>
              <TagLabel>500</TagLabel>
            </Tag>
          </Tooltip>
        );
      case LinkStatus.NotFound:
        return (
          <Tooltip label="(404) Page not found">
            <Tag borderRadius="full" colorScheme={'red'}>
              <TagLabel>404</TagLabel>
            </Tag>
          </Tooltip>
        );
      case LinkStatus.Unauthorized:
        return (
          <Tooltip label="(403) Forbidden">
            <Tag borderRadius="full" colorScheme={'red'}>
              <TagLabel>403</TagLabel>
            </Tag>
          </Tooltip>
        );
      case LinkStatus.Gone:
        return (
          <Tooltip label="(410) Removed permanently">
            <Tag borderRadius="full" colorScheme={'red'}>
              <TagLabel>410</TagLabel>
            </Tag>
          </Tooltip>
        );
      default:
        break;
    }
  };
  const clearEntityAlerts = async (id: string, errors: ScrapeError[], items: AlertListItem[]) => {
    const newErrors: ScrapeError[] = [];

    items.forEach((x) => {
      const currentError = errors.find(
        (error) =>
          error.errorType === x.alertType &&
          error.errorMessage === x.alertMessage &&
          error.key === x.alertKey
      );

      if (currentError) {
        newErrors.push(currentError);
      }
    });

    await editAlertTrigger({
      id: id,
      errors: newErrors,
    }).unwrap();

    onErrorsCleared && onErrorsCleared();
  };

  const handleErrorListDialog = (item: BackLinkListItem) => {
    const errorList: AlertListItem[] = [];

    if (item.errors) {
      const errors: AlertListItem[] = item.errors.map((error) => {
        return {
          name: item.url,
          alertType: error.errorType,
          alertMessage: error.errorMessage,
          alertNoteLabel: 'Created',
          alertNote: error.dateCreated ? format(new Date(error.dateCreated), 'dd MMM yyyy') : '',
          entityId: item.id,
          alertKey: error.key,
        };
      });
      errorList.push(...errors);
    }

    dialog({
      title: `${item.url} Alerts`,
      size: 'xl',
      render: (onSubmit) => {
        return (
          <AppAlertList
            onSaveClearedAlerts={(alerts) => {
              clearEntityAlerts(item.id, item.errors ? item.errors : [], alerts);
              onSubmit();
            }}
            items={errorList}
            showName={false}
            showType={true}
            itemsClearable={true}
          ></AppAlertList>
        );
      },
    });
  };

  return (
    <Flex flexDirection={'row'} alignItems={'center'}>
      {renderLinkStatus()}
      {robotsIndexStatus === RobotsIndexStatus.NoIndex && (
        <Tooltip label="NoIndex (set by robots meta tag)">
          <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
            <TagLabel>{'NI'}</TagLabel>
          </Tag>
        </Tooltip>
      )}
      {isManual && (
        <Tooltip
          label={
            <Flex flexDirection={'column'}>
              <AppText>Manual Mode</AppText>
              <AppText mt={2} fontSize={10} lineHeight={'16px'}>
                This will automatically be reprocessed from the 1st of each month
              </AppText>
            </Flex>
          }
        >
          <Flex ml={2} justifyContent={'center'}>
            <Icon as={FaUserCircle} boxSize={'1em'} color={AppColors.secondary} />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

export default LinkStatusCell;
