import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  Image,
  Link,
  BoxProps,
} from '@chakra-ui/react';
import { AppColors } from '../../theme';
import { FaArrowRight } from 'react-icons/fa6';
import { ReactNode } from 'react';
import { signupBetaUrl } from '../../utils';
import { usePanel } from '../../providers';
import { CookiePolicy, PrivacyPolicy } from '../policy-panels';

type CustomLink = {
  icon?: any;
  headerContent?: string;
  label: string;
  href: string;
};

export interface SharedFooterProps extends BoxProps {
  rightPipe?: string;
  leftPipe?: string;
  socialLinks: CustomLink[];
  signUpLink?: string;
  children?: ReactNode | ReactNode[];
}

export const SharedFooter: React.FC<SharedFooterProps> = ({
  signUpLink,
  rightPipe,
  leftPipe,
  socialLinks = [],
  children,
  ...props
}) => {
  const panel = usePanel();

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  const titleSize = useBreakpointValue({
    base: '35px',
    sm: '4xl',
    md: '5xl',
    lg: '7xl',
  });
  const pipeSize = useBreakpointValue({
    base: '250px',
    xs: '200px',
    md: '300px',
    xl: '400px',
  });
  const pipeWidth = useBreakpointValue({
    base: '250px',
    md: '400px',
    lg: '350px',
    xl: '500px',
  });
  const imgPos = useBreakpointValue({
    base: '275px',
    xs: '250px',
    sm: '180px',
    md: '220px',
    lg: '275px',
    xl: '175px',
  });

  const handlePrivacyPolicy = () => {
    panel({
      title: 'Privacy Policy',
      size: 'lg',
      render: (onSubmit) => {
        return <PrivacyPolicy />;
      },
    });
  };

  const handleCookiePolicy = () => {
    panel({
      title: 'Cookie Policy',
      size: 'lg',
      render: (onSubmit) => {
        return <CookiePolicy />;
      },
    });
  };

  return (
    <Flex
      bgColor={'black'}
      borderTopRadius={'15px'}
      borderBottomRadius={{ md: '0', lg: '15px' }}
      flexDir={'column'}
      pos={'relative'}
      gap={'45px'}
      pb={4}
      {...props}
    >
      <Image
        pos={'absolute'}
        h={pipeSize}
        w={pipeWidth}
        top={imgPos}
        right={'0'}
        zIndex={variant === 'desktop' ? '1' : '0'}
        src={rightPipe}
      />
      <Flex
        flexDir={'column'}
        mx={variant === 'desktop' ? '60px' : '0px'}
        gap={'30px'}
        justifySelf={'center'}
        p={variant === 'desktop' ? 4 : 6}
        height={{ xs: '80%', lg: '60%' }}
        zIndex={2}
      >
        <Flex
          maxW={'550px'}
          color={'white'}
          fontSize={titleSize}
          fontWeight={'700'}
          align={{ xs: 'start', lg: 'center' }}
          gap={'10px'}
          flexWrap={'wrap'}
          mt={variant === 'desktop' ? '50px' : 0}
          lineHeight={'shorter'}
        >
          <Text>Big</Text>
          <Text bgGradient={'linear(to-r, #29D5FB 5%, #047DFC 95%)'} bgClip={'text'}>
            passion
          </Text>
          <Text>for smart SEO.</Text>
        </Flex>
        <Flex color={'white'}>
          <Text width={variant === 'desktop' ? '40%' : '100%'}>
            <b>Optimise Effortlessly:</b> track, manage and monitor your valuable backlinks with
            ease using our intuitive platform
          </Text>
        </Flex>
        <Box>
          <Link href={signupBetaUrl}>
            <Button
              bgColor={'rgba(255, 255, 255, 0.2)'}
              py={3}
              height={'auto'}
              borderRadius={'15px'}
              _hover={{
                transform: 'translateY(-2px)',
                bgColor: 'rgba(255, 255, 255, 0.2)',
              }}
            >
              <Flex align={'center'} gap={'10px'}>
                <Text color={'white'} fontSize={'sm'}>
                  Claim your credits
                </Text>
                <Flex
                  boxSize={'30px'}
                  bgColor={AppColors.secondary}
                  borderRadius={'15px'}
                  justify={'center'}
                  align={'center'}
                >
                  <Icon as={FaArrowRight} color={'white'} />
                </Flex>
              </Flex>
            </Button>
          </Link>
        </Box>
      </Flex>
      <Box
        bgColor={'black'}
        minH={variant === 'desktop' ? '200px' : '100%'}
        zIndex={2}
        p={4}
        pos={'relative'}
      >
        <Image
          pos={'absolute'}
          bottom={'0'}
          maxH={'250px'}
          objectFit={'contain'}
          src={leftPipe}
          zIndex={1}
        />
        <Flex
          w={'auto'}
          justify={'start'}
          bgColor={'black'}
          height={'fit-content'}
          ml={variant === 'desktop' ? '45px' : 0}
          align={'end'}
        >
          <Flex
            zIndex={5}
            flexDir={'column'}
            ml={variant === 'desktop' ? '25px' : 0}
            align={variant === 'desktop' ? 'center' : 'start'}
            mt={variant === 'desktop' ? '30px' : 0}
            gap={'30px'}
          >
            <Flex
              color={'white'}
              align={'start'}
              justify={variant === 'desktop' ? 'space-evenly' : 'start'}
              flexWrap={'wrap'}
              gap={'10px'}
              marginLeft={'0'}
            >
              {socialLinks.map((link, idx) => (
                <Flex key={`${link?.href}-${idx}`}>
                  <Link
                    display={'flex'}
                    alignItems={'center'}
                    gap={'10px'}
                    href={link.href}
                    textDecor={'none'}
                    color={'white'}
                  >
                    <Icon as={link.icon} color={AppColors.secondary} boxSize={'20px'} />
                    {link.label}
                  </Link>
                </Flex>
              ))}
            </Flex>
            <Flex
              color={'white'}
              justify={variant === 'desktop' ? 'center' : 'start'}
              align={variant === 'desktop' ? 'end' : 'start'}
              gap={'20px'}
              flexDir={variant === 'desktop' ? 'row' : 'column'}
            >
              <Box>
                <Link onClick={handleCookiePolicy} textDecor={'none'}>
                  Cookie Policy
                </Link>
              </Box>
              <Box>
                <Link onClick={handlePrivacyPolicy} textDecor={'none'}>
                  Privacy Policy
                </Link>
              </Box>
              <Box>
                <Text fontWeight={'700'} color={'white˝'}>
                  Say Hello 👋
                </Text>
                <Link href="mailto:support@backlinkit.com" textDecor={'none'}>
                  support@backlinkit.com
                </Link>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        {variant !== 'desktop' && (
          <Flex justify={{ md: 'start', lg: 'end' }} height={'30px'} marginTop={'30px'} mr={'60px'}>
            <Text color={AppColors.appBackground}>© 2024 Backlinkit.com. All Rights Reserved</Text>
          </Flex>
        )}
        {variant === 'desktop' && (
          <Flex
            justify={{ lg: 'start', xl: 'end' }}
            height={'30px'}
            marginTop={{ lg: '30px', xl: '-25px' }}
            mx={{ lg: '70px', xl: 0 }}
            mr={'60px'}
          >
            <Text color={AppColors.appBackground}>© 2024 Backlinkit.com. All Rights Reserved</Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default SharedFooter;
