import { Box, Flex, Icon, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineGoogle } from 'react-icons/ai';
import { AppColors, AppText } from '@backlinkit/shared';
import { FaTruckLoading } from 'react-icons/fa';

type GoogleIndexCellProps = {
  url: string;
  hasDomainIndex?: boolean;
  hasPageIndex?: boolean;
  googleCheckDate?: Date;
};

const CheckerGoogleIndexCell: React.FC<GoogleIndexCellProps> = ({
  url,
  hasDomainIndex,
  hasPageIndex,
  googleCheckDate,
}) => {
  if (hasDomainIndex === undefined && hasPageIndex === undefined) {
    return <></>;
  }

  const getToolTipLabel = () => {
    if (!googleCheckDate) {
      return 'In 24h';
    }
    if (hasDomainIndex && hasPageIndex) {
      return 'Page Indexed';
    } else if (hasDomainIndex) {
      return 'Domain Indexed';
    } else {
      return 'No Index';
    }
  };

  const getIconColor = () => {
    if (!googleCheckDate) {
      return 'grey';
    }
    if (hasDomainIndex && hasPageIndex) {
      return 'green';
    } else if (hasDomainIndex) {
      return AppColors.secondary;
    } else {
      return 'orange';
    }
  };

  return (
    <Box>
      <Tooltip label={getToolTipLabel()}>
        <span>
          {googleCheckDate ? (
            <Icon color={getIconColor()} as={AiOutlineGoogle} />
          ) : (
            <Icon color={getIconColor()} as={FaTruckLoading} />
          )}
        </span>
      </Tooltip>
    </Box>
  );
};

export default CheckerGoogleIndexCell;
