import React, { useEffect, useState } from 'react';
import { Flex, FormLabel, Select } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import {
  AppButton,
  AppInput,
  OrganizationRoleType,
  SelectListFilter,
  SelectOption,
} from '@backlinkit/shared';

export interface UserEditFormData {
  id: string;
  email: string;
  organizationId: string;
  organizationRoleId: OrganizationRoleType;
}

export const UserEditFormDataDefaultValues: UserEditFormData = {
  id: '',
  email: '',
  organizationRoleId: OrganizationRoleType.BACKLINKER,
  organizationId: '',
};

const UserEditFormDataSchema = yup.object({
  id: yup.string(),
  email: yup.string().required('Field is required'),
  organizationRoleId: yup.number().required('Field is required'),
});

type UserEditFormProps<T> = {
  isOwner: boolean;
  form?: UserEditFormData;
} & BaseFormProps<T>;

const UserEditForm: React.FC<UserEditFormProps<UserEditFormData>> = ({
  isOwner,
  form,
  onSubmit,
}) => {
  const {
    control: UserCreateControl,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm<UserEditFormData>({
    defaultValues: form || UserEditFormDataDefaultValues,
    resolver: yupResolver(UserEditFormDataSchema),
    mode: 'onChange',
  });

  const [defaultRole, setDefaultRole] = useState<SelectOption>();

  const roleOptions: SelectOption[] = [
    {
      label: 'Admin',
      value: 0,
    },
    {
      label: 'Backlinker',
      value: 1,
    },
  ];

  useEffect(() => {
    if (form && form.id) {
      if (form.organizationRoleId === 0) {
        setDefaultRole({
          label: 'Admin',
          value: 0,
        });
      } else {
        setDefaultRole({
          label: 'Backlinker',
          value: 1,
        });
      }
    }
  }, []);

  return (
    <Flex dir="column" w={'full'}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          width: '100%',
        }}
      >
        <AppInput<UserEditFormData>
          control={UserCreateControl}
          name="email"
          label="email"
          placeHolder="email"
          error={errors.email}
        />
        {!isOwner && (
          <>
            <FormLabel m={0} p={0}>
              User Role
            </FormLabel>
            <SelectListFilter
              w={'full'}
              showClear={false}
              name="organizationRoleId"
              isInModal
              isMulti={false}
              options={roleOptions}
              defaultOption={defaultRole}
              onSelectionChange={(item: SelectOption[]) => {
                setValue('organizationRoleId', item[0].value);
              }}
            />
          </>
        )}

        <AppButton w={'full'} mt={8} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Save
        </AppButton>
      </form>
    </Flex>
  );
};

export default UserEditForm;
