import React from 'react';
import {
  Flex,
  Box,
  Stack,
  Image,
  Text,
  useToast,
  Container,
  SimpleGrid,
  Heading,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { useUserAuthenticationMutation } from '../../store/api/authenticationApi';
import { setUserAuth, logout } from '../../store/slices/authentication.slice';
import Logo from '../../assets/backlinkit_logo.svg';
import LoginForm, { LoginFormData } from '../../components/forms/login-form';
import rightArrow from '../../assets/right-arrow.svg';
import { OrganizationRoleType, HttpError, AppColors, AppButton } from '@backlinkit/shared';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [userAuthentication, { data, isLoading, error, isSuccess, isError }] =
    useUserAuthenticationMutation();

  const dispatch = useDispatch();

  const signIn = async (loginFormData: LoginFormData) => {
    try {
      await dispatch(logout());

      const res = await userAuthentication({
        username: loginFormData.email,
        password: loginFormData.password,
      }).unwrap();

      dispatch(setUserAuth(res));
      const organizationRole = res.user.organizationRoleId;

      if (organizationRole === OrganizationRoleType.ADMIN) {
        navigate(RoutesList.Dashboard);
      } else {
        navigate(RoutesList.Batches);
      }
    } catch (errResponse) {
      console.error(errResponse);
      const httpError: HttpError = (errResponse as any).data as HttpError;

      toast({
        title: 'Login Failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const goToUserRegistration = () => {
    navigate(`${RoutesList.Registration}`);
  };

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  return variant === 'desktop' ? (
    <Flex
      h={'100vh'}
      align={'center'}
      justify={'center'}
      m={'33px'}
      border={`1px solid ${AppColors.appBorder}`}
      borderRadius={'15px'}
    >
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }} zIndex={10}>
          <Stack direction={'row'} spacing={4} align={'center'}>
            <Image height={'80px'} src={Logo} alt="" objectFit={'cover'} />
          </Stack>
          <Heading lineHeight={1.1} fontSize={'3xl'} color={AppColors.textColor}>
            BETA
          </Heading>
        </Stack>
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Login
              <Text as={'span'} bgGradient="linear(to-r, #00a4ff,#037DFC)" bgClip="text">
                !
              </Text>
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              {/* We’re looking for amazing engineers just like you! Become a part of our rockstar
              engineering team and skyrocket your career! */}
            </Text>
          </Stack>
          <Box as={'form'} mt={10}>
            <LoginForm isLoading={isLoading} onSubmit={signIn}></LoginForm>
          </Box>
        </Stack>
      </Container>
    </Flex>
  ) : (
    <Flex>
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }} zIndex={10}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Image height={'auto'} maxH={'50px'} src={Logo} alt="" objectFit={'cover'} />
          </Flex>
          <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={4}>
            <Heading lineHeight={1.1} fontSize={'3xl'} color={AppColors.textColor}>
              BETA
            </Heading>
          </Flex>
        </Stack>
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Login
              <Text as={'span'} bgGradient="linear(to-r, #00a4ff,#037DFC)" bgClip="text">
                !
              </Text>
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              {/* We’re looking for amazing engineers just like you! Become a part of our rockstar
              engineering team and skyrocket your career! */}
            </Text>
          </Stack>
          <Box as={'form'} mt={10}>
            <LoginForm isLoading={isLoading} onSubmit={signIn}></LoginForm>
          </Box>
        </Stack>
      </Container>
    </Flex>
  );
};

export default Login;
