import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { BacklinkEFL } from '@backlinkit/shared';

export const backlinkEflApi = createApi({
  reducerPath: 'backlinkEflApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchBacklinkEfls: build.query<BacklinkEFL, string>({
      query: (id) => ({
        url: `/backlinkefl/by/backlink/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: BacklinkEFL) => {
        return response;
      },
    }),
  }),
});

export const { useFetchBacklinkEflsQuery, useLazyFetchBacklinkEflsQuery } = backlinkEflApi;
