import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AppText } from '@backlinkit/shared';

type RelCellProps = {
  relValue?: string;
};

const SingleRelCell: React.FC<RelCellProps> = ({ relValue }) => {
  return (
    <Tooltip label={relValue}>
      <span>
        <AppText>{relValue ? relValue : 'Left blank'}</AppText>
      </span>
    </Tooltip>
  );
};

export default SingleRelCell;
