import { AppColors, AppPlayer, AppText } from '@backlinkit/shared';
import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

export type TrainingVideo = {
  title: string;
  url: string;
};

export interface TrainginAreaFormProps {
  videos: TrainingVideo[];
}

const TraingingAreaForm: React.FC<TrainginAreaFormProps> = ({ videos }) => {
  const PlayButton = () => {
    return <Flex w={'40px'} h={'40px'} borderRadius={'100%'} bgColor={AppColors.secondary} pos={'absolute'} top={0} left={0} m={'100%'}></Flex>;
  };
  return (
    <Flex w={'100%'}>
      <Accordion allowToggle w={'100%'}>
        {videos.map((video, index) => (
          <AccordionItem key={`${video.title}-${index}`}>
            <AccordionButton minH={'55px'}>
              <Flex w={'100%'} align={'center'} justify={'space-between'}>
                <AppText fontWeight={'500'}>{video.title}</AppText>
                <AccordionIcon color={AppColors.secondary} />
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              <Flex w={'100%'} mr={12} pos={'relative'}>
                <AppPlayer height="100%" width="100%" media={video.url ?? ''} />
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  );
};

export default TraingingAreaForm;
