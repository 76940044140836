import React from 'react';
import { AppText } from '@backlinkit/shared';

type SingleLandingPageCellProps = {
  landingPage?: string;
};

const SingleLandingPageCell: React.FC<SingleLandingPageCellProps> = ({ landingPage }) => {
  return (
    <AppText ml={2} whiteSpace={'pre-wrap'} wordBreak={'break-word'} cursor={'pointer'}>
      {landingPage ? landingPage : 'Left blank'}
    </AppText>
  );
};

export default SingleLandingPageCell;
