import { Box, Checkbox, Flex, SimpleGrid, Tag, TagLabel, useToast } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import {
  AppButton,
  AppCard,
  AppColors,
  AppLoader,
  AppText,
  HttpError,
  LoadingComponent,
  OrganizationRoleType,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import PasswordForm, { PasswordFormData } from '../../components/forms/password-reset-form';
import { useUpdatePasswordMutation } from '../../store/api/authenticationApi';

const UserProfile: React.FC = () => {
  const { setLoading } = useLoading();
  const toast = useToast();
  const user = useSelector(selectUser);
  const panel = usePanel();

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const handlePasswordChange = async (formData: PasswordFormData) => {
    try {
      setLoading(true);
      await updatePassword({
        id: user?.id ?? '',
        password: formData.confirmedPassword,
      });
      toast({
        title: 'Password',
        description: 'Successfully saved.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      const httpError: HttpError = (error as any).data as HttpError;

      toast({
        title: 'Password change failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const changePassword = () => {
    panel({
      title: 'Change your Password',
      size: 'md',
      render: (onSubmit) => {
        return (
          <Box as={'form'}>
            <PasswordForm
              onSubmit={(data) => {
                handlePasswordChange(data);
                onSubmit();
              }}
              saveButtonText="Save"
            ></PasswordForm>
          </Box>
        );
      },
    });
  };

  const renderRole = (organizationRoleId: OrganizationRoleType) => {
    switch (organizationRoleId) {
      case OrganizationRoleType.ADMIN:
        return (
          <Tag borderRadius="full" bg={AppColors.primary} color={'white'}>
            <TagLabel>Admin</TagLabel>
          </Tag>
        );
      case OrganizationRoleType.BACKLINKER:
        return (
          <Tag borderRadius="full" bg={AppColors.secondary} color={'white'}>
            <TagLabel>Backlinker</TagLabel>
          </Tag>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Flex direction={'column'} w={'100%'} h={'100%'} p={'20px'}>
        <AppCard>
          <Flex justifyContent={'space-between'} alignContent={'center'}>
            <Flex alignItems={'center'}>
              <Flex direction={'row'} mr={8}>
                <AppText fontWeight={'medium'} ml={2}>
                  Your Profile
                </AppText>
              </Flex>
            </Flex>
          </Flex>
        </AppCard>

        <Flex gap={5} h={'100%'}>
          <AppCard flex={1}>
            <Flex flexDirection={'column'}>
              <AppText pb={4} fontSize={'18px'} fontWeight={'medium'}>
                Your Details:
              </AppText>
              <SimpleGrid columns={2} spacingY={4}>
                <Box>
                  <AppText fontWeight={'medium'} fontSize={'14px'}>
                    User Created:
                  </AppText>
                </Box>
                <Box>
                  <AppText fontWeight={'bold'} fontSize={'14px'}>
                    {user?.dateCreated ? format(new Date(user?.dateCreated), 'dd MMM yyyy') : ''}
                  </AppText>
                </Box>
                <Box>
                  <AppText fontWeight={'medium'} fontSize={'14px'}>
                    Email / Username:
                  </AppText>
                </Box>
                <Box>
                  <AppText fontWeight={'bold'} fontSize={'14px'}>
                    {user?.email}
                  </AppText>
                </Box>
                <Box>
                  <AppText fontWeight={'medium'} fontSize={'14px'}>
                    Role:
                  </AppText>
                </Box>
                <Box>{user && renderRole(user?.organizationRoleId)}</Box>
              </SimpleGrid>

              <Box>
                <AppButton
                  mt={8}
                  bgColor={AppColors.secondary}
                  color={'white'}
                  borderRadius={'full'}
                  onClick={() => {
                    changePassword();
                  }}
                >
                  Change Password
                </AppButton>
              </Box>
            </Flex>
          </AppCard>
          <AppCard flex={1}>
            {user?.organizationRoleId === OrganizationRoleType.ADMIN && (
              <Flex flexDirection={'column'}>
                <AppText pb={4} fontSize={'18px'} fontWeight={'medium'}>
                  Organization Details:
                </AppText>
                <SimpleGrid columns={2} spacingY={4}>
                  <Box>
                    <AppText fontWeight={'medium'} fontSize={'14px'}>
                      Organization Contact Email:
                    </AppText>
                  </Box>
                  <Box>
                    <AppText fontWeight={'bold'} fontSize={'14px'}>
                      {user?.organization?.email}
                    </AppText>
                  </Box>
                  <Box>
                    <AppText fontWeight={'medium'} fontSize={'14px'}>
                      Company Name:
                    </AppText>
                  </Box>
                  <Box>
                    <AppText fontWeight={'bold'} fontSize={'14px'}>
                      {user?.organization.name}
                    </AppText>
                  </Box>
                  <Box>
                    <AppText fontWeight={'medium'} fontSize={'14px'}>
                      Accepted Terms:
                    </AppText>
                  </Box>
                  <Box>
                    <Checkbox
                      isDisabled={true}
                      isChecked={user?.organization.acceptTerms ?? false}
                    />
                  </Box>
                  <Box>
                    <AppText fontWeight={'medium'} fontSize={'14px'}>
                      Accepted Marketing:
                    </AppText>
                  </Box>
                  <Box>
                    <Checkbox
                      isDisabled={true}
                      isChecked={user?.organization.acceptMarketing ?? false}
                    />
                  </Box>
                  <Box>
                    <AppText fontWeight={'medium'} fontSize={'14px'}>
                      Organization Created:
                    </AppText>
                  </Box>
                  <Box>
                    <AppText fontWeight={'bold'} fontSize={'14px'}>
                      {user?.organization?.dateCreated
                        ? format(new Date(user?.organization?.dateCreated), 'dd MMM yyyy')
                        : ''}
                    </AppText>
                  </Box>

                  <Box>
                    <AppText fontWeight={'medium'} fontSize={'14px'}>
                      Current Subscription:
                    </AppText>
                  </Box>
                  <Box>
                    <AppText fontWeight={'bold'} fontSize={'14px'}>
                      {user?.organization?.product?.name}
                    </AppText>
                  </Box>
                  <Box>
                    <AppText fontWeight={'medium'} fontSize={'14px'}>
                      Available Credit:
                    </AppText>
                  </Box>
                  <Box>
                    <AppText fontWeight={'bold'} fontSize={'14px'}>
                      {user?.organization?.availableCredit}
                    </AppText>
                  </Box>
                </SimpleGrid>
              </Flex>
            )}
          </AppCard>
        </Flex>
      </Flex>
    </>
  );
};

export default UserProfile;
