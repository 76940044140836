import {
  Flex,
  Icon,
  IconButton,
  Image,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
} from '@chakra-ui/react';
import React from 'react';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { protocolRegex } from '../../../../constants/regex';
import { AppColors, AppText } from '@backlinkit/shared';

type UrlCellProps = {
  url?: string;
};

const UrlCell: React.FC<UrlCellProps> = ({ url }) => {
  const toast = useToast();

  const redirectToUrl = () => {
    window.open(url, '_blank');
  };

  const handleCopyToClipboardClick = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast({
          title: 'Copied to clipboard',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((e) => {
        toast({
          title: 'Failed to copy to clipboard',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };
  return (
    <Flex direction={'row'} alignItems={'center'}>
      {url && (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Flex>
              <Image
                minW={'20px'}
                src={`https://www.google.com/s2/favicons?domain=${url}`}
                alt=""
              />

              <AppText ml={2} maxW={'200px'} isTruncated>
                {url ? url.replace(protocolRegex, '') : ''}
              </AppText>
            </Flex>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody display={'flex'} flexDir={'row'} alignItems={'center'}>
              <IconButton
                bg={AppColors.secondary}
                color={'white'}
                aria-label="column visibility button"
                isRound={false}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                size="xs"
                mr={'15px'}
                onClick={(event) => {
                  event.stopPropagation();
                  handleCopyToClipboardClick(url);
                }}
              >
                <Icon as={HiOutlineClipboardCopy} size="1rem" color={'white'} />
              </IconButton>
              <Flex>
                <AppText
                  ml={2}
                  whiteSpace={'pre-wrap'}
                  wordBreak={'break-word'}
                  cursor={'pointer'}
                  onClick={() => redirectToUrl()}
                >
                  {url ? url.replace(protocolRegex, '') : ''}
                </AppText>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
};

export default UrlCell;
