import { InputGroup, Input, FormControl, FormLabel, FlexProps, Flex } from '@chakra-ui/react';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import { AppInputType } from '../app-input';

export type AppImageUploaderProps<T extends FieldValues> = FlexProps & {
  control: Control<T>;
  name: Path<T>;
  error: FieldError | undefined;
  label: string;
  inputType: AppInputType;
};

export const AppImageUploader = <T extends FieldValues>({
  control,
  name,
  error,
  label,
  inputType = 'file',
  ...props
}: AppImageUploaderProps<T>) => {
  return (
    <Flex {...props}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl>
            <FormLabel>{label}</FormLabel>
            <Input
              type={inputType}
              name={name}
              multiple
              onChange={onChange}
              onBlur={onBlur}
              // value={value}
              ref={ref}
              cursor={'pointer'}
              w={'100%'}
              h={'100%'}
            />
            {error && <div style={{ color: 'red' }}>{error?.message}</div>}
          </FormControl>
        )}
      ></Controller>
    </Flex>
  );
};
