import React from 'react';
import { Flex, Icon, IconButton, Tooltip, useColorModeValue, useToast } from '@chakra-ui/react';
import {
  AppText,
  AppColors,
  ColumnDefinitionType,
  BacklinkBatch,
  ColumnSpecialTypes,
  useDataTable,
  useLoading,
  AppDataTable,
  AppPaginator,
  PageChangeEvent,
  AppInput,
  AppButton,
  LoadingComponent,
} from '@backlinkit/shared';
import {
  BacklinkBatchFormData,
  backlinkBatchFormDataSchema,
  backlinkBatchFormDefaultValues,
} from '../../components/forms/backlink-batch-form';
import {
  useFetchBatchesByOptionsQuery,
  useUpdateBatchMutation,
  useCreateBatchMutation,
  useLazyFetchBatchesByDomainQuery,
} from '../../store/api/backlinkBatchesApi';
import { selectDomain } from '../../store/slices/domain.slice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaSave } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { useAppSelector } from '../../store/store';

const BatchManagement: React.FC = () => {
  const domain = useAppSelector(selectDomain);
  const toast = useToast();
  const { setLoading, loading } = useLoading();

  const {
    control: batchControl,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<BacklinkBatchFormData>({
    defaultValues: backlinkBatchFormDefaultValues,
    resolver: yupResolver(backlinkBatchFormDataSchema),
    mode: 'onChange',
  });

  const [createBatchTrigger] = useCreateBatchMutation();
  const [updateBatchTrigger] = useUpdateBatchMutation();
  const [getBatchesByDomain] = useLazyFetchBatchesByDomainQuery();

  const { handleOnPageChange, nextPaginationQueryParams, pagination } = useDataTable<BacklinkBatch>(
    {}
  );

  const {
    data: batches,
    refetch: refetchBatches,
    isFetching,
  } = useFetchBatchesByOptionsQuery(
    {
      domainId: domain?.id ?? '',
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleBatchEdit = (item: BacklinkBatch) => {
    setValue('domainId', item.domainId);
    setValue('id', item.id);
    setValue('name', item.name);
  };

  const batchColumns: ColumnDefinitionType<BacklinkBatch>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date',
    },
    {
      key: 'name',
      header: 'Name',
    },
    {
      key: 'backlinks',
      header: 'Backlinks',
      render: (item) => <AppText size={'xs'}>{item.backlinks?.length}</AppText>,
    },
    {
      key: 'backlinkBatchTags',
      header: 'Tags',
      render: (item) => <AppText size={'xs'}>{item.backlinkBatchTags?.length || 0}</AppText>,
    },
    {
      key: 'custom',
      header: 'Actions',
      render: (item) => (
        <Flex>
          <IconButton
            backgroundColor={'transparent'}
            aria-label={'editBatch'}
            isRound={true}
            onClick={() => handleBatchEdit(item)}
          >
            <Icon as={MdModeEdit} boxSize={'6'} />
          </IconButton>
        </Flex>
      ),
    },
  ];

  const handleSave = async () => {
    try {
      const formData = getValues();

      setLoading(true);

      if (formData.id) {
        const item = batches?.data.find((x) => x.id === formData.id);
        if (item) {
          await updateBatchTrigger({
            id: item.id,
            domainId: item.domainId,
            createdUserId: item.createdUserId,
            lastModifiedUserId: item.lastModifiedUserId,
            name: formData.name,
            price: item.price,
            notes: item.notes,
            batchTypeId: item.batchTypeId,
            nicheTypeId: item.nicheTypeId,
            tagChanges: false,
          });
        }
      } else {
        await createBatchTrigger({
          domainId: domain?.id ?? '',
          name: formData.name,
        });
      }

      toast({
        title: 'Batch Saved',
        description: "We've saved your batch successfully!",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      reset();
      refetchBatches();
      if (domain) {
        getBatchesByDomain(domain?.id);
      }

      setLoading(false);
    } catch (error) {
      toast({
        title: 'Batch error.',
        description: "We've run into a problem saving your batch, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  return (
    <Flex flexDirection={'column'}>
      {(loading || isFetching) && <LoadingComponent />}
      <Flex w={'full'} bg={useColorModeValue('white', 'gray.800')} flexDir={'column'} flex={1}>
        <Flex flex={1} alignItems={'end'}>
          <AppInput<BacklinkBatchFormData>
            mr={2}
            flex={1}
            name="name"
            control={batchControl}
            error={errors.name}
            label=""
            placeHolder="Add / Edit Batch Name"
          />
          <Tooltip shouldWrapChildren label={'Save Batch'} aria-label="saveBatch">
            <AppButton
              px={3}
              bgColor={AppColors.appBackground}
              color={'black'}
              onClick={() => handleSave()}
            >
              <FaSave />
            </AppButton>
          </Tooltip>
        </Flex>

        <AppDataTable
          searchBar={false}
          columns={batchColumns}
          data={batches?.data || []}
          selectableRows={false}
          showColumnVisibility={false}
          noDataMessage="No Batches added yet, start by adding some!"
        />
        <AppPaginator
          pagination={pagination}
          onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
        ></AppPaginator>
      </Flex>
    </Flex>
  );
};

export default BatchManagement;
