import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { AppButton, AppColors, BackLinkListItem, useDialog, useLoading } from '@backlinkit/shared';
import CheckerAnchorCell from '../components/app-link-anchor-cell';
import CheckerGoogleCacheCell from '../components/app-link-cache-index';
import CheckerUrlCell from '../components/app-link-url-cell';
import CheckerGoogleIndexCell from '../components/app-link-gi-cell';
import CheckerLinkStatusCell from '../components/app-checker-link-status-cell';
import CheckerLinkCard from './link-card';
import { useEffect } from 'react';

export interface DomainTableProps {
  links: BackLinkListItem[];
}

const CheckerDomainTable: React.FC<DomainTableProps> = ({ links }) => {
  const dialog = useDialog();
  const { setLoading } = useLoading();
  const renderDefaultActions = (items: BackLinkListItem[]) => {
    dialog({
      title: 'Link Details',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <Flex justify={'space-between'} w={'100%'} p={4}>
            <CheckerLinkCard links={items} />
          </Flex>
        );
      },
    });
  };
  useEffect(() => {
    setLoading(true);
  }, []);

  setTimeout(() => {
    setLoading(false);
  }, 800);

  return (
    <>
      <TableContainer flex={1}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>URL</Th>
              <Th>Status</Th>
              <Th>Anchor</Th>
              <Th>GI</Th>
              <Th>GC</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {links.map((link, index) => (
              <Tr key={`${link.url}-${index}`}>
                <Td>
                  <Tooltip label={link.url} fontWeight={'bold'}>
                    <Box>
                      <CheckerUrlCell url={link.url} />
                    </Box>
                  </Tooltip>
                </Td>
                <Td>
                  <CheckerLinkStatusCell
                    isManual={link.isManual}
                    linkStatus={link.backlinkMeta?.linkStatus}
                    robotsIndexStatus={link.backlinkMeta?.robotsIndexStatus}
                  />
                </Td>
                <Td>
                  <CheckerAnchorCell item={link} />
                </Td>
                <Td>
                  <Box pos={'relative'}>
                    <CheckerGoogleIndexCell
                      url={link.url}
                      hasPageIndex={link.backlinkGoogle?.hasDomainIndex}
                      hasDomainIndex={link.backlinkGoogle?.hasDomainIndex}
                      googleCheckDate={link.backlinkGoogle?.lastGoogleCheckDate}
                    />
                  </Box>
                </Td>
                <Td>
                  <Box pos={'relative'}>
                    <CheckerGoogleCacheCell
                      url={link.url}
                      cacheState={link.backlinkGoogle?.cacheState}
                      cacheDate={link.backlinkGoogle?.cacheCreatedDate}
                      googleCheckDate={link.backlinkGoogle?.lastGoogleCheckDate}
                    />
                  </Box>
                </Td>
                <Td>
                  <AppButton
                    bgColor={AppColors.secondary}
                    color={'white'}
                    size={'xs'}
                    rounded={'2xl'}
                    onClick={() => renderDefaultActions(links)}
                  >
                    Details
                  </AppButton>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CheckerDomainTable;
