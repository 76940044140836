import { Flex } from '@chakra-ui/react';
import React from 'react';
import { BacklinkTypes, AppColors, AppText, LinkStatus } from '@backlinkit/shared';
import { FaHome } from 'react-icons/fa';

type LandinPageCellProps = {
  url?: string;
  domainUrl?: string;
  landingPage?: string;
  linkStatus?: string;
  backlinkType?: BacklinkTypes;
};

const CheckerLandinPageCell: React.FC<LandinPageCellProps> = ({
  url,
  landingPage,
  linkStatus,
  domainUrl,
  backlinkType,
}) => {
  const getFullUrl = () => {
    if (backlinkType === BacklinkTypes.tierOne && domainUrl) {
      if (domainUrl.endsWith('/') && landingPage?.startsWith('/')) {
        return `${domainUrl.substring(0, domainUrl.length - 1)}${landingPage}`;
      } else {
        return `${domainUrl}${landingPage}`;
      }
    } else {
      return landingPage;
    }
  };

  return (
    <Flex direction={'row'} alignItems={'center'} w={'auto'}>
      {url && (
        <Flex align={'center'} gap={2}>
          <Flex>
            <AppText whiteSpace={'pre-wrap'} wordBreak={'break-word'}>
              {linkStatus === LinkStatus.Ok ? getFullUrl() : 'Not Found'}
            </AppText>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default CheckerLandinPageCell;
