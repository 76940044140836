import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { OrganizationTypeDto } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationTypeApi = createApi({
  reducerPath: 'organizationTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationTypes: build.query<OrganizationTypeDto[], any>({
      query: () => ({
        url: '/organizationtype',
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchAllOrganizationTypesQuery } = organizationTypeApi;
