import React from 'react';
import { Flex, Link, Button } from '@chakra-ui/react';
import { Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import {
  AppCheckbox,
  AppInput,
  AppText,
  CookiePolicy,
  Disclaimer,
  PrivacyPolicy,
  TermsAndConditions,
  usePanel,
} from '@backlinkit/shared';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';

export type RegistrationData = {
  accountName: string;
  email: string;
  password: string;
  termsAndConditions: boolean;
  keepUpdated: boolean;
};

export const registrationFormDataDefaultValues: RegistrationData = {
  accountName: '',
  email: '',
  password: '',
  termsAndConditions: false,
  keepUpdated: false,
};

const registrationFormDataSchema = yup.object({
  accountName: yup.string().required('Field is required'),
  email: yup.string().email().required('Field is required'),
  password: yup.string().required('Field is required'),
});

type RegistrationProps<T> = BaseFormProps<T>;

const RegistrationForm: React.FC<RegistrationProps<RegistrationData>> = ({ onSubmit }) => {
  const navigate = useNavigate();
  const panel = usePanel();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<RegistrationData>({
    defaultValues: registrationFormDataDefaultValues,
    resolver: yupResolver(registrationFormDataSchema),
    mode: 'all',
  });

  const handleTermsAndConditions = () => {
    panel({
      title: 'Terms and Conditions',
      size: 'lg',
      render: (onSubmit) => {
        return <TermsAndConditions />;
      },
    });
  };

  const handlePrivacyPolicy = () => {
    panel({
      title: 'Privacy Policy',
      size: 'lg',
      render: (onSubmit) => {
        return <PrivacyPolicy />;
      },
    });
  };

  const handleCookiePolicy = () => {
    panel({
      title: 'Cookie Policy',
      size: 'lg',
      render: (onSubmit) => {
        return <CookiePolicy />;
      },
    });
  };

  const handleDisclaimer = () => {
    panel({
      title: 'Disclaimer',
      size: 'lg',
      render: (onSubmit) => {
        return <Disclaimer />;
      },
    });
  };

  const handleFormSubmit = async (formData: RegistrationData) => {
    onSubmit(formData);
  };

  return (
    <Stack spacing={4}>
      <AppInput<RegistrationData>
        control={control}
        name="accountName"
        placeHolder="Account Name"
        error={errors.accountName}
      />
      <AppInput<RegistrationData>
        control={control}
        name="email"
        placeHolder="Email Address"
        error={errors.email}
      />
      <AppInput<RegistrationData>
        inputType="password"
        control={control}
        error={errors.password}
        name="password"
        placeHolder="Password"
      />
      <Stack spacing={4}>
        <Flex gap={'8px'} align={'center'}>
          <AppCheckbox<RegistrationData>
            name="termsAndConditions"
            control={control}
            label=""
            error={errors.termsAndConditions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue('termsAndConditions', e.target.checked);
            }}
          />
          <Flex flexFlow={'row wrap'} columnGap={'6px'}>
            <AppText fontSize={'14px'}>By continuing, I accept and agree with the</AppText>
            <Link
              fontSize={'14px'}
              lineHeight={1.2}
              textDecoration={'underline'}
              onClick={handleTermsAndConditions}
            >
              Terms of Use,
            </Link>
            <Link
              fontSize={'14px'}
              lineHeight={1.2}
              textDecoration={'underline'}
              onClick={handlePrivacyPolicy}
            >
              Privacy Policy,
            </Link>
            <Link
              fontSize={'14px'}
              lineHeight={1.2}
              textDecoration={'underline'}
              onClick={handleCookiePolicy}
            >
              Cookie Policy
            </Link>
            <AppText fontSize={'14px'}>and</AppText>
            <Link
              fontSize={'14px'}
              lineHeight={1.2}
              textDecoration={'underline'}
              onClick={handleDisclaimer}
            >
              Disclaimer
            </Link>
          </Flex>
        </Flex>
        <Flex gap={'10px'} align={'center'}>
          <AppCheckbox<RegistrationData>
            name="keepUpdated"
            control={control}
            label=""
            error={errors.keepUpdated}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue('keepUpdated', e.target.checked);
            }}
          />
          <AppText fontSize={'14px'}>
            I would like to receive marketing material and promotional offers from Backlinkit.
          </AppText>
        </Flex>
      </Stack>
      <Stack>
        {/* <AppButton flex={1} gap={2} onClick={handleSubmit(handleFormSubmit)}>
          Sign Up
          <Box
            boxSize={'22px'}
            borderRadius={'100%'}
            bg={AppColors.iconColorOne}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Image w={'14px'} src={rightArrow} />
          </Box>
        </AppButton> */}

        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fontFamily={'heading'}
          mt={8}
          w={'full'}
          bgGradient="linear(to-r, #00a4ff,#037DFC)"
          color={'white'}
          _hover={{
            bgGradient: 'linear(to-r, #00a4ff,#037DFC)',
            boxShadow: 'xl',
            transform: 'translateY(-2px)',
          }}
        >
          Sign Up
        </Button>

        <Button
          onClick={() => navigate(RoutesList.Index)}
          fontFamily={'heading'}
          bg={'gray.200'}
          color={'gray.800'}
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Login
        </Button>
      </Stack>
    </Stack>
  );
};

export default RegistrationForm;
