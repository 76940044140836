// Chakra imports
import {
  Flex,
  Text,
  useBreakpointValue,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Image,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { selectDomains, setSelectedDomain } from '../../store/slices/domain.slice';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import {
  DomainListItem,
  AppColors,
  AppButton,
  AppCard,
  SelectListFilter,
  SelectOption,
  AppText,
} from '@backlinkit/shared';
import { useLazyFetchBatchesByDomainQuery } from '../../store/api/backlinkBatchesApi';

export type DomainHeaderProps = {
  domain: DomainListItem;
};

const DomainHeader: React.FC<DomainHeaderProps> = ({ domain }) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const domains = useSelector(selectDomains);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getBatchesByDomain] = useLazyFetchBatchesByDomainQuery();
  const [domainOptions, setDomainOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (domains && domains.length > 0) {
      const options: SelectOption[] = domains.map((x) => {
        return { label: x.name, value: x.id };
      });
      setDomainOptions(options);
    }
  }, [domains]);

  const navigateClick = async (route: RoutesList) => {
    navigate(route);
  };
  const variant = useBreakpointValue({
    base: '12px',
    md: '15px',
    lg: '18px',
  });
  const screenSize = useBreakpointValue({
    base: '1000px',
    xl: '1440px',
  });

  const handleDomainSelectionChange = (selection: SelectOption[]) => {
    const selectedDomain = domains.find((x) => x.id === selection[0].value);
    if (selectedDomain) {
      dispatch(setSelectedDomain(selectedDomain));
      getBatchesByDomain(selectedDomain.id);
    }
  };

  return (
    <>
      <AppCard ml={'20px'} mr={'20px'} mt={4}>
        <Flex justifyContent={'space-between'} alignContent={'center'}>
          <Flex alignItems={'center'}>
            {/* <SelectListFilter
              mr={4}
              isInModal={true}
              isMulti={false}
              showClear={false}
              options={domainOptions}
              defaultOption={{ label: domain.name, value: domain.id }}
              onSelectionChange={(selection: SelectOption[]) => {
                handleDomainSelectionChange(selection);
              }}
            /> */}

            <Flex direction={'row'} mr={8}>
              <Image
                minW={'20px'}
                src={`https://www.google.com/s2/favicons?domain=${domain?.url}`}
                alt=""
              />
              <AppText fontWeight={'medium'} ml={2}>
                {domain.name}
              </AppText>
            </Flex>

            <Flex mr={8} alignItems="center" flexDirection="row">
              <Text mr="4" color={textColorSecondary} fontSize="sm" fontWeight="400">
                Backlinks
              </Text>
              <Text color={textColorPrimary} fontSize={variant} fontWeight="700">
                {domain ? domain.backLinkCount : 0}
              </Text>
            </Flex>
            <Flex alignItems="center" flexDirection="row">
              <Text mr="4" color={textColorSecondary} fontSize="sm" fontWeight="400">
                Errors
              </Text>
              <Text color={textColorPrimary} fontSize={variant} fontWeight="700">
                {domain ? domain.errorCount : 0}
              </Text>
            </Flex>
          </Flex>

          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            display={screenSize === '1440px' ? 'flex' : 'none'}
          >
            <AppButton
              mr={4}
              bgColor={'white'}
              border={`1px solid ${AppColors.appBorder}`}
              variant={'solid'}
              size="sm"
              fontSize={variant}
              borderRadius="full"
              onClick={() => {
                navigateClick(RoutesList.DomainProfile);
              }}
            >
              Profile
            </AppButton>
            <AppButton
              mr={4}
              bgColor={'white'}
              border={`1px solid ${AppColors.appBorder}`}
              variant={'solid'}
              size="sm"
              fontSize={variant}
              borderRadius="full"
              onClick={() => {
                navigateClick(RoutesList.Links);
              }}
            >
              All Links
            </AppButton>
            {/* <AppButton
              mr={4}
              bgColor={'white'}
              border={`1px solid ${AppColors.appBorder}`}
              variant={'solid'}
              size="sm"
              fontSize={variant}
              borderRadius="full"
              onClick={() => {
                navigateClick(RoutesList.Tags);
              }}
            >
              Tags
            </AppButton> */}
            <AppButton
              mr={4}
              bgColor={'white'}
              border={`1px solid ${AppColors.appBorder}`}
              variant={'solid'}
              size="sm"
              fontSize={variant}
              borderRadius="full"
              onClick={() => {
                navigateClick(RoutesList.Batches);
              }}
            >
              Batches
            </AppButton>
            <AppButton
              mr={4}
              bgColor={'white'}
              border={`1px solid ${AppColors.appBorder}`}
              variant={'solid'}
              size="sm"
              fontSize={variant}
              borderRadius="full"
              onClick={() => {
                navigateClick(RoutesList.DomainLinkChecker);
              }}
            >
              Backlink Checker
            </AppButton>
          </Flex>
          <Menu>
            <MenuButton
              mr={4}
              bgColor={'white'}
              border={`1px solid ${AppColors.appBorder}`}
              variant={'solid'}
              size="sm"
              fontSize={variant}
              borderRadius="full"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
              display={screenSize === '1000px' ? 'block' : 'none'}
            >
              Page
            </MenuButton>
            <MenuList zIndex={5}>
              <MenuItem onClick={() => navigateClick(RoutesList.DomainProfile)}>Profile</MenuItem>
              <MenuItem onClick={() => navigateClick(RoutesList.Links)}>Backlinks</MenuItem>
              <MenuItem onClick={() => navigateClick(RoutesList.LinksReports)}>
                External Reports
              </MenuItem>
              <MenuItem onClick={() => navigateClick(RoutesList.Tags)}>Tags</MenuItem>
              <MenuItem onClick={() => navigateClick(RoutesList.Batches)}>Batches</MenuItem>
              <MenuItem onClick={() => navigateClick(RoutesList.DomainLinkChecker)}>
                Backlink Checker
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </AppCard>
    </>
  );
};

export default DomainHeader;
