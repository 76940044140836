import { Box, Flex, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AppText, usePanel } from '@backlinkit/shared';
import { BaseBacklinkCell } from './base-backlink-cell';
import EflPanel from '../../efl-panel';

type EflCellProps = BaseBacklinkCell & {
  organizationId: string;
  parentUrl: string;
};

const EflCell: React.FC<EflCellProps> = ({ organizationId, parentUrl, item }) => {
  const panel = usePanel();

  // const [fetchBacklinkEflsQuery] = useLazyFetchBacklinkEflsQuery();

  const handleEflListDialog = async () => {
    // const eflList = await fetchBacklinkEflsQuery(item.id).unwrap();

    panel({
      title: `${item.url} EFL's`,
      size: 'xl',
      render: (onSubmit) => {
        return (
          <EflPanel organizationId={organizationId} backlinkId={item.id} parentUrl={parentUrl} />
        );
      },
    });
  };

  if (!item.backlinkMeta) {
    return <></>;
  }

  return (
    <Box>
      <Tooltip
        label={
          <Flex flexDirection={'column'}>
            <AppText>External Follow: {item?.backlinkMeta?.externalFollowCount}</AppText>
            <AppText>External NoFollow: {item?.backlinkMeta?.externalNoFollowCount}</AppText>
            <AppText>Internal: {item?.backlinkMeta?.internalFollowCount}</AppText>
          </Flex>
        }
      >
        <span>
          <AppText color={'green'} cursor={'pointer'} onClick={() => handleEflListDialog()}>
            {item?.backlinkMeta?.externalFollowCount}
          </AppText>
        </span>
      </Tooltip>
    </Box>
  );
};

export default EflCell;
