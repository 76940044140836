import {
  Flex,
  useColorModeValue,
  Text,
  Box,
  Image,
  useToast,
  useBreadcrumbStyles,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import {
  useGetExternalReportQuery,
  useMarkExternalBacklinkFixedMutation,
} from '../../store/api/unAuthBacklinkReportApi';
import { useNavigate, useParams } from 'react-router-dom';
import ExternalReportTable from './external-report-table/external-report-table';
import logo from '../../assets/backlinkit_logo.svg';
import { AppColors, AppText, SharedFooter, signupBetaUrl, useLoading } from '@backlinkit/shared';
import { RoutesList } from '../../router/router';
import { socialLinks, legals } from '../../constants/footer-links';
import footerGraphic from '../../assets/Footer graphics.png';
import rightPipe from '../../assets/CTA graphics.png';

const ExternalReportsPage: React.FC = () => {
  const navigate = useNavigate();
  const title = 'Link Report';
  const toast = useToast();
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const { id } = useParams();
  const { data: report, refetch: refetchReport } = useGetExternalReportQuery(id!);
  const [markExternalBacklinkFixed] = useMarkExternalBacklinkFixedMutation();
  const { setLoading } = useLoading();
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  const goToSignUp = () => {
    navigate(RoutesList.Registration);
  };

  const markBacklinkFixed = async (backlinkId: string) => {
    try {
      setLoading(true);
      await markExternalBacklinkFixed({ id: id ?? '', backlinkId }).unwrap();
      toast({
        title: 'Marked as fixed',
        description: `We've marked the backlink as fixed`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    } catch (error) {
      toast({
        title: `Error - limit reached`,
        description: `We've pickedup multiple attempts to fix the same link. This has been blocked`,
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
    }
    refetchReport();
  };

  return (
    <>
      <Box flexDir={'column'} minH={'100vh'} bg={AppColors.appBackground} overflow={'scroll'}>
        <Box
          w={'100vw'}
          display={'flex'}
          maxH={'65px'}
          bg={'white'}
          top={0}
          p={2}
          dir="row"
          justifyContent={'start'}
        >
          <Image src={logo} h={'50px'} ml={'10px'} />
        </Box>

        <Box>
          <Flex
            flexDirection={'column'}
            bg={useColorModeValue('white', 'gray.800')}
            rounded={'2xl'}
            p={4}
            m={4}
          >
            <AppText fontWeight={'bold'}>Note:</AppText>
            <AppText mt={2}>{report?.notes ?? 'N/A'}</AppText>
          </Flex>
          <Flex align={'center'} justifyContent={'center'} flex={1} dir="column">
            <Flex direction={'column'} w={'100%'}>
              <Flex mx={4}>
                <Text
                  color={textColorPrimary}
                  fontWeight={'bold'}
                  fontSize={'2xl'}
                  mt={'10px'}
                  mb={'4px'}
                >
                  {title}
                </Text>
              </Flex>
              <Flex
                bg={useColorModeValue('white', 'gray.800')}
                rounded={'2xl'}
                p={4}
                m={4}
                flexDir={'column'}
                flex={1}
              >
                <ExternalReportTable
                  data={report?.externalBacklinks ?? []}
                  markBacklinkFixed={markBacklinkFixed}
                />
              </Flex>
            </Flex>
          </Flex>
        </Box>

        {/* <Flex
            height={'150px'}
            width={'100%'}
            position={'absolute'}
            bottom={0}
            bgColor={AppColors.secondary}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <AppText color={'white'}>CTA AREA</AppText>
          </Flex> */}

        <Box p={4}>
          <SharedFooter
            signUpLink={signupBetaUrl}
            borderRadius={variant === 'desktop' ? '15px' : '0'}
            borderTopRadius={'15px !important'}
            rightPipe={rightPipe}
            leftPipe={footerGraphic}
            socialLinks={socialLinks}
          />
        </Box>
      </Box>
    </>
  );
};
export default ExternalReportsPage;
