import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  PaginatedData,
  OrganizationTag,
  OrganizationTagForm,
  OrganizationTagEditForm,
  TagByOptionsRequest,
  OrganizationTagBulkCreateResponse,
  OrganizationTagBulkCreateRequest,
} from '@backlinkit/shared';

export const tagApi = createApi({
  reducerPath: 'tagApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchTagsByOptions: build.query<PaginatedData<OrganizationTag>, TagByOptionsRequest>({
      query: (data) => ({
        url: `/tag/by/options`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<OrganizationTag>) => {
        return response;
      },
    }),
    fetchTagsByOrganizationId: build.query<OrganizationTag[], string>({
      query: (orgId) => ({
        url: `/tag/by/organization/${orgId}`,
        method: 'GET',
      }),
      transformResponse: (response: OrganizationTag[]) => {
        const tagsData = response;
        return tagsData;
      },
    }),
    tagBulkCreate: build.mutation<
      OrganizationTagBulkCreateResponse,
      OrganizationTagBulkCreateRequest
    >({
      query: (data) => ({
        url: '/tag/create/bulk',
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: OrganizationTagBulkCreateResponse) => {
        return response;
      },
    }),
    createTag: build.mutation<OrganizationTag, OrganizationTagForm>({
      query: (payload) => ({
        url: `/tag/create`,
        method: 'POST',
        body: payload,
      }),
    }),
    editTag: build.mutation<OrganizationTag, OrganizationTag>({
      query: (payload) => ({
        url: `/tag/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteTag: build.mutation<OrganizationTag, string>({
      query: (tagId) => ({
        url: `/tag/delete/${tagId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchTagsByOptionsQuery,
  useLazyFetchTagsByOrganizationIdQuery,
  useFetchTagsByOrganizationIdQuery,
  useCreateTagMutation,
  useEditTagMutation,
  useDeleteTagMutation,
  useTagBulkCreateMutation,
} = tagApi;
