import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { BatchType } from '@backlinkit/shared';

export const batchTypesApi = createApi({
  reducerPath: 'batchTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllBatchTypes: build.query<BatchType[], any>({
      query: () => ({
        url: '/batchtype',
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchAllBatchTypesQuery } = batchTypesApi;
