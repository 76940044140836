import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppCsvFileUploadReader,
  CsvFileUploadReaderResult,
  OrganizationTag,
} from '@backlinkit/shared';
import { BaseFormProps } from './base-forms';
import { AppButton, AppText } from '@backlinkit/shared';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Icon,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FiFile } from 'react-icons/fi';
import { FaDownload } from 'react-icons/fa';
import { OrganizationTagFormData } from './organization-tag-create-form';

export interface CsvTagFormData {
  names: string;
  descriptions?: string;
}

export const csvTagsFormDataDefaultValues: CsvTagFormData = {
  names: '',
  descriptions: '',
};

const csvTagsFormDataSchema = yup.object({
  names: yup.string(),
  descriptions: yup.string(),
});

type CsvTagsFormProps<T> = {} & BaseFormProps<T>;

const BulkTagsForm: React.FC<CsvTagsFormProps<OrganizationTagFormData[]>> = ({ onSubmit }) => {
  const {
    control: tagsControl,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<CsvTagFormData>({
    defaultValues: csvTagsFormDataDefaultValues,
    resolver: yupResolver(csvTagsFormDataSchema),
    mode: 'onChange',
  });

  const [currentTags, setCurrentTags] = useState<OrganizationTag[]>([]);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);

  const handleTagsImportFileParsed = (fileData: CsvFileUploadReaderResult) => {
    const tagsToCreate = fileData.validResults.map((x) => {
      const separatedValues = x.rowValue[0].split(';');
      return {
        name: separatedValues[0],
        description: separatedValues[1] ?? '',
      } as OrganizationTag;
    });
    setCurrentTags(tagsToCreate);
    setFileUploaded(true);
  };

  const handleTagsSubmit = () => {
    const tagsFormList: OrganizationTagFormData[] = [];

    currentTags.forEach((tag) => {
      tagsFormList.push({
        name: tag.name!,
        description: tag.description,
      });
    });
    onSubmit(tagsFormList);
  };

  return (
    <Flex direction={'column'} flex={1} w={'full'} gap={4}>
      <Flex w={'full'} justify={'space-between'} p={4}>
        <AppCsvFileUploadReader<CsvTagFormData> onFileParsed={handleTagsImportFileParsed}>
          <AppButton leftIcon={<Icon as={FiFile} />}>Upload</AppButton>
        </AppCsvFileUploadReader>
        <Link href={'/templates/csv-tags-template.csv'} target="_blank">
          <AppButton leftIcon={<Icon as={FaDownload} />}>Download Template</AppButton>
        </Link>
      </Flex>
      {currentTags.length > 0 && (
        <>
          <AppText>Tags to add</AppText>
          <TableContainer overflow={'auto'}>
            <Table variant={'simple'}>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentTags.map((tag, i) => {
                  return (
                    <Tr key={i}>
                      <Td>
                        <AppText isTruncated>{tag.name}</AppText>
                      </Td>
                      <Td>
                        <AppText isTruncated>{tag.description}</AppText>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
      <AppButton
        display={fileUploaded ? 'flex' : 'none'}
        w={'full'}
        onClick={() => {
          handleTagsSubmit();
        }}
      >
        Save Tags
      </AppButton>
    </Flex>
  );
};

export default BulkTagsForm;
