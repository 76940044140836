import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { OrganizationLinkEstimationTypeDto } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationLinkEstimationTypeApi = createApi({
  reducerPath: 'organizationLinkEstimationType',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationLinkEstimationTypes: build.query<OrganizationLinkEstimationTypeDto[], any>({
      query: () => ({
        url: '/organizationLinkEstimationType',
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchAllOrganizationLinkEstimationTypesQuery } =
  organizationLinkEstimationTypeApi;
