import { ChakraProvider } from '@chakra-ui/react';
import Router from './router/router';
import './App.css';
import {
  DialogServiceProvider,
  LoadingProvider,
  PanelServiceProvider,
  theme,
} from '@backlinkit/shared';

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <LoadingProvider>
        <DialogServiceProvider>
          <PanelServiceProvider>
            <Router></Router>
          </PanelServiceProvider>
        </DialogServiceProvider>
      </LoadingProvider>
    </ChakraProvider>
  );
};
