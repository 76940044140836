import { Flex, Stack } from '@chakra-ui/react';
import { AppButton, AppColors, AppInput, AppText } from '@backlinkit/shared';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export interface PasswordFormData {
  newPassword: string;
  confirmedPassword: string;
}

export const PasswordFormDefaultValues: PasswordFormData = {
  newPassword: '',
  confirmedPassword: '',
};

export const PasswordFormDataSchema = yup.object({
  newPassword: yup.string().required('Field is required'),
  confirmedPassword: yup.string().required('Field is required'),
});

type PasswordFormProps = {
  form?: PasswordFormData;
  saveButtonText?: string;
  onSubmit: (data: PasswordFormData) => void;
};

const PasswordForm: React.FC<PasswordFormProps> = ({
  form,
  saveButtonText = 'Save & Login',
  onSubmit,
}) => {
  const [match, setMatch] = useState<boolean>(true);
  const {
    control: passwordControl,
    formState: { isValid, errors },
    getValues,
    handleSubmit,
  } = useForm<PasswordFormData>({
    defaultValues: form || PasswordFormDefaultValues,
    resolver: yupResolver(PasswordFormDataSchema),
    mode: 'onSubmit',
  });

  const handleFormSubmit = () => {
    const formValues = getValues();
    if (formValues.newPassword === formValues.confirmedPassword) {
      onSubmit(formValues);
    } else {
      setMatch(false);
    }
  };

  return (
    <Stack spacing={4} bgColor={'transparent'} color={'black'}>
      <AppInput<PasswordFormData>
        name="newPassword"
        error={errors.newPassword}
        control={passwordControl}
        label="New Password"
      />
      <AppInput<PasswordFormData>
        name="confirmedPassword"
        error={errors.confirmedPassword}
        control={passwordControl}
        label="Confirmed Password"
      />
      <Flex display={match ? 'none' : 'flex'} w={'100%'} justify={'center'}>
        <AppText color={'red'}>Passwords do not match</AppText>
      </Flex>
      <AppButton
        w={'full'}
        bgColor={AppColors.secondary}
        color={'white'}
        mt={10}
        isDisabled={!isValid}
        onClick={() => handleFormSubmit()}
        loadingText="Logging in"
      >
        {saveButtonText}
      </AppButton>
    </Stack>
  );
};

export default PasswordForm;
