import React from 'react';
import { AppText, LinkType } from '@backlinkit/shared';

type AnchorCellProps = { anchor?: string; linkType?: LinkType };

const SingleAnchorCell: React.FC<AnchorCellProps> = ({ anchor, linkType }) => {
  return (
    <AppText ml={2} whiteSpace={'normal'}>
      {linkType !== LinkType.Image && anchor}
    </AppText>
  );
};

export default SingleAnchorCell;
