import React from 'react';
import {
  AlertListItem,
  AppAlertList,
  ExternalBacklink,
  ScrapeErrorTypes,
  useDialog,
} from '@backlinkit/shared';

import { format } from 'date-fns';
import { Flex, Tag, TagLabel } from '@chakra-ui/react';

type ErrorCountCellProps = {
  item: ExternalBacklink;
};

export const SingleErrorCountCell: React.FC<ErrorCountCellProps> = ({ item }) => {
  const dialog = useDialog();

  const hasErrors = item.errors?.some((error) => error.errorType === ScrapeErrorTypes.error);
  const hasWarnings = item.errors?.some((error) => error.errorType === ScrapeErrorTypes.warning);
  const hasInfo = item.errors?.some((error) => error.errorType === ScrapeErrorTypes.info);

  let alertColor = 'grey';

  if (hasErrors) {
    alertColor = 'red';
  } else if (hasWarnings) {
    alertColor = 'orange';
  } else if (hasInfo) {
    alertColor = 'blue';
  }

  const handleErrorListDialog = (item: ExternalBacklink) => {
    const errorList: AlertListItem[] = [];

    if (item.errors) {
      const errors: AlertListItem[] = item.errors.map((error) => {
        return {
          name: item.url,
          alertType: error.errorType,
          alertMessage: error.errorMessage,
          alertNoteLabel: 'Created',
          alertNote: error.dateCreated ? format(new Date(error.dateCreated), 'dd MMM yyyy') : '',
          entityId: item.backlinkId,
          alertKey: error.key,
        };
      });
      errorList.push(...errors);
    }

    dialog({
      title: `${item.url} Errors`,
      size: 'xl',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <AppAlertList
            items={errorList}
            showName={false}
            showType={true}
            itemsClearable={false}
          ></AppAlertList>
        );
      },
    });
  };

  return (
    <Flex>
      <Tag borderRadius="full" colorScheme={alertColor}>
        <TagLabel cursor={'pointer'} onClick={() => handleErrorListDialog(item)}>
          {item && item.errors && item?.errors?.length > 0 ? item?.errors?.length : '-'}
        </TagLabel>
      </Tag>
    </Flex>
  );
};
