import { createApi } from '@reduxjs/toolkit/query/react';
import { AppUnAuthFetchBaseQuery } from './apiBase';
import {
  OtpVerifyRequest,
  UserBacklinkExternalReportByExternalUserResponse,
  BacklinkReportMarkFixedRequest,
} from '@backlinkit/shared';

export const unAuthBacklinkReportApi = createApi({
  reducerPath: 'unAuthBacklinkReportApi',
  baseQuery: AppUnAuthFetchBaseQuery,
  endpoints: (build) => ({
    verifyExternalReport: build.query<boolean, OtpVerifyRequest>({
      query: (body) => ({
        url: `/userbacklinkexternalreport/${body.id}/verify/otp/${body.otp}`,
        method: 'GET',
      }),
      transformResponse: (response: boolean) => {
        return response;
      },
    }),
    getExternalReport: build.query<UserBacklinkExternalReportByExternalUserResponse, string>({
      query: (id) => ({
        url: `/userbacklinkexternalreport/by/external/user/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: UserBacklinkExternalReportByExternalUserResponse) => {
        return response;
      },
    }),
    markExternalBacklinkFixed: build.mutation<boolean, BacklinkReportMarkFixedRequest>({
      query: (body) => ({
        url: `/userbacklinkexternalreport/mark/external/report/${body.id}/backlink/${body.backlinkId}/fixed`,
        method: 'PATCH',
      }),
      transformResponse: (response: boolean) => {
        return response;
      },
    }),
  }),
});

export const {
  useLazyVerifyExternalReportQuery,
  useGetExternalReportQuery,
  useLazyGetExternalReportQuery,
  useMarkExternalBacklinkFixedMutation,
} = unAuthBacklinkReportApi;
