import React from 'react';
import { Icon, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
import { calculateAge, AppText } from '@backlinkit/shared';
import { BsEye } from 'react-icons/bs';

type LinkDomainAgeCellProps = {
  url: string | undefined;
  originalDomainCreationDate: string | undefined;
};

const LinkDomainAgeCell = React.memo<LinkDomainAgeCellProps>(
  ({ originalDomainCreationDate, url }) => {
    const handleDomainAgeCellClick = async () => {
      window.open(`https://www.whois.com/whois/${url}`, 'blank');
    };

    if (originalDomainCreationDate) {
      return (
        <Tooltip
          label={
            originalDomainCreationDate
              ? format(new Date(originalDomainCreationDate), 'dd MMM yyyy')
              : ''
          }
          fontWeight={'bold'}
        >
          <span onClick={handleDomainAgeCellClick}>
            <AppText cursor="pointer">{calculateAge(originalDomainCreationDate)}</AppText>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip label={'Have a look at Whois lookup!'} fontWeight={'bold'}>
          <span onClick={handleDomainAgeCellClick}>
            <Icon as={BsEye} />
          </span>
        </Tooltip>
      );
    }
  }
);

export default LinkDomainAgeCell;
