import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/react';
import logo from '../../assets/backlinkit_logo.svg';
import PasswordForm, { PasswordFormData } from '../../components/forms/password-reset-form';
import { AppColors, AppText, useLoading } from '@backlinkit/shared';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserAuthenticationMutation } from '../../store/api/authenticationApi';
import { logout, setUserAuth } from '../../store/slices/authentication.slice';
import { HttpError } from '@backlinkit/shared';
import { RoutesList } from '../../router/router';
import {
  useAuthenticatePasswordKeyQuery,
  useUpdatePasswordMutation,
} from '../../store/api/authenticationApi';

const ChangePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { setLoading } = useLoading();
  const { id } = useParams();
  const { data: user } = useAuthenticatePasswordKeyQuery(id ?? '');

  const [userAuthentication, { data, isLoading, error, isSuccess, isError }] =
    useUserAuthenticationMutation();

  const [updatePassword] = useUpdatePasswordMutation();

  const dispatch = useDispatch();

  const signIn = async (formData: PasswordFormData) => {
    try {
      setLoading(true);
      await dispatch(logout());
      await updatePassword({
        id: user?.id ?? '',
        password: formData.confirmedPassword,
      });

      toast({
        title: 'Password',
        description: 'Password successfully saved.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      const res = await userAuthentication({
        username: user!.email,
        password: formData.newPassword,
      }).unwrap();

      dispatch(setUserAuth(res));
      setLoading(false);
      navigate(user?.organizationRoleId === 0 ? RoutesList.Dashboard : RoutesList.Batches);
    } catch (error) {
      console.error(error);
      const httpError: HttpError = (error as any).data as HttpError;

      toast({
        title: 'Login Failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Flex
      h={'100vh'}
      w={'100%'}
      align={'center'}
      justify={'center'}
      m={'33px'}
      border={`1px solid ${AppColors.appBorder}`}
      borderRadius={'15px'}
    >
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }} zIndex={10}>
          <Stack direction={'row'} spacing={4} align={'center'}>
            <Image height={'80px'} src={logo} alt="" objectFit={'cover'} />
          </Stack>
          <Heading lineHeight={1.1} fontSize={'3xl'} color={AppColors.textColor}>
            CHANGE PASSWORD
          </Heading>
        </Stack>
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Change Password
              <AppText
                as={'span'}
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                bgGradient="linear(to-r, #00a4ff,#037DFC)"
                bgClip="text"
                fontWeight={'bold'}
              >
                !
              </AppText>
            </Heading>
            <AppText color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}></AppText>
          </Stack>
          <Box as={'form'} mt={10}>
            <PasswordForm onSubmit={signIn}></PasswordForm>
          </Box>
        </Stack>
      </Container>
    </Flex>
  );
};

export default ChangePasswordPage;
