import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BacklinkBatch,
  BacklinkBatchForm,
  PaginatedData,
  BatchByOptionsRequest,
  BacklinkBatchUpdateRequest,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const batchApi = createApi({
  reducerPath: 'batches',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchBatchesByOptions: build.query<PaginatedData<BacklinkBatch>, BatchByOptionsRequest>({
      query: (data) => ({
        url: '/backlinkbatch/by/options',
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<BacklinkBatch>) => {
        return response;
      },
    }),
    fetchBatchesByDomain: build.query<BacklinkBatch[], string>({
      query: (id) => ({
        url: `/backlinkbatch/by/domain/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: BacklinkBatch[]) => {
        return response;
      },
    }),
    createBatch: build.mutation<BacklinkBatch, BacklinkBatchForm>({
      query: (body) => ({
        url: '/backlinkbatch/create',
        method: 'POST',
        body: body,
      }),
    }),
    updateBatch: build.mutation<BacklinkBatch, BacklinkBatchUpdateRequest>({
      query: (body) => ({
        url: `/backlinkbatch/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteBatch: build.mutation<BacklinkBatch, string>({
      query: (id) => ({
        url: `/backlinkbatch/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchBatchesByOptionsQuery,
  useFetchBatchesByDomainQuery,
  useLazyFetchBatchesByDomainQuery,
  useLazyFetchBatchesByOptionsQuery,
  useCreateBatchMutation,
  useUpdateBatchMutation,
  useDeleteBatchMutation,
} = batchApi;
