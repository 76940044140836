import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { getTransformedDomainData } from './domainApi-helper';
import {
  PaginatedData,
  DomainListItem,
  DomainByOptionsRequest,
  Domain,
  DomainForm,
  DomainEditForm,
} from '@backlinkit/shared';

export const domainApi = createApi({
  reducerPath: 'domainApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchDomainsByOptions: build.query<PaginatedData<DomainListItem>, DomainByOptionsRequest>({
      query: (data) => ({
        url: `/domain/by/options`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<Domain>) => {
        const transformedData: DomainListItem[] = getTransformedDomainData(response.data);
        return {
          data: transformedData,
          pagination: response.pagination,
        } as PaginatedData<DomainListItem>;
      },
    }),
    fetchDomainsByOrgId: build.query<DomainListItem[], string>({
      query: (orgId) => ({
        url: `/domain/by/organization/${orgId}`,
        method: 'GET',
      }),
      transformResponse: (response: Domain[]) => {
        const transformedData: DomainListItem[] = getTransformedDomainData(response);
        return transformedData;
      },
    }),
    saveDomain: build.mutation<Domain, DomainForm>({
      query: (payload) => ({
        url: `/domain/create`,
        method: 'POST',
        body: payload,
      }),
    }),
    editDomain: build.mutation<Domain, Domain>({
      query: (payload) => ({
        url: `/domain/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteDomain: build.mutation<Domain, string>({
      query: (domainId) => ({
        url: `/domain/delete/${domainId}`,
        method: 'DELETE',
      }),
    }),
    fetchDomainById: build.query<DomainListItem, string>({
      query: (id) => ({
        url: `/domain/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Domain) => {
        const transformedData: DomainListItem[] = getTransformedDomainData([response]);
        return transformedData[0];
      },
    }),
  }),
});

export const {
  useLazyFetchDomainsByOptionsQuery,
  useLazyFetchDomainByIdQuery,
  useLazyFetchDomainsByOrgIdQuery,
  useSaveDomainMutation,
  useEditDomainMutation,
  useDeleteDomainMutation,
} = domainApi;
