import { Flex, useToast, useColorModeValue, Text, TagLabel, Tag, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import TagsActionsMenu from '../../components/app/app-menus/tags-actions-menu';
import { useAppSelector } from '../../store/store';
import { selectUser } from '../../store/slices/authentication.slice';
import {
  useDeleteBacklinkReportMutation,
  useFetchBacklinkReportsByOptionsQuery,
  useResendBacklinkReportMutation,
} from '../../store/api/backlinkReportApi';
import { DeleteIcon, ViewIcon } from '@chakra-ui/icons';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  AppLoader,
  AppPaginator,
  AppText,
  BacklinkReport,
  CellClickEvent,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  useDataTable,
  useDialog,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import { IoSend } from 'react-icons/io5';
import LinkReportPanel from '../../components/link-report-panel/link-report-panel';

const LinkReportsPage: React.FC = () => {
  const panel = usePanel();
  const { setLoading } = useLoading();
  const title = 'External Link Reports';

  const user = useAppSelector(selectUser);
  const [resendExternalReport] = useResendBacklinkReportMutation();
  const [deleteReportTrigger] = useDeleteBacklinkReportMutation();

  const {
    handleOnPageChange,
    handleSortByColumnClick,
    handleSearchChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
  } = useDataTable<BacklinkReport>({});

  const {
    data: backlinkReports,
    refetch: refetchbacklinkReports,
    isLoading,
  } = useFetchBacklinkReportsByOptionsQuery(
    {
      userId: user?.id?.toString() ?? '',
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  console.log('BACK link report', backlinkReports);

  const [deleteBacklinkReportTrigger] = useDeleteBacklinkReportMutation();

  const handleResend = async (id: string) => {
    try {
      setLoading(true);
      await resendExternalReport(id ?? '');
      toast({
        title: 'Report resent',
        description: `We've resent your report to the registered email`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    } catch (error) {
      toast({
        title: `Report resend issue`,
        description: `We've run into an error resending your report. Error = ${error}`,
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (backlinkReports?.pagination) {
      console.log('dashboardDomains?.pagination ', backlinkReports?.pagination);
      setPagination(backlinkReports?.pagination);
    }
  }, [backlinkReports]);

  const toast = useToast();
  const dialog = useDialog();

  const handleViewBacklinksPanel = async (item: BacklinkReport) => {
    panel({
      title: 'External Report',
      size: 'full',
      render: () => {
        return <LinkReportPanel item={item} />;
      },
    });
  };

  const handleReportDelete = async (report: BacklinkReport) => {
    try {
      await deleteBacklinkReportTrigger(report.id);
      toast({
        title: 'Report Deleted',
        description: "We've deleted the Backlink Report you selected",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Report Delete Error',
        description:
          "We've run into a problem deleting the selected backlink report. Contact support for help",
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const reportColumns: ColumnDefinitionType<BacklinkReport>[] = [
    {
      key: 'custom',
      header: 'Domain Name',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return <AppText>{item.domain?.name}</AppText>;
      },
    },
    {
      type: ColumnSpecialTypes.date,
      key: 'expiryDate',
      header: 'Expiry Date',
      headerSortable: true,
      columnSearchable: false,
    },
    {
      key: 'emailAddress',
      header: 'Email Address',
      headerSortable: false,
      columnSearchable: true,
    },

    {
      key: 'custom',
      header: 'Tag',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return item.tag ? (
          <Tag borderRadius="full" colorScheme={'gray'}>
            <TagLabel>{item.tag?.name}</TagLabel>
          </Tag>
        ) : (
          <></>
        );
      },
    },
    {
      key: 'custom',
      header: 'Backlinks',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return (
          // <Box>
          //   <Tooltip
          //     label={
          //       <Flex flexDirection={'column'}>
          //         {item.backlinkIds.map((id) => (
          //           <AppText p="2" key={id}>
          //             {getBacklinkUrl(id)}
          //           </AppText>
          //         ))}
          //       </Flex>
          //     }
          //   >
          //     <span>

          //     </span>
          //   </Tooltip>
          // </Box>
          <Tag
            cursor={'pointer'}
            borderRadius="full"
            colorScheme={'gray'}
            onClick={() => handleViewBacklinksPanel(item)}
          >
            <TagLabel>{item.backlinks && item.backlinks.length}</TagLabel>
          </Tag>
        );
      },
    },
    {
      key: 'otpUsedCount',
      header: 'Otp Used Count',
      headerSortable: true,
      columnSearchable: false,
    },
    {
      key: 'custom',
      header: 'Actions',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return (
          <Flex dir={'row'} align={'center'} gap={2}>
            <Tooltip label={<AppText>View report</AppText>}>
              <div>
                <AppButton
                  size={'sm'}
                  border={`1px solid ${AppColors.appBorder}`}
                  borderRadius={'full'}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}
                  onClick={() => {
                    handleViewBacklinksPanel(item);
                  }}
                >
                  <ViewIcon />
                </AppButton>
              </div>
            </Tooltip>
            <Tooltip label={<AppText>Resend report</AppText>}>
              <div>
                <AppButton
                  size={'sm'}
                  border={`1px solid ${AppColors.appBorder}`}
                  borderRadius={'full'}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}
                  onClick={() => {
                    handleResend(item.id);
                  }}
                  isDisabled={!item.otpUsedCount}
                >
                  <IoSend />
                </AppButton>
              </div>
            </Tooltip>
            <Tooltip label={<AppText>Delete report</AppText>}>
              <div>
                <AppButton
                  mr={2}
                  size={'sm'}
                  border={`1px solid ${AppColors.appBorder}`}
                  borderRadius={'full'}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}
                  onClick={() => {
                    handleReportDelete(item);
                  }}
                >
                  <DeleteIcon />
                </AppButton>
              </div>
            </Tooltip>
          </Flex>
        );
      },
    },
  ];

  const [tableColumns, setTableColumns] =
    useState<ColumnDefinitionType<BacklinkReport>[]>(reportColumns);
  const [selectedReports, setSelectedReports] = useState<BacklinkReport[]>([]);

  const handleTableCellClick = async (clickEvent: CellClickEvent<BacklinkReport>) => {};

  const handleTableRowSelectedChange = (selection: BacklinkReport[]) => {
    setSelectedReports(selection);
  };

  const handleDeleteBacklinkClicked = () => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selectedReports.length} selected External Link reports?`}
            onConfirm={async () => {
              onSubmit();

              await handleDelete();
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDelete = async () => {
    try {
      const deletePromises = selectedReports.map((report) => {
        if (report && report.id) deleteBacklinkReportTrigger(report.id).unwrap();
      });

      await Promise.all(deletePromises);
    } catch (e) {
      toast({
        title: 'Report Delete error.',
        description:
          "We've run into a problem deleting the selected reports, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected External Reports deleted.',
      description: "We've deleted the external reports you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    refetchbacklinkReports();
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex px={6} py={2} flex={1} direction="column">
      {isLoading && <AppLoader />}
      <Flex
        justifyContent={'space-between'}
        align={'center'}
        borderRadius={'2xl'}
        bgColor={'white'}
        p={4}
        mt={4}
      >
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mb="4px">
          {title}
        </Text>
        <Flex alignItems={'center'}>
          <TagsActionsMenu
            isDisabled={selectedReports.length === 0}
            onDeleteTags={handleDeleteBacklinkClicked}
          />
        </Flex>
      </Flex>

      <Flex py={6} flex={1}>
        <Flex
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          padding={'4'}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={backlinkReports?.data || []}
            noDataMessage={'No external link reports added'}
            columns={tableColumns}
            selectableRows
            onRowSelected={handleTableRowSelectedChange}
            onTableCellClick={handleTableCellClick}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LinkReportsPage;
