import React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingComponent, PlatformExternalTypes } from '@backlinkit/shared';
import { useFetchPlatformExternalKeyByIdQuery } from '../../store/api/verifyApi';
import ExternalReportOtpVerificationPage from './external-report-otp-verify';
import AccountSignupOtpVerificationPage from './account-signup-otp-verify';

const ExternalVerifyPage: React.FC = () => {
  // const navigate = useNavigate();
  const { id } = useParams();
  const { data: externalItem, isFetching } = useFetchPlatformExternalKeyByIdQuery(id!);

  // useEffect(() => {
  //   if (externalItem) {
  //     switch (externalItem.type) {
  //       case PlatformExternalTypes.BACKLINKEXTERNALREPORT:
  //         navigate(`external-reports/${externalItem.externalKey}`);
  //         break;

  //       default:
  //         break;
  //     }
  //   }
  // }, [externalItem]);

  const renderComponent = () => {
    if (externalItem) {
      switch (externalItem.type) {
        case PlatformExternalTypes.BACKLINKEXTERNALREPORT:
          return <ExternalReportOtpVerificationPage id={externalItem.externalKey} />;
        case PlatformExternalTypes.ORGANIZATIONVERIFICATION:
          return <AccountSignupOtpVerificationPage id={externalItem.externalKey} />;
        default:
          break;
      }
    }
    return <LoadingComponent />; // 404 page needed here!
  };

  return <>{isFetching ? <LoadingComponent /> : renderComponent()}</>;
};
export default ExternalVerifyPage;
