import { jwtDecode } from 'jwt-decode';

export const isAuthTokenValid = (userAuthToken?: string) => {
  if (!userAuthToken) {
    return false;
  }

  const jwt = jwtDecode(userAuthToken);

  if (!jwt || Date.now() >= (jwt.exp || 0) * 1000) {
    return false;
  }

  return true;
};
