import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { BacklinkBatchTag, BacklinkBatchTagForm } from '@backlinkit/shared';

export const backlinkBatchTagApi = createApi({
  reducerPath: 'backlinkBatchTagApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    createBacklinkTag: build.mutation<BacklinkBatchTag, BacklinkBatchTagForm>({
      query: (payload) => ({
        url: `/backlinkbatchtag/create`,
        method: 'POST',
        body: payload,
      }),
    }),
    deleteBacklinkTag: build.mutation<string, string>({
      query: (id) => ({
        url: `/backlinkbatchtag/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useCreateBacklinkTagMutation, useDeleteBacklinkTagMutation } = backlinkBatchTagApi;
