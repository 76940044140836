import React, { useState } from 'react';
import { useDisclosure, Text, Flex, Image, Box } from '@chakra-ui/react';
import * as yup from 'yup';
import { BaseFormProps } from './base-forms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import logo from '../../assets/backlinkit_logo.svg';
import rightArrow from '../../assets/right-arrow.svg';
import { AppButton, AppColors, AppInput } from '@backlinkit/shared';

export interface OTPFormData {
  otp?: number;
}

const otpFormDataSchema = yup.object({
  otp: yup.number().required('Field is required'),
});

const otpDefaultValues: OTPFormData = {
  otp: undefined,
};

type OTPFormProps<T> = BaseFormProps<T>;

const OTPForm: React.FC<OTPFormProps<OTPFormData>> = ({ isLoading, onSubmit }, pageUrl: string) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<OTPFormData>({
    defaultValues: otpDefaultValues,
    resolver: yupResolver(otpFormDataSchema),
    mode: 'all',
  });
  const { onClose } = useDisclosure({ defaultIsOpen: true });

  const handleFormSubmit = (formData: OTPFormData) => {
    onSubmit(formData);
    onClose();
  };

  return (
    <>
      <Flex
        minH={'max-content'}
        w={{ md: '380px' }}
        fontWeight={'medium'}
        p={8}
        flexDir={'column'}
        gap={12}
        bg={'white'}
        borderRadius={'12px'}
        boxShadow={'lg'}
      >
        <Image src={logo} objectFit={'cover'} />
        <Text alignSelf={'center'} fontSize={'2xl'}>
          Enter OTP
        </Text>
        <AppInput<OTPFormData>
          control={control}
          inputType="number"
          name="otp"
          label=""
          error={errors.otp}
        />
        <AppButton
          isDisabled={!isValid}
          loadingText="Checking OTP"
          isLoading={isLoading}
          onClick={handleSubmit(handleFormSubmit)}
          gap={3}
        >
          Enter
          <Box
            boxSize={'22px'}
            borderRadius={'100%'}
            bg={AppColors.iconColorOne}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Image w={'14px'} src={rightArrow} />
          </Box>
        </AppButton>
      </Flex>
    </>
  );
};

export default OTPForm;
