import { useState } from 'react';

import { SortParam, SearchParam } from '../models/base';
import { PageChangeEvent, Pagination } from '../models/shared';
import {
  ColumnDefinitionType,
  TableSearchEventProps,
} from '../components/app-data-table/app-data-table-types';
import {
  searchingColumnsToSearchParam,
  sortingColumnsToSortingArray,
} from '../models/searchHelpers';

type useDataTableProps = {
  defaultPageSize?: number;
};

export const useDataTable = <T extends {}>({ defaultPageSize }: useDataTableProps) => {
  const [currentSortList, setCurrentSortList] = useState<SortParam[]>([]);
  const [currentSearchList, setCurrentSearchList] = useState<SearchParam>();
  const [selected, setSelected] = useState<T[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [currentPage, setCurrentPage] = useState<number | 1>();
  const [currentPageSize, setCurrentPageSize] = useState<number>(
    defaultPageSize ? defaultPageSize : 25
  );

  const handleSearchChange = (event: TableSearchEventProps<T>) => {
    const searchObjects = searchingColumnsToSearchParam(event);
    setCurrentSearchList(searchObjects);
  };

  const handleSortByColumnClick = (columns: ColumnDefinitionType<T>[]) => {
    const columnSortQueryString = sortingColumnsToSortingArray(columns);
    setCurrentSortList(columnSortQueryString);
  };

  const handleTableRowsSelectedChange = (selection: T[]) => {
    setSelected(selection);
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    console.log(pageChangeEvent);
    setCurrentPage(pageChangeEvent.currentPage);
    setCurrentPageSize(pageChangeEvent.currentPageSize);
  };

  const nextPaginationQueryParams = {
    page: currentPage ?? 1,
    pageSize: currentPageSize ?? defaultPageSize ? defaultPageSize : 25,
    sortList: currentSortList,
    searchList: currentSearchList,
  };

  return {
    handleSearchChange,
    handleSortByColumnClick,
    handleTableRowsSelectedChange,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
    selected,
  };
};
