import { NavItem } from '@backlinkit/shared';

export const HeaderNavItems: Array<NavItem> = [
  {
    label: 'Dashboard',
    href: '/dashboard',
    isAdmin: true,
  },
  {
    label: 'Link Batches',
    href: '/batches',
    isAdmin: false,
  },
  {
    label: 'Google Checker',
    href: '/google-checker',
    isAdmin: true,
  },
  {
    label: 'Account',
    href: '/account',
    isAdmin: true,
  },
  {
    label: 'External Reports',
    href: '/links/reports',
    isAdmin: false,
  },
  {
    label: 'Tags',
    href: '/tags',
    isAdmin: false,
  },
];
