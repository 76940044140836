import React from 'react';
import { Flex, Alert, Box } from '@chakra-ui/react';
import { AppText } from '../app-text/app-text';
import { CloseIcon } from '@chakra-ui/icons';
import { Notification } from '../../models';

export type AppUserNotificationProps = {
  item: Notification;
  showName?: boolean;
  showType?: boolean;
  itemsClearable?: boolean;
  onItemClearClick: (item: Notification) => void;
  onItemClick?: (item: Notification) => void;
};

export const AppUserNotification: React.FC<AppUserNotificationProps> = ({
  item,
  showName = true,
  showType = true,
  itemsClearable = false,
  onItemClearClick,
  onItemClick,
}) => {
  const handleClearAlertClick = (item: Notification) => {
    itemsClearable && onItemClearClick && onItemClearClick(item);
  };

  const getAlertType = (item: Notification) => {
    switch (item.notificationType) {
      case 0:
        return 'info';
      case 1:
        return 'warning';
      case 2:
        return 'error';
      default:
        return 'info';
    }
  };

  return (
    <Alert justifyContent="space-between" alignItems="start" mb={2} status={getAlertType(item)} onClick={() => onItemClick}>
      <Flex direction={'column'}>
        {showName && (
          <Box>
            <Flex direction={'row'} justifyContent={'start'} alignItems="center">
              <AppText mr={2} fontWeight={'bold'}>
                {item.title}
              </AppText>
            </Flex>
          </Box>
        )}

        {showType && (
          <Box mt={showName ? 2 : 0}>
            <Flex direction={'row'} justifyContent={'start'} alignItems="center">
              <AppText>{item.description}</AppText>
            </Flex>
          </Box>
        )}

        {/* {item.title && (
          <Box mt={showName ? 2 : 0}>
            <Flex direction={'row'} justifyContent={'start'} alignItems="center">
              <AppText>{item.title}</AppText>
            </Flex>
          </Box>
        )} */}

        {item.dateCreated && (
          <Box mt={showName ? 2 : 0}>
            <Flex direction={'row'} justify={'start'} align={'center'}>
              <AppText>{item.dateCreated.toString().slice(0, 10)}</AppText>
            </Flex>
          </Box>
        )}
      </Flex>
      {itemsClearable && (
        <Flex>
          <CloseIcon
            onClick={() => handleClearAlertClick(item)}
            width={'10px'}
            height={'10px'}
            _hover={{
              cursor: 'pointer',
            }}
          />
        </Flex>
      )}
    </Alert>
  );
};
