import { AppColors } from '@backlinkit/shared';
import { Box, Flex, keyframes, SystemStyleObject, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
export interface StatusIndicatorProps {
  status?: string;
  overrideColor?: string;
  pulse?: boolean;
  label?: string;
}

// export enum StatusIndicatorStatusEnum {
//   active = 'green.400',
//   error = 'red.600',
//   inActive = 'gray.400',
// }

const ringScaleMin = 0.33;
const ringScaleMax = 0.66;

const pulseRing = keyframes`
	0% {
    transform: scale(${ringScaleMin});
  }
	30% {
		transform: scale(${ringScaleMax});
	},
  40%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
	`;
const pulseDot = keyframes`
	0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.9);
  }
	`;

const after: SystemStyleObject = {
  animation: `2.25s ${pulseDot} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
};

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  status,
  overrideColor,
  pulse = false,
  label,
}) => {
  const [indicatorStatus, setIndicatorStatus] = useState<string | undefined>(undefined);

  useEffect(() => {
    initIndicator();
  }, [status]);

  const initIndicator = () => {
    if (status && !overrideColor) {
      setIndicatorStatus(status);
    } else if (overrideColor && overrideColor.length > 0) {
      setIndicatorStatus(overrideColor);
    } else {
      setIndicatorStatus(AppColors.primary);
    }
  };

  const before: SystemStyleObject = {
    content: "''",
    position: 'relative',
    display: 'block',
    width: '300%',
    height: '300%',
    boxSizing: 'border-box',
    marginLeft: '-100%',
    marginTop: '-100%',
    borderRadius: '50%',
    bgColor: indicatorStatus,
    animation: `2.25s ${pulseRing} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <Tooltip label={label} textTransform="capitalize">
        <Box
          as="div"
          h="16px"
          w="16px"
          position="relative"
          bgColor={indicatorStatus}
          borderRadius="50%"
          _before={pulse ? before : undefined}
          _after={pulse ? after : undefined}
        />
      </Tooltip>
    </Flex>
  );
};

export default StatusIndicator;
