import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Notification } from '@backlinkit/shared';
import { notificationApi } from '../api/notificationsApi';

interface UserState {
  userNotifications: Notification[];
}

const initialState: UserState = {
  userNotifications: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationApi.endpoints.fetchNotificationByUser.matchFulfilled,
      (state, { payload }) => {
        return { ...state, userNotifications: payload };
      }
    );
  },
  reducers: {},
});

export const {} = userSlice.actions;

export const selectUserNotifications = (state: RootState) => state.user.userNotifications;
