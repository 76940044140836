import { Flex } from '@chakra-ui/react';
import { AppPlayer, AppText } from '@backlinkit/shared';

const TrainingAreaDialog: React.FC = () => {
  return (
    <Flex p={4} flexDir={'column'} w={'100%'} rounded={'md'} bgColor={'white'}>
      <AppPlayer
        height="100%"
        width="100%"
        media="/training-videos/Backlinkit - TrainingArea.mp4"
      />
    </Flex>
  );
};

export default TrainingAreaDialog;
