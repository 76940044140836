import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { Box, Image } from '@chakra-ui/react';

export type AppPlayerProps = {
  media: string;
  controls?: boolean;
  width?: string;
  height?: string;
  thumbnailUrl?: string;
  playerRef?: any;
} & ReactPlayerProps;

export const AppPlayer: React.FC<AppPlayerProps> = ({
  media,
  controls = true,
  thumbnailUrl,
  playerRef,
  width,
  height,
}) => {
  return (
    <Box w={'100%'} pos={'relative'} objectFit={'cover'}>
      <ReactPlayer
        url={media}
        controls={controls}
        light={
          thumbnailUrl && (
            <Image
              src={thumbnailUrl}
              objectFit={'cover'}
              objectPosition={'center'}
              alt="Thumbnail"
            />
          )
        }
        ref={playerRef}
      />
    </Box>
  );
};
