import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { PlatformExternalKeyDto } from '@backlinkit/shared';

export const verifyApi = createApi({
  reducerPath: 'verifyApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchPlatformExternalKeyById: build.query<PlatformExternalKeyDto, string>({
      query: (id) => ({
        url: `/platformexternalkey/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: PlatformExternalKeyDto) => {
        return response;
      },
    }),
  }),
});

export const { useFetchPlatformExternalKeyByIdQuery } = verifyApi;
