import { Icon, Tag, TagLabel, Tooltip, Flex } from '@chakra-ui/react';
import { BiNotepad } from 'react-icons/bi';
import { AppText, BacklinkBatch } from '@backlinkit/shared';

type BatchNoteCellProps = {
  batch?: BacklinkBatch;
};

const BatchNoteCell: React.FC<BatchNoteCellProps> = ({ batch }) => {
  if (batch && batch.notes && batch.notes.length > 0) {
    return (
      <Flex justify={'center'}>
        <Tooltip label={<AppText>{batch.notes}</AppText>}>
          <div>
            <Icon as={BiNotepad} />
          </div>
        </Tooltip>
      </Flex>
    );
  } else {
    return (
      <Flex justify={'center'}>
        <Tag borderRadius={'full'}>
          <TagLabel>-</TagLabel>
        </Tag>
      </Flex>
    );
  }
};

export default BatchNoteCell;
