import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { OrganizationPaymentDto, OrganizationPaymentForm } from '@backlinkit/shared';

export const organizationPaymentApi = createApi({
  reducerPath: 'organizationPaymentApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchOrganizationPaymentById: build.query<OrganizationPaymentDto, string>({
      query: (id) => ({
        url: `/organizationpayment/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: OrganizationPaymentDto) => {
        const tagsData = response;
        return tagsData;
      },
    }),
    createOrganizationPayment: build.mutation<OrganizationPaymentDto, OrganizationPaymentForm>({
      query: (payload) => ({
        url: `/organizationpayment/create`,
        method: 'POST',
        body: payload,
      }),
    }),
    editOrganizationPayment: build.mutation<OrganizationPaymentDto, OrganizationPaymentDto>({
      query: (payload) => ({
        url: `/organizationpayment/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteOrganizationPayment: build.mutation<OrganizationPaymentDto, string>({
      query: (tagId) => ({
        url: `/organizationpayment/delete/${tagId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchOrganizationPaymentByIdQuery,
  useLazyFetchOrganizationPaymentByIdQuery,
  useCreateOrganizationPaymentMutation,
  useEditOrganizationPaymentMutation,
  useDeleteOrganizationPaymentMutation,
} = organizationPaymentApi;
