import React from 'react';
import { Flex, Select } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { useState } from 'react';
import { AppButton, AppInput, OrganizationRoleType } from '@backlinkit/shared';

export interface UserCreateFormData {
  email: string;
  password: string;
  organizationRoleId: OrganizationRoleType;
}

export const UserCreateFormDataDefaultValues: UserCreateFormData = {
  email: '',
  password: '',
  organizationRoleId: OrganizationRoleType.BACKLINKER,
};

const UserCreateFormDataSchema = yup.object({
  email: yup.string().email().required('Field is required'),
  password: yup.string().required('Field is required'),
  organizationRoleId: yup.number(),
});

type UserCreateFormProps<T> = {
  form?: UserCreateFormData;
} & BaseFormProps<T>;

const UserCreateForm: React.FC<UserCreateFormProps<UserCreateFormData>> = ({ form, onSubmit }) => {
  const {
    control: UserCreateControl,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm<UserCreateFormData>({
    defaultValues: form || UserCreateFormDataDefaultValues,
    resolver: yupResolver(UserCreateFormDataSchema),
    mode: 'onChange',
  });

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = +e.currentTarget.value;
    setValue('organizationRoleId', selectedValue);
  };
  return (
    <Flex dir="column" w={'full'}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          width: '100%',
        }}
      >
        <AppInput<UserCreateFormData>
          control={UserCreateControl}
          name="email"
          label="Email"
          placeHolder="Email"
          error={errors.email}
        />
        <AppInput<UserCreateFormData>
          control={UserCreateControl}
          name="password"
          label="Password"
          placeHolder="Password"
          error={errors.password}
        />
        <Select aria-label="User Role" mt={'15px'} onChange={onSelectChange}>
          <option value={OrganizationRoleType.BACKLINKER}>Backlinker</option>
          <option value={OrganizationRoleType.ADMIN}>Admin</option>
        </Select>

        <AppButton w={'full'} mt={8} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Save
        </AppButton>
      </form>
    </Flex>
  );
};

export default UserCreateForm;
