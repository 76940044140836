import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { OrganizationCompetitor, OrganizationExludeLink } from '@backlinkit/shared';
import { organizationCompetitorApi } from '../api/organization-competitorsApi';
import { organizationExcludeLinkApi } from '../api/organizationExludeLinkApi';

interface UserState {
  organizationCompetitors: OrganizationCompetitor[];
  organizationExludeLinks: OrganizationExludeLink[];
}

const initialState: UserState = {
  organizationCompetitors: [],
  organizationExludeLinks: [],
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      organizationCompetitorApi.endpoints.fetchAllOrganizationCompetitors.matchFulfilled,
      (state, { payload }) => {
        return { ...state, organizationCompetitors: payload };
      }
    );
    builder.addMatcher(
      organizationExcludeLinkApi.endpoints.fetchAllOrganizationExcludeLinks.matchFulfilled,
      (state, { payload }) => {
        return { ...state, organizationExludeLinks: payload };
      }
    );
  },
  reducers: {},
});

export const {} = organizationSlice.actions;

export const selectOrganizationCompetitors = (state: RootState) =>
  state.organization.organizationCompetitors;

export const selectOrganizationExludeLinks = (state: RootState) =>
  state.organization.organizationExludeLinks;
