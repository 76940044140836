import { Box } from '@chakra-ui/react';
import DomainLayout from '../../components/layouts/domain-layout';
import AppLinkChecker from '../../components/app/app-link-checker/app-link-checker';

export default function DomainLinkChecker() {
  return (
    <DomainLayout>
      <Box pl={'20px'} pr={'20px'} w="100%">
        <AppLinkChecker h={'100%'} w={'full'}></AppLinkChecker>
      </Box>
    </DomainLayout>
  );
}
