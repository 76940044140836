import React from 'react';
import { Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';
import { AppButtonAs, AppButton } from '@backlinkit/shared';

export interface DomainActionsMenuProps {
  isDisabled: boolean;
  onRefreshDomains?: () => void;
  onDeleteDomains?: () => void;
}

const DomainActionsMenu: React.FC<DomainActionsMenuProps> = ({
  isDisabled,
  onDeleteDomains,
  onRefreshDomains,
}) => {
  const handleDomainsRefresh = () => {
    onRefreshDomains && onRefreshDomains();
  };

  const handleDomainsDelete = () => {
    onDeleteDomains && onDeleteDomains();
  };

  return (
    <Menu>
      <MenuButton
        size={'sm'}
        borderRadius={'full'}
        mr={4}
        as={AppButtonAs}
        bg={'white'}
        rightIcon={<ChevronDownIcon />}
        gap={2}
      >
        Actions
      </MenuButton>
      <MenuList>
        <Flex flexDirection={'column'} px={4} py={2} gap={2}>
          <AppButton
            isDisabled={isDisabled}
            onClick={handleDomainsDelete}
            leftIcon={<DeleteIcon />}
          >
            Delete
          </AppButton>
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default DomainActionsMenu;
