import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Text,
  Portal,
  useToast,
  Box,
  Icon,
} from '@chakra-ui/react';
import { IoMdRefreshCircle } from 'react-icons/io';
import LinksFilter from '../app/app-data-table/filters/links-filter';
import LastModifiedCell from './components/cells/last-modified-cell';
import LinkTypeCell from './components/cells/link-type-cell';
import LinkStatusCell from './components/cells/link-status-cell';
import { ErrorCountCell } from './components/cells/error-count-cell';
import UrlCell from './components/cells/url-cell';
import LandinPageCell from './components/cells/landing-page-cell';
import AnchorCell from './components/cells/anchor-cell';
import GoogleIndexCell from './components/cells/google-index-cell';
import GoogleCacheCell from './components/cells/google-cache-cell';
import RelCell from './components/cells/rel-cell';
import IpCell from './components/cells/ip-cell';
import TldCell from './components/cells/tld-cell';
import EflCell from './components/cells/efl-cell';
import { ChevronDownIcon, SettingsIcon } from '@chakra-ui/icons';
import { GoNote } from 'react-icons/go';
import { GoogleActionType } from '../../constants/googleActionTypes';
import BackLinkNoteForm from '../forms/backlink-note-form';
import {
  AppButton,
  AppColors,
  AppDataTable,
  AppText,
  BackLink,
  BackLinkListItem,
  BacklinkFilter,
  BacklinkFormData,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  DomainListItem,
  LinkStatus,
  RelStatus,
  ScrapeError,
  SearchParam,
  TableSearchEventProps,
  searchingColumnsToSearchParam,
  useDialog,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import { FcGoogle } from 'react-icons/fc';
import { ReactNode, useState } from 'react';
import BacklinkActionsMenu from '../app/app-menus/backlink-actions-menu';
import { format } from 'date-fns';
import LastScrapeCell from './components/cells/last-scrape-cell';
import BacklinkCreateFormsWrapper from '../forms/backlink-create-forms-wrapper';
import { useEditBacklinkMutation } from '../../store/api/backlinksApi';
import BackLinkManualForm, { BacklinkManualFormData } from '../forms/backlink-manual-form';
import LinkNotesCell from './components/cells/link-notes-cell';
import DifferenceCountCell from './components/cells/difference-cell';
import BacklinkBatchCell from './components/cells/backlink-batch-cell';
import BackLinkEditForm from '../forms/backlink-edit-form';
import BackLinkPriceForm from '../forms/backlink-price-form';
import { useAppSelector } from '../../store/store';
import {
  selectOrganizationExludeLinks,
  selectOrganizationCompetitors,
} from '../../store/slices/organization.slice';
import { userApi } from '../../store/api/userApi';
import { selectUser } from '../../store/slices/authentication.slice';
const { JSONtoCSVConverter } = require('react-json-csv-convert');

export type BacklinkMenuItems = {
  icon: any;
  onClick: (item: BackLinkListItem) => void;
  label: string;
};

type BacklinksTableProps = {
  currentDomain: DomainListItem;
  title: string;
  parentUrl: string;
  data: BackLinkListItem[];
  tempBacklinkCount?: number;
  tempGoogleDataCount?: number;
  additionalBacklinkColumns: ColumnDefinitionType<BackLinkListItem>[];
  additionalMenuItems: BacklinkMenuItems[];
  isBatchCreateDisabled: boolean;
  onAddClick: (
    backlinkFormsData: BacklinkFormData[],
    currentDomain: DomainListItem,
    batchPrice?: number,
    batch?: string
  ) => void;
  onEditBacklink: (
    backlinkForm: BacklinkFormData,
    currentDomain: DomainListItem,
    isNoteEdit?: boolean,
    isPriceEdit?: boolean
  ) => void;
  onSearchChange?: (searchParams: SearchParam) => void;
  onSortByColumnClick?: (columns: ColumnDefinitionType<BackLinkListItem>[]) => void;
  onFiltersChange: (params: BacklinkFilter) => void;
  onRowSelectedChange?: (selection: BackLinkListItem[]) => void;
  onSelectedBacklinksRescrape: (selection: BackLinkListItem[]) => void;
  onSelectedBacklinksGoogleRescrape: (selection: BackLinkListItem[]) => void;
  onSelectedBacklinksDelete: (selection: BackLinkListItem[]) => void;
  onSelectedBacklinksExternalReport: (selection: BackLinkListItem[]) => void;
  onBackButtonClick: () => void;
  refetch: () => void;
  batchId?: string;
  children: ReactNode;
};

const BacklinksTable: React.FC<BacklinksTableProps> = ({
  currentDomain,
  title,
  parentUrl,
  data = [],
  tempBacklinkCount,
  tempGoogleDataCount,
  additionalBacklinkColumns,
  additionalMenuItems,
  onAddClick,
  onEditBacklink,
  onRowSelectedChange,
  onSearchChange,
  onSortByColumnClick,
  onFiltersChange,
  onSelectedBacklinksRescrape,
  onSelectedBacklinksGoogleRescrape,
  onSelectedBacklinksDelete,
  onSelectedBacklinksExternalReport,
  refetch,
  onBackButtonClick,
  batchId,
  children,
}) => {
  const dialog = useDialog();
  const panel = usePanel();
  const { setLoading } = useLoading();
  const toast = useToast();
  const [editBacklinkTrigger] = useEditBacklinkMutation();
  const [currentSearchParam, setCurrentSearchParam] = useState<SearchParam>();
  const [selectedBacklinks, setSelectedBacklinks] = useState<BackLinkListItem[]>([]);

  const user = useAppSelector(selectUser);

  const handleAddDialog = () => {
    panel({
      title: 'Create Backlink',
      size: 'xl',
      render: (onSubmit) => {
        return (
          <BacklinkCreateFormsWrapper
            domainId={currentDomain?.id ?? ''}
            isBatchCreate={batchId ? true : false}
            onSubmit={(data, batch, batchPrice) => {
              onSubmit();
              onAddClick(data, currentDomain, batchPrice, batch);
            }}
          ></BacklinkCreateFormsWrapper>
        );
      },
    });
  };

  const getLinkEditRelValue = (backlink: BackLinkListItem) => {
    const relValues: string[] = backlink.backlinkMeta?.relValue?.split(' ') ?? [];

    if (backlink.backlinkMeta?.relStatus === RelStatus.follow) {
      relValues.push('follow');
    } else {
      relValues.push('nofollow');
    }

    return relValues.join(',');
  };

  const handleEditDialog = (backlink: BackLinkListItem, isNote: boolean = false) => {
    const backlinkForm: BacklinkFormData = {
      id: backlink.id,
      url: backlink.url,
      price: backlink.price ? +backlink.price : undefined,
      priceCurrency: backlink.currency,
      expectedLandingPage: backlink.expectedLandingPage,
      expectedAnchor: backlink.expectedAnchor,
      notes: backlink.notes,
      expectedRel: backlink.expectedRelValue,
    };
    panel({
      title: isNote ? 'Edit Backlink Note' : 'Edit Backlink',
      size: 'xl',
      render: (onSubmit) => {
        if (isNote) {
          return (
            <BackLinkNoteForm
              form={backlinkForm}
              onSubmit={(formData) => {
                setLoading(true);
                onEditBacklink(formData, currentDomain, true);
                onSubmit();
                setLoading(false);
              }}
            ></BackLinkNoteForm>
          );
        }

        return (
          <BackLinkEditForm
            form={backlinkForm}
            backlink={backlink}
            onSubmit={(formData) => {
              onEditBacklink(formData, currentDomain);
              onSubmit();
            }}
          ></BackLinkEditForm>
        );
      },
    });
  };

  const handleEditPriceDialog = (backlink: BackLinkListItem) => {
    const backlinkForm: BacklinkFormData = {
      id: backlink.id,
      url: backlink.url,
      price: backlink.price ? +backlink.price : undefined,
      priceCurrency: backlink.currency,
      expectedLandingPage: backlink.expectedLandingPage,
      expectedAnchor: backlink.expectedAnchor,
      notes: backlink.notes,
      expectedRel: backlink.expectedRelValue,
    };
    panel({
      title: 'Edit Backlink Price',
      size: 'xl',
      render: (onSubmit) => {
        return (
          <BackLinkPriceForm
            form={backlinkForm}
            onSubmit={(formData) => {
              onEditBacklink(formData, currentDomain, false, true);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleGoogleActionCellClick = async (item: BackLinkListItem, type: GoogleActionType) => {
    switch (type) {
      case GoogleActionType.index:
        window.open(`https://www.google.com/search?q=site:${item.url}`, 'blank');
        break;
      case GoogleActionType.cache:
        window.open(`http://webcache.googleusercontent.com/search?q=cache:${item.url}`, 'blank');
        break;
      case GoogleActionType.mobile:
        window.open(`https://search.google.com/test/mobile-friendly?url=${item.url}`, 'blank');
        break;
      default:
        break;
    }
  };

  const handleSearchChange = (event: TableSearchEventProps<BackLinkListItem>) => {
    const searchObjects = searchingColumnsToSearchParam(event);
    if (searchObjects) {
      setCurrentSearchParam(searchObjects);
      onSearchChange && onSearchChange(searchObjects);
    }
  };

  const handleFiltersChange = (backlinkFilter: BacklinkFilter) => {
    onFiltersChange(backlinkFilter);
  };

  const handleTableRowsSelectedChange = (selection: BackLinkListItem[]) => {
    setSelectedBacklinks(selection);
    onRowSelectedChange && onRowSelectedChange(selection);
  };

  const setupExportLoader = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleBacklinkAlertsCleared = () => {
    refetch && refetch();
  };

  const handleExportCsvBacklinkClicked = () => {
    const exportData = selectedBacklinks?.map((backLink) => {
      return {
        domain: currentDomain?.url || '',
        url: backLink.url || '',
        landingPage: backLink.landingPage || '',
        anchor: backLink.anchor || '',
        relStatus: backLink.backlinkMeta?.relStatus || '',
        dateAdded: backLink.dateCreated || '',
        expectedLandingPage: backLink.expectedLandingPage || '',
        expectedAnchor: backLink.expectedAnchor || '',
        expectedRel: backLink.expectedRelValue || '',
        cacheState: backLink.backlinkGoogle?.cacheState || '',
        hasPageIndex: backLink.backlinkGoogle?.hasPageIndex || '',
        hasDomainIndex: backLink.backlinkGoogle?.hasDomainIndex || '',
        tldCountry: backLink.backlinkMeta?.tldCountry || '',
        tldDomain: backLink.backlinkMeta?.tldDomain || '',
        tldSubDomain: backLink.backlinkMeta?.tldSubDomain || '',
        ip: backLink.backlinkMeta?.ip || '',
        ipCountry: backLink.backlinkMeta?.ipCountry || '',
        externalNoFollowCount: backLink.backlinkMeta?.externalNoFollowCount || '',
        externalFollowCount: backLink.backlinkMeta?.externalFollowCount || '',
        internalFollowCount: backLink.backlinkMeta?.internalFollowCount || '',
      };
    });

    const headers = [
      'DOMAIN',
      'URL',
      'LANDING PAGE',
      'ANCHOR',
      'REL',
      'DATE ADDED',
      'EXPECTED LANDING PAGE',
      'EXPECTED ANCHOR',
      'EXPECTED REL',
      'GOOGLE CACHE',
      'GOOGLE PAGE INDEX',
      'GOOGLE DOMAIN INDEX',
      'TLD COUNTRY',
      'TLD DOMAIN',
      'TLD SUB DOMAIN',
      'IP',
      'IP COUNTRY',
      'EXTERNAL NO FOLLOW COUNT',
      'EXTERNAL FOLLOW COUNT',
      'INTERNAL FOLLOW COUNT',
    ];
    const csvConfig = {
      headers,
      actions: Object.keys(headers).map((x) => null),
      keys: [
        'domain',
        'url',
        'landingPage',
        'anchor',
        'relStatus',
        'dateAdded',
        'expectedLandingPage',
        'expectedAnchor',
        'expectedRel',
        'cacheState',
        'hasPageIndex',
        'hasDomainIndex',
        'tldCountry',
        'tldDomain',
        'tldSubDomain',
        'ip',
        'ipCountry',
        'externalNoFollowCount',
        'externalFollowCount',
        'internalFollowCount',
      ],
    };

    dialog({
      title: 'Export Backlinks',
      render: (onSubmit, onCancel) => {
        return (
          <JSONtoCSVConverter
            csvConfig={csvConfig}
            data={exportData}
            styleProp={{ display: 'inline-block' }}
            fileName={`backlink-export-${format(new Date(), 'dd MMM yyyy')}`}
          >
            <AppButton
              mr="4"
              onClick={() => {
                setupExportLoader();
                onCancel();
              }}
            >
              Download CSV
            </AppButton>
          </JSONtoCSVConverter>
        );
      },
    });
  };

  const handleSetManual = async (backlink: BackLinkListItem) => {
    const setManualBacklink = async (formData: BacklinkManualFormData) => {
      setLoading(true);

      console.log('FormData: ', formData);

      const errors: ScrapeError[] = [];

      const backlinkEditModel: BackLink = {
        ...backlink,
        errors: errors,
        landingPage: formData.landingHref || '',
        anchor: formData.anchorText || '',
        backlinkMeta: {
          ...backlink.backlinkMeta,
          relValue: formData.rel || 'nofollow',
          linkStatus: LinkStatus.Ok,
        },
        isManual: true,
      };

      await editBacklinkTrigger(backlinkEditModel).unwrap();

      toast({
        title: 'Manually Set Backlink.',
        description: 'Backlink has been set manually, it will revert to auto on the 1st',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      setLoading(false);

      refetch && refetch();
    };

    panel({
      title: 'Set Backlink Manually',
      render: (onSubmit, onCancel) => {
        return (
          <BackLinkManualForm
            onSubmit={(formData: BacklinkManualFormData) => {
              setManualBacklink(formData);
              onSubmit();
            }}
          ></BackLinkManualForm>
        );
      },
    });
  };

  const renderDefaultTableActions = (item: BackLinkListItem) => {
    return (
      <Menu>
        <MenuButton
          color={'white'}
          size="xs"
          bgColor={AppColors.secondary}
          as={Button}
          borderRadius="full"
          rightIcon={<ChevronDownIcon />}
        >
          Actions
        </MenuButton>
        <Portal>
          <MenuList zIndex={999}>
            <MenuItem
              icon={<SettingsIcon />}
              onClick={() => {
                handleEditDialog(item);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              icon={<SettingsIcon />}
              onClick={() => {
                handleSetManual(item);
              }}
            >
              Set Manual
            </MenuItem>
            {additionalMenuItems.map((x, index) => (
              <MenuItem
                key={`additional-menu-item-${index}`}
                icon={x.icon}
                onClick={() => {
                  x.onClick(item);
                }}
              >
                {x.label}
              </MenuItem>
            ))}
            <MenuItem
              icon={<FcGoogle />}
              onClick={() => handleGoogleActionCellClick(item, GoogleActionType.index)}
            >
              Open Google Index
            </MenuItem>
            <MenuItem
              icon={<FcGoogle />}
              onClick={() => handleGoogleActionCellClick(item, GoogleActionType.cache)}
            >
              Open Google Cache
            </MenuItem>
            {/* <MenuItem
              icon={<FcGoogle />}
              onClick={() => handleGoogleActionCellClick(item, GoogleActionType.mobile)}
            >
              Open Google Mobile friendly test
            </MenuItem> */}
            <MenuItem
              icon={<GoNote />}
              onClick={() => {
                handleEditDialog(item, true);
              }}
            >
              Notes
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    );
  };

  const backLinkColumns: ColumnDefinitionType<BackLinkListItem>[] = [
    {
      key: 'custom',
      header: 'LM',
      headerMeta: 'Last modified indicates the last date the link was amended and by which user.',
      headerSortable: false,
      render: (item) => <LastModifiedCell item={item} />,
    },
    {
      key: 'custom',
      header: 'RP',
      headerMeta: 'Reprocess your Backlink',
      headerSortable: false,
      render: (item) => (
        <Icon
          onClick={() => onSelectedBacklinksRescrape([item])}
          cursor={'pointer'}
          height={'25px'}
          width={'25px'}
          as={IoMdRefreshCircle}
        />
      ),
    },
    {
      key: 'price',
      header: 'Price',
      // isVisible: false,
      render: (item) => {
        return (
          <Box onClick={() => handleEditPriceDialog(item)} cursor={'pointer'}>
            <AppText>{`${item.currency ?? ''} ${item.price ?? 'No Price'}`}</AppText>
          </Box>
        );
      },
    },
    {
      key: 'backlinkBatchId',
      header: 'Batch',
      isVisible: batchId ? false : true,
      render: (item) => {
        return <BacklinkBatchCell batchId={item.backlinkBatchId} />;
      },
    },
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      headerMeta: 'The date the specific link was added to be monitored.',
      header: 'Date Added',
      headerSortable: true,
    },
    // {
    //   key: 'custom',
    //   header: 'PC',
    //   headerMeta: 'Processed Count - the amount of times the system has processed the backlink',
    //   headerSortable: false,
    //   isVisible: false,
    //   render: (item) => (
    //     <LastScrapeCell updatedDate={item.lastModified} scrapeCount={item.scrapeCount} />
    //   ),
    // },
    {
      key: 'custom',
      header: 'Type',
      headerMeta: 'We track 3 link types; text link, image and redirect.',
      headerSortable: true,
      isVisible: true,
      render: (item) => {
        return <LinkTypeCell linkType={item.backlinkMeta?.linkType} />;
      },
    },
    {
      key: 'custom',
      headerSortable: true,
      header: 'Status',
      headerMeta: 'Indicates the HTTP status code of a link',
      render: (item) => (
        <LinkStatusCell
          isManual={item.isManual}
          linkStatus={item.backlinkMeta?.linkStatus}
          robotsIndexStatus={item.backlinkMeta?.robotsIndexStatus}
          onErrorsCleared={handleBacklinkAlertsCleared}
        />
      ),
    },
    {
      key: 'errorCount',
      header: 'Alerts',
      headerMeta: 'Shows error messages related to the specific link.',
      headerSortable: false,
      render: (item) => (
        <ErrorCountCell item={item} onErrorsCleared={handleBacklinkAlertsCleared} />
      ),
    },
    {
      key: 'errorCount',
      header: 'Link Stats',
      headerMeta: 'Shows differences related to the speciic link.',
      headerSortable: false,
      render: (item) => (
        <DifferenceCountCell
          backlinkUrl={item.url}
          scraperDifference={item.scraperDifference}
          // icon={item.savedDifferences ? FaCheck : MdClose}
          // color={item.savedDifferences ? 'green' : 'red'}
        />
      ),
    },
    {
      key: 'url',
      header: 'Url',
      headerMeta: 'Indicates the referring page your link is located on.',
      headerSortable: true,
      columnSearchable: true,
      cellDataClickable: true,
      render: (item) => <UrlCell url={item.url} />,
    },
    {
      key: 'landingPage',
      headerSortable: true,
      header: 'Landing',
      headerMeta: 'The destination URL your link points to.',
      columnSearchable: true,
      render: (item) => (
        <LandinPageCell
          url={item.url}
          landingPage={item.landingPage}
          backlinkType={item.backlinkType}
          linkStatus={item.backlinkMeta?.linkStatus}
          domainUrl={item.expectedLandingPage ? item.expectedLandingPage : currentDomain?.url ?? ''}
        />
      ),
    },
    {
      key: 'anchor',
      headerSortable: true,
      header: 'Anchor',
      headerMeta:
        'The anchor text for text links, empty anchor for image links and the URL for redirects.',
      columnSearchable: true,
      render: (item) => <AnchorCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Notes',
      headerMeta: 'Your specific notes related to a link',
      cellDataClickable: true,
      render: (item) => (
        <span onClick={() => handleEditDialog(item, true)}>
          <LinkNotesCell item={item} />
        </span>
      ),
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'GI',
      headerMeta:
        'Google index indicator; Green - Page indexed, Orange - Domain indexed - Transparent - Not index',
      cellDataClickable: true,
      render: (item) => (
        <GoogleIndexCell
          url={item.url}
          hasDomainIndex={item.backlinkGoogle?.hasDomainIndex}
          hasPageIndex={item.backlinkGoogle?.hasPageIndex}
          googleCheckDate={item.backlinkGoogle?.lastGoogleCheckDate}
        />
      ),
    },
    {
      key: 'custom',
      headerSortable: false,
      cellDataClickable: true,
      header: 'GC',
      headerMeta: ' Google cache; Google icon - URL is cached, Error icon - Url not cached.',
      render: (item) => (
        <GoogleCacheCell
          url={item.url}
          cacheState={item?.backlinkGoogle?.cacheState}
          cacheDate={item?.backlinkGoogle?.cacheCreatedDate}
          googleCheckDate={item.backlinkGoogle?.lastGoogleCheckDate}
          domainMeta={item.backlinkGoogle?.domainMeta ?? []}
        />
      ),
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Rel',
      headerMeta:
        'The “rel” attribute for the specific link - follow, nofollow (ugc and sponsored).',
      render: (item) => (
        <RelCell relStatus={item.backlinkMeta?.relStatus} relValue={item.backlinkMeta?.relValue} />
      ),
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'IP',
      headerMeta: 'The IP address retrieved from the server the website was served from.',
      render: (item) => <IpCell item={item} />,
    },
    {
      key: 'custom',
      header: 'TLD',
      headerMeta: 'Top level domain - .com, .org, .co, .co.uk (etc.)',
      headerSortable: false,
      render: (item) => <TldCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'EFL',
      headerMeta:
        'External follow links - Outbound links located on the same URL your link is located.',
      render: (item) => (
        <EflCell organizationId={user?.organizationId ?? ''} parentUrl={parentUrl} item={item} />
      ),
    },
    ...additionalBacklinkColumns,
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      headerMeta: 'Additional actions that can be executed for the specific link.',
      cellDataClickable: true,
      render: renderDefaultTableActions,
      stickyColumn: true,
      stickyColumnPosition: 40,
    },
  ];

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const getRefreshText = () => {
    if (tempBacklinkCount && tempBacklinkCount > 0) return 'Sync Backlinks';
    if (tempGoogleDataCount && tempGoogleDataCount > 0) return 'Sync Backlinks';
    return 'Refresh Backlink list';
  };

  return (
    <>
      <Flex justifyContent={'space-between'}>
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
          {title}
        </Text>
        <Flex alignItems={'center'}>
          {!batchId && (
            <AppButton
              onClick={onBackButtonClick}
              borderRadius="full"
              size="sm"
              mr={4}
              variant={'solid'}
            >
              Back
            </AppButton>
          )}

          <Box pos="relative">
            <AppButton
              onClick={() => refetch()}
              borderRadius="full"
              size="sm"
              mr={4}
              variant={'solid'}
            >
              {tempGoogleDataCount && tempGoogleDataCount > 0 ? (
                <Box
                  mt={tempBacklinkCount && tempBacklinkCount > 0 ? '5' : 0}
                  className="button_google__badge"
                >
                  <Icon as={FcGoogle} />
                </Box>
              ) : null}

              {getRefreshText()}

              {tempBacklinkCount && tempBacklinkCount > 0 ? (
                <Box className="button__badge">{tempBacklinkCount}</Box>
              ) : null}
            </AppButton>
          </Box>

          <BacklinkActionsMenu
            isDisabled={selectedBacklinks.length === 0}
            onCreateBacklinkExternalReport={() =>
              onSelectedBacklinksExternalReport(selectedBacklinks)
            }
            onRefreshBacklinks={() => onSelectedBacklinksRescrape(selectedBacklinks)}
            onDeleteBacklinks={() => onSelectedBacklinksDelete(selectedBacklinks)}
            onExportBacklinks={handleExportCsvBacklinkClicked}
            onReProcessBacklinksGoogle={() => onSelectedBacklinksGoogleRescrape(selectedBacklinks)}
          />
          <AppButton
            loadingText={'Adding Link'}
            onClick={handleAddDialog}
            borderRadius="full"
            size="sm"
            variant={'solid'}
          >
            Add Backlink
          </AppButton>
        </Flex>
      </Flex>

      <Flex w={'full'} overflow={'auto'} flexDir={'column'}>
        {backLinkColumns && (
          <AppDataTable
            data={data}
            noDataMessage={'No links added, start by adding some!'}
            columns={backLinkColumns}
            selectableRows={true}
            onRowSelected={handleTableRowsSelectedChange}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={onSortByColumnClick}
            customFilter={
              <LinksFilter
                onFiltersChange={handleFiltersChange}
                isBatchTable={batchId ? true : false}
              />
            }
          />
        )}

        {children}
      </Flex>
    </>
  );
};

export default BacklinksTable;
