import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { RelStatus, AppText } from '@backlinkit/shared';

type RelCellProps = {
  relStatus?: RelStatus;
  relValue?: string;
};

const CheckerRelCell: React.FC<RelCellProps> = ({ relStatus, relValue }) => {
  if (!relStatus) {
    return <></>;
  }

  const getRelStatus = () => {
    if (relStatus === RelStatus.follow) {
      return 'FL';
    } else if (relStatus === RelStatus.noFollow) {
      return 'NF';
    } else {
      return '-';
    }
  };

  const getRelColor = () => {
    if (relStatus === RelStatus.follow) {
      return 'green';
    } else if (relStatus === RelStatus.noFollow) {
      return 'orange';
    } else {
      return 'gray';
    }
  };

  return (
    <Tooltip label={relValue}>
      <span>
        <AppText color={getRelColor()}>{getRelStatus()}</AppText>
      </span>
    </Tooltip>
  );
};

export default CheckerRelCell;
