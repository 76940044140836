import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { Notification, NotificationEditForm } from '@backlinkit/shared';

export const notificationApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchNotificationByUser: build.query<Notification[], string>({
      query: (id) => ({
        url: `/notification/by/user/${id}`,
        method: 'GET',
      }),
    }),
    fetchAllNotificationsByOrganization: build.query<Notification[], string>({
      query: (id) => ({
        url: `/notification/by/organization/${id}`,
        method: 'GET',
      }),
    }),
    fetchNotificationById: build.query<Notification, string>({
      query: (id) => ({
        url: `/notification/${id}`,
        method: 'GET',
      }),
    }),
    updateNotification: build.query<Notification, NotificationEditForm>({
      query: (body) => ({
        url: `/notification/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteNotification: build.mutation<string, string>({
      query: (id) => ({
        url: `/notification/delete/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteAllUserNotifications: build.mutation<boolean, string>({
      query: (userId) => ({
        url: `/notification/delete/by/user/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchNotificationByUserQuery,
  useLazyFetchNotificationByUserQuery,
  // useFetchAllNotificationsByOrganizationQuery,
  useFetchNotificationByIdQuery,
  useDeleteNotificationMutation,
  useDeleteAllUserNotificationsMutation,
} = notificationApi;
