import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  PaginatedData,
  BacklinkReport,
  UserBacklinkExternalReportsByOptionsRequest,
  BacklinkReportMarkFixedRequest,
} from '@backlinkit/shared';

export const backlinkReportApi = createApi({
  reducerPath: 'backlinkReportApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchBacklinkReportsByOptions: build.query<
      PaginatedData<BacklinkReport>,
      UserBacklinkExternalReportsByOptionsRequest
    >({
      query: (data) => ({
        url: `/userbacklinkexternalreport/by/options`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<BacklinkReport>) => {
        return response;
      },
    }),
    fetchBacklinkReportsByUser: build.query<PaginatedData<BacklinkReport>, string>({
      query: (userId) => ({
        url: `/userbacklinkexternalreport/by/user/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response: BacklinkReport[]) => {
        return {
          data: response,
        } as PaginatedData<BacklinkReport>;
      },
    }),
    fetchBacklinkReportById: build.query<BacklinkReport, string>({
      query: (id) => ({
        url: `/userbacklinkexternalreport/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: BacklinkReport) => {
        return response;
      },
    }),
    saveBacklinkReport: build.mutation<BacklinkReport, BacklinkReport>({
      query: (payload) => ({
        url: `/userbacklinkexternalreport/create`,
        method: 'POST',
        body: payload,
      }),
    }),
    resendBacklinkReport: build.mutation<BacklinkReport, string>({
      query: (id) => ({
        url: `/userbacklinkexternalreport/${id}/resend`,
        method: 'POST',
      }),
    }),
    deleteBacklinkReport: build.mutation<BacklinkReport, string>({
      query: (id) => ({
        url: `/userbacklinkexternalreport/delete/${id}`,
        method: 'DELETE',
      }),
    }),
    markExternalBacklinkUnFixed: build.mutation<boolean, BacklinkReportMarkFixedRequest>({
      query: (body) => ({
        url: `/userbacklinkexternalreport/mark/external/report/${body.id}/backlink/${body.backlinkId}/unfixed`,
        method: 'PATCH',
      }),
      transformResponse: (response: boolean) => {
        return response;
      },
    }),
  }),
});

export const {
  useFetchBacklinkReportsByOptionsQuery,
  useFetchBacklinkReportsByUserQuery,
  useLazyFetchBacklinkReportByIdQuery,
  useFetchBacklinkReportByIdQuery,
  useSaveBacklinkReportMutation,
  useDeleteBacklinkReportMutation,
  useResendBacklinkReportMutation,
  useMarkExternalBacklinkUnFixedMutation,
} = backlinkReportApi;
