import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { OtpVerifyRequest } from '@backlinkit/shared';

export const tempOrganizationApi = createApi({
  reducerPath: 'tempOrganizationApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    verifyOrganizationOtp: build.query<boolean, OtpVerifyRequest>({
      query: (body) => ({
        url: `/temporganization/${body.id}/verify/otp/${body.otp}`,
        method: 'GET',
      }),
      transformResponse: (response: boolean) => {
        return response;
      },
    }),
  }),
});

export const { useLazyVerifyOrganizationOtpQuery } = tempOrganizationApi;
