import { createApi } from '@reduxjs/toolkit/query/react';
import { AppUnAuthFetchBaseQuery } from './apiBase';
import {
  UserAuth,
  LoginParams,
  RegistrationParams,
  PasswordUpsertRequest,
  User,
} from '@backlinkit/shared';

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: AppUnAuthFetchBaseQuery,
  endpoints: (build) => ({
    userAuthentication: build.mutation<UserAuth, LoginParams>({
      query: (body) => ({
        url: `/auth/login`,
        method: 'POST',
        body,
      }),
    }),
    userRegistration: build.mutation<UserAuth, RegistrationParams>({
      query: (body) => ({
        url: `/auth/register`,
        method: 'POST',
        body: JSON.stringify(body),
      }),
    }),
    userRegistrationInitiate: build.mutation<UserAuth, string>({
      query: (id) => ({
        url: `/auth/register/${id}/initiate`,
        method: 'POST',
      }),
    }),
    requestPasswordReset: build.mutation<any, string>({
      query: (email) => ({
        url: `/auth/request/password/reset/${email}`,
        method: 'GET',
      }),
    }),
    updatePassword: build.mutation<any, PasswordUpsertRequest>({
      query: (body) => ({
        url: '/auth/update-password',
        method: 'PUT',
        body: body,
      }),
    }),
    authenticatePasswordKey: build.query<User, string>({
      query: (key) => ({
        url: `/auth/authenticate/passwordKey/${key}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useUserAuthenticationMutation,
  useUserRegistrationMutation,
  useUserRegistrationInitiateMutation,
  useRequestPasswordResetMutation,
  useAuthenticatePasswordKeyQuery,
  useUpdatePasswordMutation,
} = authenticationApi;
