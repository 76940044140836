import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { backlinkURLRegex } from '../../constants/regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppButton, AppInput, BacklinkFormData } from '@backlinkit/shared';

export const backLinkFormDataDefaultValues: BacklinkFormData = {
  url: '',
  notes: '',
  expectedLandingPage: '',
  expectedAnchor: '',
  expectedRel: '',
};

const backLinkFormDataSchema = yup.object({
  url: yup.string().required('Field is required').matches(backlinkURLRegex, 'Not a valid Url'),
});

type BackLinkNoteFormType<T> = {
  form?: BacklinkFormData;
} & BaseFormProps<T>;

const BackLinkNoteForm: React.FC<BackLinkNoteFormType<BacklinkFormData>> = ({ form, onSubmit }) => {
  const {
    control: backLinkControl,
    formState: { isValid, errors },
    getValues,
  } = useForm<BacklinkFormData>({
    defaultValues: form || backLinkFormDataDefaultValues,
    resolver: yupResolver(backLinkFormDataSchema),
    mode: 'onChange',
  });

  const handleFormSubmit = (formData?: BacklinkFormData) => {
    if (form?.id && formData) {
      onSubmit(formData);
    }
  };

  return (
    <Flex flexDirection={'column'} flex={1}>
      <form>
        <AppInput<BacklinkFormData>
          mt={6}
          control={backLinkControl}
          error={errors.notes}
          label="Notes"
          name="notes"
          placeHolder={'Notes'}
          textArea={true}
          flex={1}
        />

        {form?.id && (
          <AppButton
            mt={8}
            w={'full'}
            isDisabled={!isValid}
            onClick={() => {
              handleFormSubmit(getValues());
            }}
          >
            {'Save'}
          </AppButton>
        )}
      </form>
    </Flex>
  );
};

export default BackLinkNoteForm;
