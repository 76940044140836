import { AppColors, AppText } from '@backlinkit/shared';
import { Box, Card, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';

export type OrganizationProfileProps = {
  banner?: string;
  avatar: string;
  name: string;
  subTitle: string;
  domains: number | string;
  links: number | string;
  errors: number | string;
};

const OrganizationProfile: React.FC<OrganizationProfileProps> = ({
  banner,
  avatar,
  name,
  subTitle,
  domains,
  links,
  errors,
}) => {
  const user = useSelector(selectUser);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';

  const getProfileLetters = () => {
    if (user && user.organization && user.organization.name) {
      const matches = user.organization.name.match(/\b(\w)/g);
      const acronym = matches?.join('');

      return acronym;
    }
  };

  return (
    <Card
      variant="filled"
      bg={'white'}
      p="4"
      rounded={'2xl'}
      mb={{ base: '0px', lg: '20px' }}
      alignItems="center"
      h={'100%'}
    >
      {/* bg={`url(${banner})`} */}
      <Box bg={AppColors.secondary} bgSize="cover" borderRadius="16px" h="131px" w="100%" />
      <Flex
        justify={'center'}
        align={'center'}
        mx="auto"
        mt="-43px"
        w={'87px'}
        h={'87px'}
        borderRadius={'full'}
        border={`4px solid ${AppColors.appBackground}`}
        backgroundColor={AppColors.contentColor}
      >
        <AppText
          fontSize={'2xl'}
          fontWeight={'bold'}
          color={'white'}
        >{`${getProfileLetters()}`}</AppText>
      </Flex>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>

      <Text color={textColorSecondary} fontSize="sm">
        {subTitle}
      </Text>
      <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="auto" me="60px" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {domains}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Domains
          </Text>
        </Flex>
        <Flex mx="auto" me="60px" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {links}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Links
          </Text>
        </Flex>
        <Flex mx="auto" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {errors}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Errors
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default OrganizationProfile;
