import React from 'react';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import {
  AppColors,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  useDataTable,
  useLoading,
  AppDataTable,
  AppPaginator,
  PageChangeEvent,
  AppInput,
  AppButton,
  LoadingComponent,
  OrganizationExludeLink,
} from '@backlinkit/shared';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaSave } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { selectUser } from '../../store/slices/authentication.slice';
import { useAppSelector } from '../../store/store';
import {
  useCreateOrganizationExcludeLinkMutation,
  useEditOrganizationExcludeLinkMutation,
  useFetchAllOrganizationExcludeLinksQuery,
  useLazyFetchAllOrganizationExcludeLinksQuery,
} from '../../store/api/organizationExludeLinkApi';

export interface CompetitorFormData {
  id?: string;
  link: string;
}

const defaultValues: CompetitorFormData = {
  link: '',
};

const dataSchema = yup.object({
  link: yup.string().required('Field is required'),
});

export const ExludeListManagement: React.FC = () => {
  const user = useAppSelector(selectUser);
  const toast = useToast();

  const {
    control,
    formState: { isValid, errors },
    setValue,
    getValues,
    reset,
  } = useForm<CompetitorFormData>({
    defaultValues: defaultValues,
    resolver: yupResolver(dataSchema),
    mode: 'onChange',
  });

  const [createTrigger] = useCreateOrganizationExcludeLinkMutation();
  const [updateTrigger] = useEditOrganizationExcludeLinkMutation();
  const [getAllCompetitors] = useLazyFetchAllOrganizationExcludeLinksQuery();
  const { setLoading, loading } = useLoading();

  const { handleOnPageChange, pagination } = useDataTable<OrganizationExludeLink>({
    defaultPageSize: 5,
  });

  const {
    data: competitors,
    refetch,
    isLoading,
  } = useFetchAllOrganizationExcludeLinksQuery(user?.organizationId!, {
    skip: !user,
  });

  const handleEdit = (item: OrganizationExludeLink) => {
    setValue('id', item.id);
    setValue('link', item.link);
  };

  const competitorColumns: ColumnDefinitionType<OrganizationExludeLink>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date Added',
    },
    {
      key: 'link',
      header: 'Domain',
    },
    {
      key: 'custom',
      header: 'Actions',
      render: (item) => (
        <Flex>
          <IconButton
            backgroundColor={'transparent'}
            aria-label="edit-exclude-link"
            isRound
            onClick={() => handleEdit(item)}
          >
            <Icon as={MdModeEdit} boxSize={6} />
          </IconButton>
        </Flex>
      ),
    },
  ];

  const handleSave = async () => {
    try {
      const formData = getValues();
      setLoading(true);
      if (formData.id) {
        const competitor = competitors?.find((x) => x.id === formData.id);
        if (competitor) {
          await updateTrigger({
            id: competitor.id,
            link: formData.link,
            organizationId: competitor.organizationId,
          });
        }
        toast({
          title: 'Exclude Link saved',
          description: "We've saved your exclude link successfully!",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        await createTrigger({
          link: formData.link,
          organizationId: user?.organizationId ?? '',
        });
        toast({
          title: 'Exclude Link saved',
          description: "We've saved your exclude link successfully!",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }

      reset();
      refetch();

      if (user) {
        getAllCompetitors(user.organizationId);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: 'Exclude Link error',
        description: "We've run into a problem saving your exclude link, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Flex flexDir={'column'}>
      {(loading || isLoading) && <LoadingComponent />}
      <Flex w={'full'} bg={useColorModeValue('white', 'gray.800')} flex={1}>
        <Flex flex={1} align={'end'}>
          <AppInput<CompetitorFormData>
            mr={2}
            flex={1}
            name="link"
            control={control}
            error={errors.link}
            placeHolder="Add / Edit Exclude Link"
          />
        </Flex>
        <Tooltip shouldWrapChildren label={'Save Exclude Link'} aria-label="save exclude link">
          <AppButton
            px={3}
            bgColor={AppColors.appBackground}
            color={'black'}
            onClick={() => handleSave()}
            isDisabled={!isValid}
          >
            <FaSave />
          </AppButton>
        </Tooltip>
      </Flex>

      <Box mt={2} maxH={'400px'} overflow={'auto'}>
        <AppDataTable
          searchBar={false}
          columns={competitorColumns}
          data={competitors || []}
          selectableRows={false}
          showColumnVisibility={false}
          noDataMessage="No Exclude Links added yet, start by adding some!"
        />
      </Box>

      {/* <AppPaginator
        pagination={pagination}
        onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
      ></AppPaginator> */}
    </Flex>
  );
};

export default ExludeListManagement;
