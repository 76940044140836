import { useEffect, useState } from 'react';
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Text,
  useColorModeValue,
  useToast,
  TagLabel,
  Tag,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  useFetchUsersByOrganizationIdQuery,
  useUserCreateMutation,
  useUserDeleteMutation,
  useUserUpdateMutation,
} from '../../../store/api/userApi';
import { selectUser } from '../../../store/slices/authentication.slice';
import UserCreateForm, { UserCreateFormData } from '../../forms/user-create-form';
import UserEditForm, { UserEditFormData } from '../../forms/edit-user-form';
import {
  AppButton,
  AppColors,
  AppDataTable,
  AppLoader,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  OrganizationRoleType,
  PageChangeEvent,
  Pagination,
  SearchParam,
  SortParam,
  TableSearchEventProps,
  User,
  searchingColumnsToSearchParam,
  sortingColumnsToSortingArray,
  useDialog,
} from '@backlinkit/shared';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';

const useDataTable = <T extends {}>() => {
  const [currentSortList, setSortList] = useState<SortParam[]>([]);
  const [searchList, setSearchList] = useState<SearchParam>();
  const [selected, setSelected] = useState<User[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [currentPageSize, setPageSize] = useState<number | undefined>();

  const handleUserSearchChange = (e: TableSearchEventProps<T>) => {
    const searchObjects = searchingColumnsToSearchParam(e);
    setSearchList(searchObjects);
  };

  const handleSortByColumnClick = (columns: ColumnDefinitionType<T>[]) => {
    const columnSortQueryString = sortingColumnsToSortingArray(columns);
    setSortList(columnSortQueryString);
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    console.log(pageChangeEvent);
    setCurrentPage(pageChangeEvent.currentPage);
    setPageSize(pageChangeEvent.currentPageSize);
  };

  const nextPaginationQueryParams = {
    page: currentPage,
    pageSize: currentPageSize,
    sortList: currentSortList,
    searchList: searchList,
  };

  return {
    handleUserSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
    selected,
  };
};

const UsersTable: React.FC = () => {
  const user = useSelector(selectUser);
  const [saveUserTrigger] = useUserCreateMutation();
  const [editUserTrigger] = useUserUpdateMutation();
  const [deleteUserTrigger] = useUserDeleteMutation();

  const dialog = useDialog();
  const toast = useToast();
  const {
    handleOnPageChange,
    handleUserSearchChange,
    nextPaginationQueryParams,
    pagination,
    selected,
    setPagination,
  } = useDataTable<User>();

  const {
    data: users,
    refetch: refetchUsers,
    isLoading,
  } = useFetchUsersByOrganizationIdQuery(user?.organizationId ?? '', {
    refetchOnMountOrArgChange: true,
  });

  const renderDefaultTableActions = (item: User) => {
    return (
      <Menu>
        <MenuButton
          size="xs"
          bgColor={'white'}
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEditDialog(item);
            }}
          >
            Edit
          </MenuItem>
          {user?.id !== item.id && (
            <MenuItem
              icon={<DeleteIcon />}
              onClick={() => {
                handleDeleteUser(item);
              }}
            >
              Delete
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    );
  };

  const renderRole = (item: User) => {
    switch (item?.organizationRoleId) {
      case OrganizationRoleType.ADMIN:
        return (
          <Tag borderRadius="full" bg={AppColors.primary} color={'white'}>
            <TagLabel>Admin</TagLabel>
          </Tag>
        );
      case OrganizationRoleType.BACKLINKER:
        return (
          <Tag borderRadius="full" bg={AppColors.secondary} color={'white'}>
            <TagLabel>Backlinker</TagLabel>
          </Tag>
        );
      default:
        break;
    }
  };

  const userColumns: ColumnDefinitionType<User>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'organizationRoleId',
      header: 'Role',
      headerSortable: true,
      columnSearchable: true,
      render: (item) => renderRole(item),
    },
    {
      key: 'email',
      header: 'e-mail',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  const [tableColumns, setTableColumns] = useState<ColumnDefinitionType<User>[]>(userColumns);

  const handleEditDialog = (selectedUser: User) => {
    const isOwner = user?.organization.createdUserId === selectedUser.id;
    const UserForm: UserEditFormData = {
      id: selectedUser.id.toString(),
      email: selectedUser.email,
      organizationRoleId: selectedUser.organizationRoleId,
      organizationId: user!.organizationId,
    };
    dialog({
      title: 'Edit User',
      render: (onSubmit) => {
        return (
          <UserEditForm
            isOwner={isOwner}
            form={UserForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertUser(formData);
            }}
          ></UserEditForm>
        );
      },
    });
  };

  const handleAddDialog = () => {
    dialog({
      title: 'Create User',
      render: (onSubmit) => {
        return (
          <UserCreateForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertUser(formData);
            }}
          ></UserCreateForm>
        );
      },
    });
  };

  const upsertUser = async (formData: any) => {
    let edittedUser = formData.id ? true : false;
    try {
      if (!edittedUser) {
        const createFormData = formData as UserCreateFormData;
        await saveUserTrigger({
          email: createFormData.email,
          password: createFormData.password,
          organizationRoleId: createFormData.organizationRoleId,
          organizationId: user!.organizationId,
        }).unwrap();
      } else {
        const editFormData = formData as UserEditFormData;
        await editUserTrigger({
          id: editFormData.id ?? '',
          email: editFormData.email,
          organizationRoleId: editFormData.organizationRoleId,
          organizationId: user!.organizationId,
        }).unwrap();
        edittedUser = true;
      }

      toast({
        title: `User ${!edittedUser ? 'added' : 'updated'}`,
        description: `We've ${
          !edittedUser ? 'added' : 'updated'
        } your user for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchUsers();
    } catch (err) {
      if (edittedUser) {
        toast({
          title: `User updating. error= ${err}`,
          description: `We've run into a problem updating your user, Contact support for help`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: `User already exists in the system.`,
          description: `Please change the email address & try again`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
    refetchUsers();
  };

  const handleDeleteUser = async (users: User) => {
    try {
      const deletePromise = deleteUserTrigger(users.id).unwrap();
      await deletePromise;
    } catch (err) {
      toast({
        title: 'User Delete Error',
        description: `We've run into a problem deleting the selected user. Contact support for help`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'User deleted',
      description: `We've deleted the user you selected`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    refetchUsers();
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {}, [users]);

  return (
    <>
      <Flex justifyContent={'space-between'} m={4}>
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
          {'Users'}
        </Text>
        <Flex alignItems={'center'}>
          <AppButton onClick={handleAddDialog} size="sm" borderRadius={'full'} mr="4">
            Add User
          </AppButton>
        </Flex>
      </Flex>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        rounded={'2xl'}
        m={4}
        flexDir={'column'}
        flex={1}
      >
        <AppDataTable
          data={users || []}
          noDataMessage={'No users added, start by adding some!'}
          columns={tableColumns}
          selectableRows={false}
        />
      </Flex>
    </>
  );
};

export default UsersTable;
