import { Box, SimpleGrid } from '@chakra-ui/react';
import { selectDomain } from '../../store/slices/domain.slice';
import VerticalBarChart from '../../components/charts/app-vertical-bar-chart/app-vertical-bar-chart';
import HorizontalBarChart from '../../components/charts/app-horizontal-bar-chart/app-horizontal-bar-chart';
import PieBarChart from '../../components/charts/app-pie-chart/app-pie-chart';
import { selectDomainProfileBacklinks } from '../../store/slices/backlink.slice';
import { useEffect, useState } from 'react';
import {
  GoogleIndexReportDefault,
  BarChartReportDefault,
  LinkTypeReportDefault,
  RelReportDefault,
} from './domain-profile-chart-defaults';
import DomainLayout from '../../components/layouts/domain-layout';
import WorldMapChart, {
  WorldMapClickEvent,
  WorldMapItem,
} from '../../components/charts/app-world-map-chart/app-world-map-chart';
import { useLazyFetchBacklinksByDomainQuery } from '../../store/api/backlinksApi';
import { LinkType, findOcc, RelStatus, RelValues, AppCard } from '@backlinkit/shared';
import { useAppSelector } from '../../store/store';

export default function DomainProfile() {
  const domain = useAppSelector(selectDomain);
  const domainBacklinks = useAppSelector(selectDomainProfileBacklinks);

  const [googleIndexReport, setGoogleIndexReport] = useState<any>(GoogleIndexReportDefault);
  const [anchorReport, setAnchorReport] = useState<any>({
    ...BarChartReportDefault,
  });
  const [anchorTermReport, setAnchorTermReport] = useState<any>({
    ...BarChartReportDefault,
  });
  const [linkTypeReport, setLinkTypeReport] = useState<any>(LinkTypeReportDefault);
  const [tldReport, setTldReport] = useState<any>({ ...BarChartReportDefault });

  const [landingPageReport, setLandingPageReport] = useState<any>({
    ...BarChartReportDefault,
  });

  const [domainReport, setDomainReport] = useState<any>({
    ...BarChartReportDefault,
  });

  const [geolocationReport, setGeolocationReport] = useState<WorldMapItem[]>([]);

  const [relReportReport, setRelReportReport] = useState<any>(RelReportDefault);

  const [googleIndexReportKey, setGoogleIndexReportKey] = useState<number>(new Date().valueOf());

  const [linkTypeReportKey, setLinkTypeReportKey] = useState<number>(new Date().valueOf());

  const [relReportKey, setRelReportKey] = useState<number>(new Date().valueOf());

  const [getBacklinksByDomain] = useLazyFetchBacklinksByDomainQuery();

  useEffect(() => {
    if (domain) {
      getBacklinksByDomain(domain?.id ?? '');
    }
  }, [domain]);

  useEffect(() => {
    if (domainBacklinks && domainBacklinks.length > 0) {
      setupGoogleIndexReport();
      setupTopAnchorReport();
      setupTopAnchorTermsReport();
      setupLinkTypeReport();
      setupTLDReport();
      setupLandingPageReport();
      setupDomainReport();
      setupRelReport();
      setupGeolocationReport();
    } else {
      setupGoogleIndexReport();
      setupLinkTypeReport();
      setupRelReport();
      setAnchorReport(BarChartReportDefault);
      setAnchorTermReport(BarChartReportDefault);
      setTldReport(BarChartReportDefault);
      setLandingPageReport(BarChartReportDefault);
      setDomainReport(BarChartReportDefault);
      setGeolocationReport([]);
    }
  }, [domainBacklinks]);

  const setupGoogleIndexReport = () => {
    const domainGoogleIndexCount = domainBacklinks.filter((x) => x.backlinkGoogle?.hasDomainIndex);
    const pageGoogleIndexCount = domainBacklinks.filter((x) => x.backlinkGoogle?.hasPageIndex);
    const noGoogleIndexCount = domainBacklinks.filter(
      (x) => !x.backlinkGoogle?.hasDomainIndex && !x.backlinkGoogle?.hasPageIndex
    );

    const googleIndexReportData = { ...GoogleIndexReportDefault };
    googleIndexReportData.datasets[0].data = [
      domainGoogleIndexCount ? domainGoogleIndexCount.length : 0,
      pageGoogleIndexCount ? pageGoogleIndexCount.length : 0,
      noGoogleIndexCount ? noGoogleIndexCount.length : 0,
    ];

    setGoogleIndexReport(googleIndexReportData);
    setGoogleIndexReportKey(new Date().valueOf() + 1);
  };

  const setupTopAnchorReport = () => {
    const filteredAnchors = domainBacklinks.filter(
      (x) => x.backlinkMeta?.linkType !== LinkType.Image
    );
    if (filteredAnchors) {
      const topAnchors = findOcc(filteredAnchors, 'anchor');

      const anchorReportData = JSON.parse(JSON.stringify({ ...BarChartReportDefault }));

      for (const anchor of topAnchors) {
        anchorReportData.labels?.push(anchor.key);
        anchorReportData.datasets[0].data.push(anchor.occurrence);
      }

      setAnchorReport(anchorReportData);
    }
  };

  const setupTopAnchorTermsReport = () => {
    let anchorTerms: string[] = [];
    const filteredAnchors = domainBacklinks.filter(
      (x) => x.backlinkMeta?.linkType !== LinkType.Image
    );

    if (filteredAnchors) {
      const anchors = filteredAnchors.map((x) => x.anchor);
      for (const anchor of anchors) {
        if (anchor) {
          const currentAnchorTemrs = anchor.split(' ');
          anchorTerms = anchorTerms.concat(currentAnchorTemrs);
        }
      }

      const anchorTermList = anchorTerms.map((value) => {
        return { anchor: value };
      });

      const topAnchorTerms = findOcc(anchorTermList, 'anchor');

      const anchorReportData = JSON.parse(JSON.stringify({ ...BarChartReportDefault }));

      for (const anchor of topAnchorTerms) {
        anchorReportData.labels?.push(anchor.key);
        anchorReportData.datasets[0].data.push(anchor.occurrence);
      }

      setAnchorTermReport(anchorReportData);
    }
  };

  const setupLinkTypeReport = () => {
    const linkType = domainBacklinks.filter((x) => x.backlinkMeta?.linkType === LinkType.Link);
    const imageType = domainBacklinks.filter((x) => x.backlinkMeta?.linkType === LinkType.Image);
    const redirectType = domainBacklinks.filter(
      (x) => x.backlinkMeta?.linkType === LinkType.Redirect
    );

    const linkTypeReportData = { ...LinkTypeReportDefault };
    linkTypeReportData.datasets[0].data = [
      linkType ? linkType.length : 0,
      imageType ? imageType.length : 0,
      redirectType ? redirectType.length : 0,
    ];

    setLinkTypeReport(linkTypeReportData);
    setLinkTypeReportKey(new Date().valueOf());
  };

  const setupTLDReport = () => {
    let backlinkMetas = [];

    for (const item of domainBacklinks) {
      if (item.backlinkMeta != null) {
        backlinkMetas.push(item.backlinkMeta);
      }
    }

    if (backlinkMetas) {
      const topTld = findOcc(backlinkMetas, 'tld');

      const tldReportData = JSON.parse(JSON.stringify({ ...BarChartReportDefault }));

      for (const tld of topTld) {
        tldReportData.labels?.push(tld.key);
        tldReportData.datasets[0].data.push(tld.occurrence);
      }

      setTldReport(tldReportData);
    }
  };

  const setupLandingPageReport = () => {
    const landingPages = findOcc(domainBacklinks, 'landingPage');

    const reportData = JSON.parse(JSON.stringify({ ...BarChartReportDefault }));

    const blankAmountFound = landingPages.find((x) => x.key === '');
    const slashAmountFound = landingPages.find((x) => x.key === '/');

    const blankOccuranceSum =
      (blankAmountFound?.occurrence ? blankAmountFound?.occurrence : 0) +
      (slashAmountFound?.occurrence ? slashAmountFound?.occurrence : 0);

    reportData.labels?.push('/');
    reportData.datasets[0].data.push(blankOccuranceSum);

    for (const landingPage of landingPages) {
      if (landingPage.key !== '' && landingPage.key !== '/') {
        reportData.labels?.push(landingPage.key);
        reportData.datasets[0].data.push(landingPage.occurrence);
      }
    }

    setLandingPageReport(reportData);
  };

  const setupDomainReport = () => {
    const domainList = [];
    for (const item of domainBacklinks) {
      if (item && item.url) {
        const domain = new URL(item.url);
        domainList.push(domain.hostname);
      }
    }

    const domainData = domainList.map((x) => {
      return { domain: x };
    });

    const data = findOcc(domainData, 'domain');

    const reportData = JSON.parse(JSON.stringify({ ...BarChartReportDefault }));

    for (const item of data) {
      reportData.labels?.push(item.key);
      reportData.datasets[0].data.push(item.occurrence);
    }

    setDomainReport(reportData);
  };

  const setupRelReport = () => {
    const followCount = domainBacklinks.filter(
      (x) => x.backlinkMeta?.relStatus === RelStatus.follow
    );
    const noFollowCount = domainBacklinks.filter(
      (x) => x.backlinkMeta?.relValue && x.backlinkMeta?.relValue.includes(RelValues.noFollow)
    );
    const ugcCount = domainBacklinks.filter(
      (x) => x.backlinkMeta?.relValue && x.backlinkMeta?.relValue.includes(RelValues.ugc)
    );
    const sponsoredCount = domainBacklinks.filter(
      (x) => x.backlinkMeta?.relValue && x.backlinkMeta?.relValue.includes(RelValues.sponsored)
    );

    const reportData = { ...RelReportDefault };
    reportData.datasets[0].data = [
      followCount ? followCount.length : 0,
      noFollowCount ? noFollowCount.length : 0,
      ugcCount ? ugcCount.length : 0,
      sponsoredCount ? sponsoredCount.length : 0,
    ];

    setRelReportReport(reportData);
    setRelReportKey(new Date().valueOf());
  };

  const setupGeolocationReport = () => {
    const countryList: WorldMapItem[] = [];

    let backlinkMetas = [];

    for (const item of domainBacklinks) {
      if (item.backlinkMeta != null) {
        backlinkMetas.push(item.backlinkMeta);
      }
    }

    const data = findOcc(backlinkMetas, 'ipCountry');

    for (const item of data) {
      if (item.key) countryList.push({ country: item.key, value: item.occurrence });
    }

    setGeolocationReport(countryList);
  };

  const handleMapClickEvent = (mapClickData: WorldMapClickEvent) => {
    console.log('MAP CLICK DATA', mapClickData);
  };

  return (
    <DomainLayout>
      <Box pl={'20px'} pr={'20px'} w="100%">
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
          <AppCard title="Top Anchors" justifyContent="center" alignItems={'center'} h="100%">
            <Box maxH="300px" minW="75%" m="auto">
              <HorizontalBarChart chartData={anchorReport} />
            </Box>
          </AppCard>
          <AppCard title="Top Anchor Terms" justifyContent="center" alignItems={'center'} h="100%">
            <Box maxH="300px" minW="75%" m="auto">
              <HorizontalBarChart chartData={anchorTermReport} />
            </Box>
          </AppCard>
          <AppCard
            title="Google Index Report"
            justifyContent="center"
            alignItems={'center'}
            h="100%"
          >
            <Box maxH="300px" m="auto">
              <PieBarChart key={googleIndexReportKey} chartData={googleIndexReport} />
            </Box>
          </AppCard>
          <AppCard title="Link Type Report" justifyContent="center" alignItems={'center'} h="100%">
            <Box maxH="300px" m="auto">
              <PieBarChart key={linkTypeReportKey} chartData={linkTypeReport} />
            </Box>
          </AppCard>
          <AppCard title="Top TLD" justifyContent="center" alignItems={'center'} h="100%">
            <Box maxH="300px" minW="75%" m="auto">
              <VerticalBarChart chartData={tldReport} />
            </Box>
          </AppCard>

          <AppCard
            title="Most linked landing pages"
            justifyContent="center"
            alignItems={'center'}
            h="100%"
          >
            <Box maxH="300px" minW="75%" m="auto">
              <HorizontalBarChart chartData={landingPageReport} />
            </Box>
          </AppCard>
          <AppCard title="Rel Attribute" justifyContent="center" alignItems={'center'} h="100%">
            <Box maxH="300px" m="auto">
              <PieBarChart key={relReportKey} chartData={relReportReport} />
            </Box>
          </AppCard>
          <AppCard
            title="Domain Distribution"
            justifyContent="center"
            alignItems={'center'}
            h="100%"
          >
            <Box maxH="300px" minW="75%" m="auto">
              <HorizontalBarChart chartData={domainReport} />
            </Box>
          </AppCard>
        </SimpleGrid>
        <AppCard mt="4" title="IP Geolocation" justifyContent="start" alignItems={'center'}>
          <WorldMapChart
            chartData={geolocationReport}
            onMapClick={(mapClickData: WorldMapClickEvent) => handleMapClickEvent(mapClickData)}
          />
        </AppCard>
      </Box>
    </DomainLayout>
  );
}
