import React, { useEffect, useState } from 'react';
import { Flex, SimpleGrid } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { backlinkURLRegex } from '../../constants/regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import {
  AppAlertItem,
  AppButton,
  AppInput,
  BacklinkFormData,
  SelectListFilter,
  SelectOption,
} from '@backlinkit/shared';
import { useCurrencies } from '../../hooks/useCurrencies';

export const backLinkFormDataDefaultValues: BacklinkFormData = {
  url: '',
  notes: '',
  expectedLandingPage: '',
  expectedAnchor: '',
  expectedRel: '',
  price: 0,
  priceCurrency: undefined,
};

const backLinkFormDataSchema = yup.object({
  url: yup.string().required('Field is required').matches(backlinkURLRegex, 'Not a valid Url'),
  price: yup.number(),
});

type BackLinkPriceFormType<T> = {
  form?: BacklinkFormData;
} & BaseFormProps<T>;

const BackLinkPriceForm: React.FC<BackLinkPriceFormType<BacklinkFormData>> = ({
  form,
  onSubmit,
}) => {
  const [currencyOptions, setCurrencyOptions] = useState<SelectOption[]>();
  const [warning, setWarning] = useState<boolean>(false);
  const [defaultCurrency, setDefaultCurrency] = useState<SelectOption>();

  const {
    control: backLinkControl,
    formState: { isValid },
    setValue,
    getValues,
  } = useForm<BacklinkFormData>({
    defaultValues: form || backLinkFormDataDefaultValues,
    resolver: yupResolver(backLinkFormDataSchema),
    mode: 'onChange',
  });

  const currencies = useCurrencies();

  const handleFormSubmit = (formData?: BacklinkFormData) => {
    if (form?.id && formData) {
      onSubmit(formData);
    }
  };

  useEffect(() => {
    if (currencies && form) {
      setCurrencyOptions(currencies);

      const selectedCurrency = currencies?.find((x) => {
        return x.value === form.priceCurrency;
      });
      if (selectedCurrency) {
        setDefaultCurrency(selectedCurrency);
      }
    }
    getCurrencyWarning();
  }, [currencies, form?.priceCurrency]);

  const getCurrencyWarning = () => {
    const formData = getValues();
    if (formData.price && formData.price > 0) {
      switch (formData?.priceCurrency) {
        case undefined:
        case '':
          setWarning(true);
          break;
        default:
          setWarning(false);
          break;
      }
    }
  };

  useEffect(() => {
    setCurrencyOptions(currencies);
    getCurrencyWarning();
  }, [currencies, form?.priceCurrency]);

  return (
    <Flex flexDirection={'column'} flex={1}>
      <form>
        <SimpleGrid columns={2} columnGap={4} pb={4}>
          <Flex height={'100%'} align={'end'}>
            <SelectListFilter
              flex={1}
              mr={2}
              options={currencyOptions || []}
              isInModal
              isMulti={false}
              showClear={false}
              placeholder="Select Currency"
              defaultOption={defaultCurrency}
              onSelectionChange={(item: SelectOption[]) => {
                setValue('priceCurrency', item[0].value);
                getCurrencyWarning();
              }}
            />
          </Flex>
          <AppInput<BacklinkFormData>
            mt={6}
            control={backLinkControl}
            error={undefined}
            name="price"
            label="Price"
            placeHolder={'Price'}
            inputType="number"
            flex={1}
            onChange={() => {
              getCurrencyWarning();
            }}
          />
        </SimpleGrid>

        {form?.id && (
          <AppButton
            mt={8}
            w={'full'}
            isDisabled={!isValid && !warning}
            onClick={() => {
              handleFormSubmit(getValues());
            }}
          >
            {'Save'}
          </AppButton>
        )}

        {warning && (
          <AppAlertItem
            item={{
              name: 'Curency required',
              nameLabel: undefined,
              alertType: 'warning',
              alertMessage: 'Field is required',
              alertMessageLabel: undefined,
              alertNote: undefined,
              alertNoteLabel: undefined,
              entityId: undefined,
              alertKey: undefined,
            }}
          />
        )}
      </form>
    </Flex>
  );
};

export default BackLinkPriceForm;
