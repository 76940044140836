import React, { useState } from 'react';
import { Box, Divider, Flex, IconButton, Popover, Tooltip, useToast } from '@chakra-ui/react';
import {
  AppText,
  AppColors,
  BacklinkBatch,
  AppCard,
  usePanel,
  useLoading,
  DomainListItem,
} from '@backlinkit/shared';
import StatusIndicator from '../../components/status-indicator/status-indicator';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import BacklinkBatchLinks from './backlink-batch-links';
import BatchTagCell from '../../components/backlink-batch/batch-tag-cell';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import BacklinkBatchForm, {
  BacklinkBatchFormData,
} from '../../components/forms/backlink-batch-form';
import { useDeleteBatchMutation, useUpdateBatchMutation } from '../../store/api/backlinkBatchesApi';
import BatchNoteCell from '../../components/app/app-batch-note-cell/app-batch-note-cell';

export type BatchCardProps = {
  batch: BacklinkBatch;
  domain: DomainListItem;
  refetchBatches: () => void;
  showLinks?: boolean;
};

const BatchCard: React.FC<BatchCardProps> = ({ batch, domain, showLinks = false, refetchBatches }) => {
  const panel = usePanel();
  const toast = useToast();
  const { setLoading } = useLoading();

  const [showBacklinks, setShowBacklinks] = useState<boolean>(showLinks);
  const [updateBatchTrigger] = useUpdateBatchMutation();
  const [deleteBatchTrigger] = useDeleteBatchMutation();

  const handleBatchEdit = () => {
    const tagIds = batch.backlinkBatchTags ? batch.backlinkBatchTags.map((x) => x.tagId ?? '') : [];
    const formValues: BacklinkBatchFormData = {
      id: batch.id,
      domainId: batch.domainId,
      name: batch.name,
      price: batch.price,
      notes: batch.notes,
      batchTypeId: batch.batchTypeId,
      nicheTypeId: batch.nicheTypeId,
      tagIds,
    };
    panel({
      title: `Batch Edit`,
      size: 'lg',
      render: (onSubmit) => {
        return (
          <BacklinkBatchForm
            form={formValues}
            onSubmit={async (formData) => {
              console.log('BatchForm', formData);
              onSubmit();
              setLoading(true);
              await updateBatchTrigger({
                id: batch.id,
                domainId: batch.domainId,
                createdUserId: batch.createdUserId,
                lastModifiedUserId: batch.lastModifiedUserId,
                name: formData.name,
                price: formData.price,
                notes: formData.notes,
                batchTypeId: formData.batchTypeId,
                nicheTypeId: formData.nicheTypeId,
                tagIds: formData.tagIds,
                tagChanges: true,
              });
              refetchBatches();
              setLoading(false);
            }}
          />
        );
      },
    });
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteBatchTrigger(batch.id).unwrap();
      toast({
        title: 'Selected Batch deleted',
        description: "We've deleted the batch you selected",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchBatches();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast({
        title: 'Batch delete error',
        status: 'error',
        description:
          "We've run into an error deleting the batch you selected, Contact support for help",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <AppCard>
      <Flex gap={8} justifyContent={'space-between'}>
        <Flex flex={4} gap={1} alignItems={'center'} justify={'space-evenly'}>
          <AppText flex={1} fontWeight={'bold'}>
            {batch.name}
          </AppText>
          <AppText flex={1}>Price: {batch.price || 'N/A'}</AppText>
          <AppText flex={1}>Backlinks: {batch.backlinks?.length || 0}</AppText>
          <Box flex={1}>
            <BatchTagCell tags={batch.backlinkBatchTags || []} />
          </Box>
          <Box flex={1}>
            <BatchNoteCell batch={batch} />
          </Box>
          <Box flex={1}>
            <Popover trigger="hover" placement="top">
              <Flex>
                <Tooltip
                  label={
                    batch.backlinks?.some((item) => item.errors && item.errors.length > 0)
                      ? 'There are errors within your batch'
                      : 'There are no errors in you batch'
                  }
                >
                  <span>
                    <StatusIndicator
                      status={
                        batch.backlinks?.some((x) => x.errors && x.errors.length > 0)
                          ? AppColors.errorColor
                          : AppColors.successColor
                      }
                      pulse={true}
                    />
                  </span>
                </Tooltip>
              </Flex>
            </Popover>
          </Box>
          <Flex gap={4}>
            <IconButton
              aria-label="batchDelete"
              icon={<MdDelete />}
              bgColor={AppColors.appBackground}
              color={AppColors.primary}
              onClick={() => handleDelete()}
            />
            <IconButton
              aria-label="batchEdit"
              icon={<MdModeEdit />}
              bgColor={AppColors.appBackground}
              color={AppColors.primary}
              onClick={() => handleBatchEdit()}
            />
            <IconButton
              aria-label="showbacklinks-chevron"
              icon={showBacklinks ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
              bgColor={AppColors.appBackground}
              color={AppColors.primary}
              onClick={() => {
                setShowBacklinks(!showBacklinks);
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      {showBacklinks && (
        <Flex flexDirection={'column'}>
          <Divider my={4} />

          <Flex gap={8}>
            <AppText>
              Batch Type: <strong>{batch.batchType ? batch.batchType.name : 'Not Selected'}</strong>
            </AppText>
            <AppText>
              Niche Type: <strong>{batch.nicheType ? batch.nicheType.name : 'Not Selected'}</strong>
            </AppText>
          </Flex>

          <Box width={'94vw'}>
            <BacklinkBatchLinks
              batchId={batch.id}
              domain={domain}
              refetchBatches={refetchBatches}
            />
          </Box>
        </Flex>
      )}
    </AppCard>
  );
};

export default BatchCard;
