import React from 'react';
import { Flex, Icon, IconButton, Tooltip, useColorModeValue, useToast } from '@chakra-ui/react';
import {
  AppColors,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  useDataTable,
  useLoading,
  AppDataTable,
  AppPaginator,
  PageChangeEvent,
  AppInput,
  AppButton,
  LoadingComponent,
  OrganizationTag,
} from '@backlinkit/shared';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaSave } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import {
  useCreateTagMutation,
  useEditTagMutation,
  useFetchTagsByOptionsQuery,
  useLazyFetchTagsByOrganizationIdQuery,
} from '../../store/api/tagApi';
import { selectUser } from '../../store/slices/authentication.slice';
import { useAppSelector } from '../../store/store';

export type TagManagementFormData = {
  id?: string;
  name: string;
};

export const tagManagementFormDataDefaultValues: TagManagementFormData = {
  name: '',
};

export const tagManagementFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
});

const TagManagement: React.FC = () => {
  const user = useAppSelector(selectUser);
  const toast = useToast();
  const { setLoading, loading } = useLoading();

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<TagManagementFormData>({
    defaultValues: tagManagementFormDataDefaultValues,
    resolver: yupResolver(tagManagementFormDataSchema),
    mode: 'onChange',
  });

  const [createTagTrigger] = useCreateTagMutation();
  const [updateTagTrigger] = useEditTagMutation();
  const [getAllTagsByOrganizationId] = useLazyFetchTagsByOrganizationIdQuery();

  const { handleOnPageChange, nextPaginationQueryParams, pagination } =
    useDataTable<OrganizationTag>({ defaultPageSize: 5 });

  const {
    data: OrganizationTags,
    isFetching,
    refetch: refetchTags,
  } = useFetchTagsByOptionsQuery(
    {
      organizationId: user?.organizationId?.toString() ?? '',
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleEdit = (item: OrganizationTag) => {
    setValue('id', item.id);
    setValue('name', item.name ?? '');
  };

  const dataColumns: ColumnDefinitionType<OrganizationTag>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date',
    },
    {
      key: 'name',
      header: 'Name',
    },
    {
      key: 'custom',
      header: 'Actions',
      render: (item) => (
        <Flex>
          <IconButton
            backgroundColor={'transparent'}
            aria-label={'editTag'}
            isRound={true}
            onClick={() => handleEdit(item)}
          >
            <Icon as={MdModeEdit} boxSize={'6'} />
          </IconButton>
        </Flex>
      ),
    },
  ];

  const handleSave = async () => {
    try {
      const formData = getValues();

      setLoading(true);

      if (formData.id) {
        const item = OrganizationTags?.data.find((x) => x.id === formData.id);
        if (item) {
          await updateTagTrigger({
            id: item.id,
            createdUserId: item.createdUserId,
            lastModifiedUserId: item.lastModifiedUserId,
            name: formData.name,
            description: item.description,
          });
        }
      } else {
        await createTagTrigger({
          name: formData.name,
          description: 'Test',
          organizationId: user?.organizationId,
        });
      }

      toast({
        title: 'Tag Saved',
        description: "We've saved your Tag successfully!",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      reset();
      refetchTags();
      if (user) {
        getAllTagsByOrganizationId(user?.organizationId);
      }

      setLoading(false);
    } catch (error) {
      toast({
        title: 'Tag error.',
        description: "We've run into a problem saving your Tag, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  return (
    <Flex flexDirection={'column'}>
      {(loading || isFetching) && <LoadingComponent />}
      <Flex w={'full'} bg={useColorModeValue('white', 'gray.800')} flexDir={'column'} flex={1}>
        <Flex flex={1} alignItems={'end'}>
          <AppInput<TagManagementFormData>
            mr={2}
            flex={1}
            name="name"
            control={control}
            error={errors.name}
            label=""
            placeHolder="Add / Edit Tag Name"
          />
          <Tooltip shouldWrapChildren label={'Save Tag'} aria-label="saveTag">
            <AppButton
              px={3}
              bgColor={AppColors.appBackground}
              color={'black'}
              onClick={() => handleSave()}
            >
              <FaSave />
            </AppButton>
          </Tooltip>
        </Flex>

        <AppDataTable
          searchBar={false}
          columns={dataColumns}
          data={OrganizationTags?.data || []}
          selectableRows={false}
          showColumnVisibility={false}
          noDataMessage="No Tags added yet, start by adding some!"
        />
        <AppPaginator
          pagination={pagination}
          onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
        ></AppPaginator>
      </Flex>
    </Flex>
  );
};

export default TagManagement;
