import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  PaginatedData,
  UserGoogleCheckerLink,
  UserCheckerLinksByOptionsRequest,
  UserCheckerLinkBulkCreateRequest,
  UserCheckerLinkReprocessRequest,
} from '@backlinkit/shared';

export const googleCheckerApi = createApi({
  reducerPath: 'googleCheckerApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchGoogleCheckerItemsByOptions: build.query<
      PaginatedData<UserGoogleCheckerLink>,
      UserCheckerLinksByOptionsRequest
    >({
      query: (body) => ({
        url: `/usercheckerlink/by/options`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: PaginatedData<UserGoogleCheckerLink>) => {
        return response;
      },
    }),
    userCheckerLinkBulkCreate: build.query<any, UserCheckerLinkBulkCreateRequest>({
      query: (data) => ({
        url: `/usercheckerlink/create/bulk`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }),
    userCheckerLinkBulkDelete: build.query<any, string>({
      query: (userId) => ({
        url: `/usercheckerlink/delete/by/userId/${userId}`,
        method: 'DELETE',
      }),
    }),
    userCheckerReprocess: build.query<any, UserCheckerLinkReprocessRequest>({
      query: (data) => ({
        url: `/usercheckerlink/reprocess/bulk`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }),
  }),
});

export const {
  useLazyFetchGoogleCheckerItemsByOptionsQuery,
  useLazyUserCheckerLinkBulkCreateQuery,
  useLazyUserCheckerLinkBulkDeleteQuery,
  useLazyUserCheckerReprocessQuery,
} = googleCheckerApi;
