import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  StripeCheckoutSessionRequest,
  StripePortalSessionRequest,
  StripeSessionResponse,
  StripeUpdateSubscriptionRequest,
} from '@backlinkit/shared';

export const stripeApi = createApi({
  reducerPath: 'stripeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    createCheckoutSession: build.mutation<StripeSessionResponse, StripeCheckoutSessionRequest>({
      query: (body) => ({
        url: `/stripe/create-checkout-session`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: StripeSessionResponse) => {
        return response;
      },
    }),
    createPortalSession: build.mutation<StripeSessionResponse, StripePortalSessionRequest>({
      query: (body) => ({
        url: `/stripe/create-portal-session`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: StripeSessionResponse) => {
        return response;
      },
    }),
    updateSubscription: build.mutation<any, StripeUpdateSubscriptionRequest>({
      query: (body) => ({
        url: `/stripe/update-subscription`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useCreateCheckoutSessionMutation,
  useCreatePortalSessionMutation,
  useUpdateSubscriptionMutation,
} = stripeApi;
