import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { OrganizationTag } from '@backlinkit/shared';
import { tagApi } from '../api/tagApi';

interface TagState {
  tags: OrganizationTag[];
}

const initialState: TagState = {
  tags: [],
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      tagApi.endpoints.fetchTagsByOrganizationId.matchFulfilled,
      (state, { payload }) => {
        return { ...state, tags: payload };
      }
    );
  },
  reducers: {},
});

export const {} = tagSlice.actions;

export const selectOrganizationTags = (state: RootState) => state.tag.tags;
