import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa6';
import { FaYoutube } from 'react-icons/fa6';

export const legals = [
  {
    href: '',
    label: 'Cookie Policy',
  },
  {
    href: '',
    label: 'Privacy Policy',
  },
  {
    href: '',
    headerContent: 'Say Hello 👋',
    label: 'support@backlinkit.com',
  },
];

export const socialLinks = [
  {
    href: '',
    label: 'FACEBOOK',
    icon: FaFacebook,
  },
  {
    href: '',
    label: 'INSTAGRAM',
    icon: FaInstagram,
  },
  {
    href: '',
    label: 'LINKEDIN',
    icon: FaLinkedin,
  },
  {
    href: '',
    label: 'YOUTUBE',
    icon: FaYoutube,
  },
];
