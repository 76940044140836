import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { User, UserCreateForm, UserEditForm } from '@backlinkit/shared';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchUsersByOrganizationId: build.query<User[], string>({
      query: (orgId) => ({
        url: `/user/by/organization/${orgId}`,
        method: 'GET',
      }),
      transformResponse: (response: User[]) => {
        return response;
      },
    }),
    fetchUserById: build.query<User, string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: User) => {
        return response;
      },
    }),
    userCreate: build.mutation<User, UserCreateForm>({
      query: (body) => ({
        url: `/user/create`,
        method: 'POST',
        body: body,
      }),
    }),
    userUpdate: build.mutation<User, UserEditForm>({
      query: (payload) => ({
        url: `user/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    userMarkSignIn: build.mutation<User, string>({
      query: (userId) => ({
        url: `/user/mark/signedin/${userId}`,
        method: 'PATCH',
      }),
    }),
    userDelete: build.mutation<User, string>({
      query: (userId) => ({
        url: `/user/delete/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useUserCreateMutation,
  useFetchUsersByOrganizationIdQuery,
  useLazyFetchUserByIdQuery,
  useUserMarkSignInMutation,
  useUserUpdateMutation,
  useUserDeleteMutation,
} = userApi;
