import {
  BacklinkFormData,
  BacklinkCSVImportValidated,
  CsvFileUploadReaderErrorRow,
  CsvFileUploadReaderResult,
} from '@backlinkit/shared';

import { MultiBacklinkData } from '../components/forms/multi-backlink-create-form';
import { ImportBacklinkCsvRequiredColumnsEnum } from '../constants/csvImportDefinitions';
import { domainURLRegex } from '../constants/regex';

export type BacklinkImportFileValidationResult = {
  validbacklinkFormsData: BacklinkFormData[];
  invalidBacklinkImports: BacklinkCSVImportValidated[];
  invalidFileImports: CsvFileUploadReaderErrorRow[];
};

export const validateMultiBacklinkFormData = (multiUrlValue: string) => {
  const regExp = /\r?\n|\r/g;
  const cleanedUrlList = multiUrlValue.replaceAll(regExp, ',').split(',');

  const backlinksData: MultiBacklinkData[] = [];
  cleanedUrlList.forEach((url, index) => {
    if (url.match(domainURLRegex)) {
      backlinksData.push({ id: index, url: url, valid: true });
    } else {
      backlinksData.push({ id: index, url: url, valid: false });
    }
  });

  return backlinksData;
};

export const backlinkImportFileValidation = (fileData: CsvFileUploadReaderResult) => {
  const finalResult: BacklinkImportFileValidationResult = {
    validbacklinkFormsData: [],
    invalidBacklinkImports: [],
    invalidFileImports: [],
  };
  if (
    fileData &&
    fileData.headerResult &&
    fileData.headerResult.valid &&
    fileData.validResults.length > 0
  ) {
    fileData.validResults.forEach((importedBacklink, index) => {
      const url = safeIndexValue(
        importedBacklink.rowValue,
        ImportBacklinkCsvRequiredColumnsEnum.Url
      );
      const price = safeIndexValue(
        importedBacklink.rowValue,
        ImportBacklinkCsvRequiredColumnsEnum.Price
      );
      const priceCurrency = safeIndexValue(
        importedBacklink.rowValue,
        ImportBacklinkCsvRequiredColumnsEnum.Price_Currency
      );
      const expectedAnchor = safeIndexValue(
        importedBacklink.rowValue,
        ImportBacklinkCsvRequiredColumnsEnum.Expected_Anchor
      );
      const expectedLandingPage = safeIndexValue(
        importedBacklink.rowValue,
        ImportBacklinkCsvRequiredColumnsEnum.Expected_Landing_Page
      );
      const follow = boolOrNull(
        safeIndexValue(
          importedBacklink.rowValue,
          ImportBacklinkCsvRequiredColumnsEnum['Follow(True/False)']
        ) ?? ''
      );
      const noFollow = boolOrNull(
        safeIndexValue(
          importedBacklink.rowValue,
          ImportBacklinkCsvRequiredColumnsEnum['NoFollow(True/False)']
        ) ?? ''
      );
      const ugc = boolOrNull(
        safeIndexValue(
          importedBacklink.rowValue,
          ImportBacklinkCsvRequiredColumnsEnum['UGC(True/False)']
        ) ?? ''
      );
      const sponsored = boolOrNull(
        safeIndexValue(
          importedBacklink.rowValue,
          ImportBacklinkCsvRequiredColumnsEnum['Sponsored(True/False)']
        ) ?? ''
      );

      if (url && url.toString().match(domainURLRegex)) {
        let linkRel = '';
        if (follow === true) {
          linkRel = 'follow';
        } else {
          let linkRelList: string[] = [];

          if (noFollow === true) {
            linkRelList.push('nofollow');
          }

          if (ugc === true) {
            linkRelList.push('ugc');
          }
          if (sponsored === true) {
            linkRelList.push('sponsored');
          }

          linkRel = linkRelList.join(',');
        }

        let convertedPrice = price ? +price : 0;

        finalResult.validbacklinkFormsData.push({
          url: url,
          price: convertedPrice,
          priceCurrency: priceCurrency,
          expectedAnchor: expectedAnchor,
          expectedLandingPage: expectedLandingPage,
          notes: '',
          expectedRel: linkRel,
        });
      } else {
        if (!url || !url.toString().match(domainURLRegex)) {
          finalResult.invalidBacklinkImports.push({
            lineNumber: importedBacklink.rowLine,
            value: url || 'Url',
            message: 'Url not valid',
          });
        }
      }
    });
  }

  finalResult.invalidFileImports = fileData.invalidRows;

  return finalResult;
};

export const boolOrNull = (value: string) => {
  if (value.toLocaleLowerCase() === 'true' || value.toLocaleLowerCase() === 'false') {
    const boolValue = value.toLocaleLowerCase() === 'true';
    return boolValue;
  }

  return null;
};

const safeIndexValue = (rowData: string[], index: number) => {
  if (typeof rowData[index] !== 'undefined' && rowData[index] !== null) {
    return rowData[index];
  } else {
    return undefined;
  }
};
