import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import * as React from 'react';

export interface SharedPanelOptions {
  catchOnCancel?: boolean;
  title?: string;
  noTitle?: boolean;
  size?: 'full' | 'xl' | 'lg' | 'md' | 'sm';
  render?: (onSubmit: () => void, onCancel: () => void) => React.ReactNode;
  noPadding?: boolean;
  closeButtonColor?: string;
  showClose?: boolean;
  showOverlay?: boolean;
}

interface SharedPanelProps extends SharedPanelOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const SharedPanel: React.FC<SharedPanelProps> = ({
  open,
  title,
  noTitle = false,
  size = 'md',
  closeButtonColor = 'black',
  noPadding = false,
  showClose = true,
  showOverlay = false,
  render,
  onSubmit,
  onClose,
}) => {
  return (
    <Drawer
      isOpen={open}
      placement="right"
      onClose={onClose}
      size={size}
      closeOnOverlayClick={showClose ? true : false}
    >
      {showOverlay ? (
        <DrawerOverlay w={'full'} bg="blackAlpha.300" backdropFilter="blur(10px)" />
      ) : (
        <DrawerOverlay w={'full'} />
      )}

      <DrawerContent h={'100%'}>
        {showClose && <DrawerCloseButton zIndex={5} color={closeButtonColor} />}

        {noTitle ? <></> : <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>}

        <DrawerBody p={noPadding ? 0 : 6}>{render && render(onSubmit, onClose)}</DrawerBody>

        {/* <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue">Submit</Button>
        </DrawerFooter> */}
      </DrawerContent>
    </Drawer>
  );
};
