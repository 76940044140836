import { Flex, useToast, TagLabel, Tag, Box } from '@chakra-ui/react';
import { useMarkExternalBacklinkUnFixedMutation } from '../../store/api/backlinkReportApi';
import { AppLoader, AppText, BacklinkReport, useLoading } from '@backlinkit/shared';
import ExternalReportTable from '../../pages/external-report/external-report-table/external-report-table';
import { useGetExternalReportQuery } from '../../store/api/unAuthBacklinkReportApi';

type LinkReportPanel = {
  item: BacklinkReport;
};

const LinkReportPanel: React.FC<LinkReportPanel> = ({ item }) => {
  const toast = useToast();
  const { setLoading, loading } = useLoading();
  const { data, isFetching, refetch } = useGetExternalReportQuery(item.id);

  const [markExternalBacklinkUnFixed] = useMarkExternalBacklinkUnFixedMutation();

  const markBacklinkUnFixed = async (id: string, backlinkId: string) => {
    try {
      setLoading(true);
      await markExternalBacklinkUnFixed({ id: id ?? '', backlinkId }).unwrap();
      toast({
        title: 'Marked as unfixed',
        description: `We've marked the backlink as unfixed`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetch();
      setLoading(false);
    } catch (error) {
      toast({
        title: `Mark backlink unfixed saving issue`,
        description: `We've run into an error marking the backlink you selected as unfixed. Error = ${error}`,
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {(isFetching || loading) && <AppLoader />}
      <Flex flexDirection={'column'}>
        <Flex>
          <Box width={'200px'}>
            <AppText fontWeight={'medium'} fontSize={'14px'}>
              Domain:
            </AppText>
          </Box>
          <Box>
            <AppText>{item?.domain?.url}</AppText>
          </Box>
        </Flex>
        <Flex mt={4}>
          <Box width={'200px'}>
            <AppText fontWeight={'medium'} fontSize={'14px'}>
              Sent to:
            </AppText>
          </Box>
          <Box>
            <AppText>{item?.emailAddress}</AppText>
          </Box>
        </Flex>
        <Flex mt={4}>
          <Box width={'200px'}>
            <AppText fontWeight={'medium'} fontSize={'14px'}>
              Tag:
            </AppText>
          </Box>
          <Box>
            <Tag borderRadius="full" colorScheme={'gray'}>
              <TagLabel>{item.tag?.name}</TagLabel>
            </Tag>
          </Box>
        </Flex>

        {/* </Flex> */}
        <Flex rounded={'2xl'} overflow={'hidden'} flexDir={'column'} flex={1}>
          <ExternalReportTable
            data={data?.externalBacklinks ?? []}
            markBacklinkUnFixed={(backlinkId: string) => markBacklinkUnFixed(item.id, backlinkId)}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default LinkReportPanel;
