import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  FormLabel,
  SimpleGrid,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { DeleteIcon } from '@chakra-ui/icons';
import { validateMultiBacklinkFormData } from '../../utils/sharedFormHelpers';
import {
  BacklinkFormData,
  AppColors,
  AppButton,
  AppInput,
  AppText,
  SelectOption,
  SelectListFilter,
  AppAlertItem,
} from '@backlinkit/shared';
import { useCurrencies } from '../../hooks/useCurrencies';

export type MultiBacklinkFormData = {
  urls: string;
  notes: string;
  backlinkCategoryId?: string;
  checkGoogleIndex: boolean;
  checkGoogleCache: boolean;
  checkDomainAge: boolean;
  price?: number;
  priceCurrency?: string;
};

export interface MultiBacklinkData {
  id?: number;
  url: string;
  valid: boolean;
  price?: number;
  priceCurrency?: string;
}

export const backLinkMultiFormDataDefaultValues: MultiBacklinkFormData = {
  urls: '',
  notes: '',
  backlinkCategoryId: undefined,
  checkDomainAge: true,
  checkGoogleIndex: true,
  checkGoogleCache: true,
  price: 0,
  priceCurrency: '',
};

export const multiBackLinkFormDataSchema = yup.object({
  urls: yup.string().required('Field is required'),
  notes: yup.string(),
  backlinkCategoryId: yup.string(),
  price: yup.number(),
  priceCurrency: yup.string(),
});

type MultiBackLinkCreateFormType<T> = {} & BaseFormProps<T>;

const MultiBackLinkCreateForm: React.FC<MultiBackLinkCreateFormType<BacklinkFormData[]>> = ({
  onSubmit,
}) => {
  const [backlinksList, setBacklinksList] = useState<MultiBacklinkData[]>([]);
  const [backlinksSubmitted, setBacklinksSubmitted] = useState<boolean>(false);
  const [currencyOptions, setCurrencyOptions] = useState<SelectOption[]>();
  const [warning, setWarning] = useState<boolean>(false);
  const currencies = useCurrencies();

  const {
    setValue,
    control: backLinkControl,
    formState: { isValid, errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm<MultiBacklinkFormData>({
    defaultValues: backLinkMultiFormDataDefaultValues,
    resolver: yupResolver(multiBackLinkFormDataSchema),
    mode: 'onChange',
  });

  const handleFormSubmit = () => {
    const currentForm = getValues();
    const backlinksData = validateMultiBacklinkFormData(currentForm.urls);
    setBacklinksList(backlinksData);
    setBacklinksSubmitted(true);
  };

  const handleProcessBacklinks = () => {
    debugger;
    const currentForm = getValues();
    // const price = (currentForm.price ?? 0) / currentForm.urls.split(/[\s,]+/).length;
    const backlinksFormList: BacklinkFormData[] = [];
    backlinksList.forEach((multiBacklink, index) => {
      backlinksFormList.push({
        url: multiBacklink.url,
        notes: currentForm.notes,
        priceCurrency: currentForm.priceCurrency,
        price: currentForm.price,
      });
    });
    onSubmit(backlinksFormList);
  };

  const handleDeleteAddedBacklink = (formData: MultiBacklinkData) => {
    const filteredData = [...backlinksList].filter((x) => x.id !== formData.id);
    setBacklinksList(filteredData);
  };

  const getCurrencyWarning = () => {
    const formData = getValues();
    if (formData.price && formData.price > 0) {
      switch (formData?.priceCurrency) {
        case undefined:
          setWarning(true);
          break;
        case '':
          setWarning(true);
          break;
        default:
          setWarning(false);
          break;
      }
    }
  };

  useEffect(() => {
    setCurrencyOptions(currencies);
  }, [currencies]);

  return (
    <Flex mt={2} flexDirection={'column'} flex={1}>
      <form>
        {!backlinksSubmitted && (
          <>
            <AppInput<MultiBacklinkFormData>
              flex={1}
              control={backLinkControl}
              name="urls"
              label="URLs"
              error={errors.urls}
              placeHolder={'Urls'}
              textArea={true}
            />
            <SimpleGrid columns={2} columnGap={4}>
              <Flex height={'100%'} align={'end'}>
                <SelectListFilter
                  flex={1}
                  mr={2}
                  options={currencyOptions || []}
                  isInModal
                  isMulti={false}
                  showClear={false}
                  placeholder="Select Currency"
                  onSelectionChange={(item: SelectOption[]) => {
                    setValue('priceCurrency', item[0].value);
                    getCurrencyWarning();
                  }}
                />
              </Flex>
              <AppInput<MultiBacklinkFormData>
                mt={6}
                control={backLinkControl}
                error={undefined}
                name="price"
                label="Price"
                inputType="number"
                placeHolder={'Price'}
                flex={1}
                onChange={() => {
                  getCurrencyWarning();
                }}
              />
            </SimpleGrid>
            <FormLabel mt={2}>
              Please Note - The price & currency will apply to all added links*
            </FormLabel>
            <AppButton
              mt="6"
              w={'full'}
              onClick={() => {
                handleFormSubmit();
              }}
              isDisabled={!isValid}
            >
              {'Validate Backlinks'}
            </AppButton>
          </>
        )}
        {backlinksSubmitted && (
          <>
            <Accordion allowToggle mt={4}>
              <AccordionItem>
                <AccordionButton>
                  <Flex flex={1} justifyContent={'space-between'} pr={4}>
                    <AppText
                      textAlign={'left'}
                      w={'100%'}
                      variant={'h3'}
                      fontWeight={'medium'}
                      fontSize={'18px'}
                    >
                      Added Backlinks
                    </AppText>
                    <AppText
                      px={2}
                      py={1}
                      borderRadius="full"
                      bgColor={AppColors.secondary}
                      textColor={'white'}
                    >
                      {backlinksList.length}
                    </AppText>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Url</Th>
                          <Th>Valid</Th>
                          <Th>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {backlinksList.map((link, index) => {
                          return (
                            <Tr key={`${link.url}-${index}`}>
                              <Td>
                                <AppText isTruncated>{link.url}</AppText>
                              </Td>
                              <Td>
                                <AppText isTruncated>{link.valid ? 'True' : 'False'}</AppText>
                              </Td>
                              <Td>
                                <Flex>
                                  <AppButton
                                    mr={4}
                                    bgColor={AppColors.secondary}
                                    variant={'solid'}
                                    size="xs"
                                    borderRadius="full"
                                    onClick={() => {
                                      handleDeleteAddedBacklink(link);
                                    }}
                                    rightIcon={<DeleteIcon />}
                                  >
                                    Delete
                                  </AppButton>
                                </Flex>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <AppInput<MultiBacklinkFormData>
              control={backLinkControl}
              error={errors.notes}
              label="Notes"
              name="notes"
              placeHolder={'Notes'}
              textArea={true}
              flex={1}
              mt="4"
            />

            <AppButton
              mt="6"
              w={'full'}
              onClick={() => {
                handleProcessBacklinks();
              }}
              disabled={!warning}
            >
              {'Process Backlinks'}
            </AppButton>
          </>
        )}
      </form>
      {warning && (
        <AppAlertItem
          item={{
            name: 'Currency',
            nameLabel: undefined,
            alertType: 'warning',
            alertMessage: 'Field is required',
            alertMessageLabel: undefined,
            alertNote: undefined,
            alertNoteLabel: undefined,
            entityId: undefined,
            alertKey: undefined,
          }}
        />
      )}
    </Flex>
  );
};

export default MultiBackLinkCreateForm;
