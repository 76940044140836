import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppColors, AppText } from '@backlinkit/shared';
import BaseLayout from '../../components/layouts/base-layout';
import { useAppSelector } from '../../store/store';
import { selectUser } from '../../store/slices/authentication.slice';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { RoutesList } from '../../router/router';
import { useFetchProductByIdQuery } from '../../store/api/productApi';

const PaymentSuccessPage: React.FC = () => {
  const { id } = useParams();
  const user = useAppSelector(selectUser);
  const { data: product } = useFetchProductByIdQuery(id ?? '');
  const navigate = useNavigate();

  const paymentDate = new Date().toUTCString().slice(0, 16);

  return (
    <Flex direction={'column'} align={'center'} justify={'center'} w={'100vw'}>
      <Flex
        boxShadow={'lg'}
        w={'md'}
        h={'lg'}
        bgColor={'white'}
        direction={'column'}
        align={'center'}
        justify={'space-around'}
        gap={5}
        borderRadius={'xl'}
        p={8}
      >
        <CheckCircleIcon color={'green'} boxSize={'60px'} />
        <AppText
          color={AppColors.primary}
          fontWeight={'bold'}
          fontSize={'2xl'}
          mt={'10px'}
          mb={'4px'}
        >
          Payement Success!
        </AppText>
        <Flex direction={'column'} w={'full'} gap={3}>
          <Flex justify={'space-between'} w={'full'}>
            <AppText fontWeight={'bold'}>Payment Date</AppText>
            <AppText>{paymentDate}</AppText>
          </Flex>
          <Flex justify={'space-between'} w={'full'}>
            <AppText fontWeight={'bold'}>Payment Amount</AppText>
            <AppText>{`$ ${product?.price}`}</AppText>
          </Flex>
          <Flex justify={'space-between'} w={'full'}>
            <AppText fontWeight={'bold'}>Plan</AppText>
            <AppText>{`${product?.name}`}</AppText>
          </Flex>
          <Flex justify={'space-between'} w={'full'}>
            <AppText fontWeight={'bold'}>Organization</AppText>
            <AppText>{user?.organization?.name}</AppText>
          </Flex>
        </Flex>
        <Box
          as={'button'}
          color={'white'}
          bgGradient={`linear(to-r, ${AppColors.secondary2}, ${AppColors.iconColorOne})`}
          w={'full'}
          p={2}
          borderRadius={'lg'}
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
            transition: '0.2s',
          }}
          fontWeight={'600'}
          fontSize={'lg'}
          onClick={() => navigate(RoutesList.Dashboard)}
        >
          Go to Dasboard
        </Box>
      </Flex>
    </Flex>
  );
};
export default PaymentSuccessPage;
