import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { FAQ } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const faqApi = createApi({
  reducerPath: 'faqApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllFaqs: build.query<FAQ[], any>({
      query: () => ({
        url: '/faq',
        method: 'GET',
      }),
    }),
    fetchFaqByIdQuery: build.query<FAQ, string>({
      query: (id) => ({
        url: `/faq/${id}`,
        method: 'GET',
      }),
    }),
    fetchFeaturedFaqs: build.query<FAQ[], any>({
      query: () => ({
        url: '/faq/featured',
        method: 'GET',
      }),
      transformResponse: (response: FAQ[]) => {
        return response;
      },
    }),
  }),
});

export const { useFetchAllFaqsQuery, useFetchFaqByIdQueryQuery, useFetchFeaturedFaqsQuery } =
  faqApi;
