import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  SimpleGrid,
  Image,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import UserRegistrationForm, { RegistrationData } from '../../components/forms/registration-form';
import { useUserRegistrationMutation } from '../../store/api/authenticationApi';
import Logo from '../../assets/backlinkit_logo.svg';
import { AppColors, AppText, useLoading } from '@backlinkit/shared';
import { useState } from 'react';
import { useFetchOrganizationSourceTypeByIdQuery } from '../../store/api/organizationSourceTypeApi';

export default function RegistrationInvite() {
  const { sourceId } = useParams();
  const toast = useToast();
  const { setLoading } = useLoading();
  const [showError, setShowError] = useState<boolean>(false);

  const [userRegistration, { data, isLoading, error, isSuccess, isError }] =
    useUserRegistrationMutation();

  const { data: sourceType, isFetching } = useFetchOrganizationSourceTypeByIdQuery(sourceId ?? '', {
    skip: !sourceId,
  });

  const signUp = async (registrationData: RegistrationData) => {
    if (registrationData.termsAndConditions === true) {
      setShowError(false);
      try {
        setLoading(true);
        await userRegistration({
          accountName: registrationData.accountName,
          email: registrationData.email,
          password: registrationData.password,
          organizationSourceId: sourceId ? sourceId : '',
          acceptMarketing: registrationData.keepUpdated,
          acceptTerms: registrationData.termsAndConditions,
        });
        toast({
          title: 'Account created, verification required!',
          description: 'Please go action the email with instructions to verify your account.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      } catch (e) {
        setLoading(false);
        toast({
          title: 'Account creation error',
          status: 'error',
          description:
            "We've run into an error creating your account, you might have an account with us already, Contact support for help",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 10000);
    }
  };

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  const getSourceText = () => {
    if (sourceType) {
      return sourceType.description;
    } else {
      return '';
    }
  };

  return variant === 'desktop' ? (
    <Flex
      h={'100vh'}
      align={'center'}
      justify={'center'}
      position={'relative'}
      overflow={'auto'}
      border={`1px solid ${AppColors.appBorder}`}
      borderRadius={'15px'}
      m={'33px'}
    >
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }} zIndex={10}>
          <Stack direction={'row'} spacing={4} align={'center'}>
            <Image height={'80px'} src={Logo} alt="" objectFit={'cover'} />
          </Stack>
          <Heading color={AppColors.textColor}>
            <Flex flexDirection={'column'} gap={4}>
              <AppText lineHeight={1.1} fontSize={'2xl'}>
                Welcome to <strong>BacklinkIt!</strong>
              </AppText>
              <AppText lineHeight={1.1} fontSize={'2xl'}>
                Thank you for joining us via {getSourceText()}.
              </AppText>
              <AppText lineHeight={1.1} fontSize={'2xl'}>
                We can't wait for you to try out our platform <br /> and boost your{' '}
                <strong>SEO</strong> success!
              </AppText>
              <AppText lineHeight={1.1} fontSize={'2xl'}>
                Sign up and let us know what you think.
              </AppText>
            </Flex>
          </Heading>
        </Stack>
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Sign up
              <Text as={'span'} bgGradient="linear(to-r, #00a4ff,#037DFC)" bgClip="text">
                !
              </Text>
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              {/* We’re looking for amazing engineers just like you! Become a part of our rockstar
              engineering team and skyrocket your career! */}
            </Text>
          </Stack>
          <Box as={'form'} mt={10}>
            <UserRegistrationForm onSubmit={signUp}></UserRegistrationForm>

            {showError && (
              <Alert mt={4} rounded={'md'} status="error">
                <AlertIcon />
                <AlertTitle>
                  Please read through & accept the terms & conditions to continue creating your
                  account.
                </AlertTitle>
              </Alert>
            )}
          </Box>
        </Stack>
      </Container>
    </Flex>
  ) : (
    <Flex overflow={'auto'}>
      <Container
        as={SimpleGrid}
        columns={{ base: 1, md: 2 }}
        spacing={4}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          spacing={{ base: 10, md: 20 }}
          zIndex={10}
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
        >
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Image height={'auto'} maxH={'50px'} src={Logo} alt="" objectFit={'cover'} />
          </Flex>
          <Heading color={AppColors.textColor}>
            <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={4}>
              <AppText lineHeight={1.1} fontSize={'16px'}>
                Almost there!
              </AppText>
              <AppText lineHeight={1.1} fontSize={'16px'}>
                Sign up to the smart approach
              </AppText>
              <AppText lineHeight={1.1} fontSize={'16px'}>
                to tracking your off-page SEO campaigns with Backlinkit.
              </AppText>
              <AppText lineHeight={1.1} fontSize={'16px'}>
                {getSourceText()}
              </AppText>
            </Flex>
          </Heading>
        </Stack>
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
        >
          <Stack spacing={2}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Sign up
              <Text as={'span'} bgGradient="linear(to-r, #00a4ff,#037DFC)" bgClip="text">
                !
              </Text>
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              {/* We’re looking for amazing engineers just like you! Become a part of our rockstar
              engineering team and skyrocket your career! */}
            </Text>
          </Stack>
          2
          <Box as={'form'}>
            <UserRegistrationForm onSubmit={signUp}></UserRegistrationForm>

            {showError && (
              <Alert mt={4} rounded={'md'} status="error">
                <AlertIcon />
                <AlertTitle>
                  Please read through & accept the terms & conditions to continue creating your
                  account.
                </AlertTitle>
              </Alert>
            )}
          </Box>
        </Stack>
      </Container>
    </Flex>
  );
}
