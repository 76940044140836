import { useState } from 'react';
import { Flex, FormLabel, List, ListItem, Input, useColorModeValue } from '@chakra-ui/react';
import { AppButton, AppColors, AppInput, AppText } from '@backlinkit/shared';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { useForm } from 'react-hook-form';
import { DeleteIcon } from '@chakra-ui/icons';

export interface InviteUserForm {
  displayName: string;
  emails: string[];
  referrerMessage: string;
}

const userInviteFormDefaultValues: InviteUserForm = {
  displayName: '',
  emails: [],
  referrerMessage: '',
};

const userInviteFormDataSchema = yup.object({
  displayName: yup.string().required('Field is required'),
  referrerMessage: yup.string().required('Field is required'),
});

type BugFormProps<T> = {
  form?: InviteUserForm;
} & BaseFormProps<T>;

const UserInviteForm: React.FC<BugFormProps<InviteUserForm>> = ({ form, onSubmit }) => {
  const [listItem, setListItem] = useState<string>('');
  const [listItems, setListItems] = useState<string[]>([]);

  const {
    control: control,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<InviteUserForm>({
    defaultValues: form || userInviteFormDefaultValues,
    resolver: yupResolver(userInviteFormDataSchema),
    mode: 'onChange',
  });

  const handleFormSave = () => {
    const formData = getValues();
    onSubmit({
      displayName: formData.displayName,
      emails: listItems,
      referrerMessage: formData.referrerMessage,
    });
    console.log('MESSAGE DTO', formData)
  };

  const handleAddItem = (item: string) => {
    if (listItem) {
      setListItems((listItems) => {
        return [...listItems, listItem];
      });
    }
    setListItem('');
  };

  const handleDeleteItem = (item: string) => {
    const newList = listItems.filter((x) => x !== item);
    setListItems(newList);
  };

  return (
    <Flex p={4} flexDir={'column'} gap={'10px'} w={'full'}>
      <Flex
        flexDirection={'column'}
        bg={AppColors.infoHighlightColor}
        color={'#222222'}
        rounded={'2xl'}
        p={4}
      >
        <AppText fontWeight={'bold'}>Please note:</AppText>
        <AppText mt={2}>
          For every user that accepts your invite! We will add 100 credits to your google checker
          account!
        </AppText>
      </Flex>
      <AppInput<InviteUserForm>
        mt={8}
        control={control}
        name={'displayName'}
        error={errors.displayName}
        label={'Email From Name'}
      />
      <AppInput<InviteUserForm>
        mt={8}
        control={control}
        name={'referrerMessage'}
        error={errors.referrerMessage}
        label={'Referral Message:'}
        textArea
      />
      <FormLabel>Email addresses</FormLabel>
      <Flex w={'full'} gap={3}>
        <Input
          value={listItem}
          onChange={(e) => setListItem(e.target.value)}
          name="list"
          placeholder="Email Address"
        />
        <AppButton onClick={() => handleAddItem(listItem)}>+</AppButton>
      </Flex>
      <List spacing={4}>
        {listItems.map((x) => {
          return (
            <Flex w={'full'} justify={'space-between'} align={'center'} gap={3} key={x}>
              <Flex gap={3}>
                <ListItem>{x}</ListItem>
              </Flex>
              <AppButton
                size={'sm'}
                rightIcon={<DeleteIcon />}
                borderRadius={'full'}
                onClick={() => handleDeleteItem(x)}
              >
                Delete
              </AppButton>
            </Flex>
          );
        })}
      </List>

      <AppButton w={'full'} mt={8} disabled={!isValid} onClick={() => handleFormSave()}>
        Save
      </AppButton>
    </Flex>
  );
};

export default UserInviteForm;
