import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Links from '../pages/links/links';
import Login from '../pages/login/login';
import Tags from '../pages/tags/tags';
import LinkReportsPage from '../pages/link-reports/link-reports';
import ProtectedRoute from './protected-route';
import { AuthOutlet, RouterOutlet } from './router-outlet';
import SecondTierLinks from '../pages/secondTierLinks/secondTierLinks';
import UserProfile from '../pages/userProfile/userProfile';
import Dashboard from '../pages/dashboard/dashboard';
import { OrganizationPage } from '../pages/organization/organization';
import DomainProfile from '../pages/domain-profile/domain-profile';
import DomainLinkChecker from '../pages/domain-link-checker/domain-link-checker';
import DomainLinkGoogleChecker from '../pages/domain-link-google-checker/domain-link-google-checker';
import ExternalReportsPage from '../pages/external-report/external-report';
import AdminRoute from './admin-route';
import PaymentSuccessPage from '../pages/payments/payment-success';
import PaymentErrorPage from '../pages/payments/payment-error';
import Batches from '../pages/backlink-batches/backlink-batches';
import ExternalVerifyPage from '../pages/external-verify/external-verify';
import RegistrationInvite from '../pages/registration/registration-invite';
import ChangePasswordPage from '../pages/change-password/change-password';
import TestPage from '../pages/test/test-page';

export enum RoutesList {
  Index = '/',
  Dashboard = '/dashboard',
  Links = '/links',
  DomainProfile = '/domain-profile',
  SecondTierLinks = '/links/children',
  Registration = '/registration',
  UserProfile = '/user-profile',
  Tags = '/tags',
  DomainLinkChecker = '/domain-link-checker',
  GoogleChecker = '/google-checker',
  LinksReports = '/links/reports',
  Account = '/account',
  OrganizationSubscriptions = '/organization/subscriptions',
  ExternalReports = '/external-reports/:id',
  PaymentSuccess = '/payment/success/:id',
  PaymentCancel = '/payment/cancel/:id',
  ChangeToOnceOffKey = '/password-reset/changeToOnceOffSecurityKey',
  PasswordReset = '/reset-password',
  Batches = '/batches',
  Test = '/test',
}

const Router: React.FC = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route path="/" element={<AuthOutlet />}>
          <Route index element={<Login />} />
          <Route path="registration" element={<RegistrationInvite />} />
          <Route path="registration/:sourceId" element={<RegistrationInvite />} />
          <Route path="verify/:id" element={<ExternalVerifyPage />} />
          <Route path="external-report/:id" element={<ExternalReportsPage />} />
          <Route path="change-password/:id" element={<ChangePasswordPage />} />
        </Route>
        <Route path="/" element={<RouterOutlet />}>
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <AdminRoute>
                  <Dashboard />
                </AdminRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="domain-profile"
            element={
              <ProtectedRoute route={RoutesList.DomainProfile}>
                <DomainProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute route={RoutesList.UserProfile}>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="links"
            element={
              <ProtectedRoute route={RoutesList.Links}>
                <Links />
              </ProtectedRoute>
            }
          />
          <Route
            path="links/reports"
            element={
              <ProtectedRoute route={RoutesList.LinksReports}>
                <LinkReportsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="tags"
            element={
              <ProtectedRoute route={RoutesList.Links}>
                <Tags />
              </ProtectedRoute>
            }
          />
          <Route
            path="domain-link-checker"
            element={
              <ProtectedRoute route={RoutesList.DomainLinkChecker}>
                <DomainLinkChecker />
              </ProtectedRoute>
            }
          />
          <Route
            path="google-checker"
            element={
              <ProtectedRoute route={RoutesList.GoogleChecker}>
                <AdminRoute>
                  <DomainLinkGoogleChecker />
                </AdminRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="links/children"
            element={
              <ProtectedRoute route={RoutesList.SecondTierLinks}>
                <SecondTierLinks />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-profile"
            element={
              <ProtectedRoute route={RoutesList.UserProfile}>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="batches"
            element={
              <ProtectedRoute route={RoutesList.Batches}>
                <Batches />
              </ProtectedRoute>
            }
          />
          <Route
            path="batches/:id"
            element={
              <ProtectedRoute route={RoutesList.Batches}>
                <Batches />
              </ProtectedRoute>
            }
          />
          <Route
            path="account"
            element={
              <ProtectedRoute route={RoutesList.Account}>
                <AdminRoute>
                  <OrganizationPage />
                </AdminRoute>
              </ProtectedRoute>
            }
          />

          <Route
            path="payment/success/:id"
            element={
              <ProtectedRoute route={RoutesList.PaymentSuccess}>
                <AdminRoute>
                  <PaymentSuccessPage />
                </AdminRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment/cancel/:id"
            element={
              <ProtectedRoute route={RoutesList.PaymentCancel}>
                <AdminRoute>
                  <PaymentErrorPage />
                </AdminRoute>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="test" element={<TestPage />} />
        <Route
          path="test"
          element={
            <ProtectedRoute route={RoutesList.Test}>
              <AdminRoute>
                <TestPage />
              </AdminRoute>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
