import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { DomainUserRoleTypeDto } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const domainUserRoleTypesApi = createApi({
  reducerPath: 'domainUserRoleTypes',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllDomainUserRoleTypes: build.query<DomainUserRoleTypeDto[], any>({
      query: () => ({
        url: '/domainuserroletype',
        method: 'GET',
      }),
    }),
    fetchDomainUserRoleById: build.query<DomainUserRoleTypeDto, string>({
      query: (id) => ({
        url: `/domainuserroletype/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchAllDomainUserRoleTypesQuery, useFetchDomainUserRoleByIdQuery } =
  domainUserRoleTypesApi;
