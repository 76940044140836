import React from 'react';
import DomainLayout from '../../components/layouts/domain-layout';
import { Flex, useToast } from '@chakra-ui/react';
import {
  useCreateBatchMutation,
  useFetchBatchesByDomainQuery,
  useLazyFetchBatchesByDomainQuery,
} from '../../store/api/backlinkBatchesApi';
import { selectDomain } from '../../store/slices/domain.slice';
import BatchCard from './backlink-batch-card';
import { AppButton, AppLoader, AppText, useLoading, usePanel } from '@backlinkit/shared';
import BacklinkBatchForm, {
  BacklinkBatchFormData,
} from '../../components/forms/backlink-batch-form';
import { useAppSelector } from '../../store/store';
import { useParams } from 'react-router-dom';

const Batches: React.FC = () => {
  const panel = usePanel();
  const toast = useToast();
  const { setLoading } = useLoading();
  const domain = useAppSelector(selectDomain);
  const [createBatchTrigger] = useCreateBatchMutation();
  const [getBatchesByDomain] = useLazyFetchBatchesByDomainQuery();
  const batchId = useParams();

  const {
    data: batches,
    refetch: refetchBatches,
    isLoading,
  } = useFetchBatchesByDomainQuery(domain?.id ?? '', {
    refetchOnMountOrArgChange: true,
    skip: !domain,
  });

  const handleAddDialog = () => {
    panel({
      title: 'Create Batch',
      size: 'xl',
      render: (onSubmit) => {
        return (
          <BacklinkBatchForm
            onSubmit={(data) => {
              handleSave(data);
              onSubmit();
            }}
          ></BacklinkBatchForm>
        );
      },
    });
  };

  const handleSave = async (formData: BacklinkBatchFormData) => {
    try {
      setLoading(true);

      await createBatchTrigger({
        domainId: domain?.id ?? '',
        name: formData.name,
        price: formData.price,
        notes: formData.notes,
        batchTypeId: formData.batchTypeId,
        nicheTypeId: formData.nicheTypeId,
        tagIds: formData.tagIds,
      });

      toast({
        title: 'Batch Saved',
        description: "We've saved your batch successfully!",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      refetchBatches();
      if (domain) {
        getBatchesByDomain(domain?.id);
      }

      setLoading(false);
    } catch (error) {
      toast({
        title: 'Batch error.',
        description: "We've run into a problem saving your batch, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  return (
    <DomainLayout>
      {isLoading && <AppLoader />}
      <Flex px={6} flex={1} direction="column">
        <Flex justifyContent={'space-between'}>
          <AppText fontWeight="bold" fontSize="2xl">
            Batches
          </AppText>

          <AppButton
            loadingText={'Adding Link'}
            onClick={handleAddDialog}
            borderRadius="full"
            size="sm"
            variant={'solid'}
          >
            Add Batch
          </AppButton>
        </Flex>

        {domain && (
          <Flex mt={4} flexDirection={'column'}>
            {batches?.map((batch) => (
              <BatchCard
                key={`batch-card-${batch.id}`}
                batch={batch}
                domain={domain}
                refetchBatches={refetchBatches}
                showLinks={batch.id === batchId.id}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </DomainLayout>
  );
};

export default Batches;
